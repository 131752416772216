import type { AdminTextsAction } from './actions';
import { ADMINTEXTS_LOADED } from './actions';

type AdminTexts = Record<string, string | null>

const initialState: AdminTexts = {};

export default function (state = initialState, action: AdminTextsAction) {
  switch (action.type) {
    case ADMINTEXTS_LOADED:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

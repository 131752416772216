import styles from '../Lines.module.scss';
import LineTemplatePropTypes from './LineTemplatePropTypes';
import { SimpleText } from 'components/sanaText';
import { useUpdateContext } from '../../updateContext';
import { RichText } from 'components/sanaText';
import { InfoAlert } from 'components/primitives/alerts'
import { useCurrencyInfo } from 'utils/hooks';
import { getCurrency } from 'utils/format';
import { FormattedPrice } from 'components/primitives/price';
import { useHasAbilities } from 'components/objects/user'; // [209289] TE: Remove discount info
import { AbilityTo } from 'behavior/user/constants'; // [209289] TE: Remove discount info


const LineTemplate = ({
  productInfo,
  agreementTerm,
  productActionBlock,
  actionLinks,
  quantityBox,
  uomLabel,
  priceNode,
  productDiscount,
  subTotal,
  className = null,
  isGroupedLine,
  id,
  isSpecialShipping,  //[114886] 3.12. Warning on products that require additional shipping
  isVariantSpecialShipping,//[114886] 3.12. Warning on products that require additional shipping
  surchargeValue, //133701 - [RFC Surcharges] 3.1. Display surcharge notices on the web shop
  surchargeType   //133701 - [RFC Surcharges] 3.1. Display surcharge notices on the web shop
}) => {
  const { isB2BPreset } = useUpdateContext();

  let percentageSurchargeType = surchargeType === "Percentage";

  const currencyInfo = useCurrencyInfo();
  const currencySymbol = getCurrency(currencyInfo.id, currencyInfo.cultureName);
  const discountAbility = useHasAbilities(AbilityTo.DiscountAbility)[0]; // [209289] TE: Remove discount info

  let quantityColSpan = 1;
  if (!uomLabel) {
    quantityColSpan++;
    if (!priceNode)
      quantityColSpan++;
  }

  return (
    <tbody className={className} id={id}>
      <tr className={styles.productBlock}>
        {(isB2BPreset || productActionBlock) && (
          <td className={styles.productActionCell} aria-hidden={!isGroupedLine}>
            {productActionBlock}
          </td>
        )}
        <td className={styles.productInfoCell} colSpan={productActionBlock ? '2' : '3'} headers="basketProduct" aria-describedby="basketProduct">
          {productInfo}
          {agreementTerm}
        </td>
      </tr>
      <tr className={`${styles.priceBlock} ${priceNode ? '' : styles.noPrice}`}>
        <td className={styles.quantityCell} colSpan={quantityColSpan} headers="basketQuantity" aria-describedby="basketQuantity">
          {quantityBox}
        </td>
        {uomLabel &&
          <td className={styles.uomCell} colSpan={priceNode ? null : '2'} headers="basketUom" aria-describedby="basketUom">
            {uomLabel}
          </td>
        }
        {priceNode && (
          <td className={styles.costCell} headers="basketPrice" aria-describedby="basketPrice">
            {priceNode}
          </td>
        )}
      </tr>
      <tr className={`${styles.rowTotal} ${productDiscount ? styles.noBorder : styles.noBorder} ${subTotal ? '' : styles.noPrice}`}>
        <td colSpan={subTotal ? '2' : '3'} headers="basketProduct" aria-describedby="basketProduct">
          {actionLinks}
        </td>
        {subTotal && (
          <td className={styles.costCell} headers="basketTotal" aria-describedby="basketTotal">
            <span className={styles.totalLabel}><SimpleText textKey="Total_Label" /></span> {subTotal}
          </td>
        )}
      </tr>
      {productDiscount && discountAbility && ( // [209289] TE: Remove discount info
        <tr className={styles.rowDiscount}>
          <td colSpan="3" headers="basketTotal" aria-describedby="basketTotal">
            {productDiscount}
          </td>
        </tr>
      )}
      {surchargeValue !== undefined && surchargeValue !== null && surchargeValue !== 0 && percentageSurchargeType && (
        <tr className={`${styles.rowMtz} mtz-notice-cart`}>
          <td colSpan="3">
            <RichText
              textKey={'SurchargeInformation_Percentage_Basket'}
              formatWith={[surchargeValue]}
            />
          </td>
        </tr>
      )}
      {surchargeValue !== undefined && surchargeValue !== null && surchargeValue !== 0 && !percentageSurchargeType && (
        <tr className={`${styles.rowMtz} mtz-notice-cart`}>
          <td colSpan="3">
            <RichText
              textKey={'SurchargeInformation_ActualValue_Basket'}
              formatWith={[<FormattedPrice price={surchargeValue} currencyInfo={currencyInfo} />]}
            />
          </td>
        </tr>
      )}
      <tr className="info_alert-wrapper">
        <td colSpan="6"> {(isSpecialShipping || isVariantSpecialShipping) && (<InfoAlert role="alert"> <RichText textKey="SpecialShippingMessage" /> </InfoAlert>)}</td>
      </tr>
    </tbody>
  );
};

LineTemplate.propTypes = LineTemplatePropTypes;

export default LineTemplate;
/*166091: [Autolack - phase 2 CR] Provide link from the My account menu dropdown to navigate to the stock tracking pages.*/
import { RichText } from 'components/sanaText';
import { routesBuilder } from 'routes';
import Link from './base/Link';
import { AbilityTo, AbilityState } from 'behavior/user/constants';
import { abilitiesPropType } from 'behavior/user';
import { withAbilities } from 'components/objects/user';

const MyStockTrackingLink = ({ abilities: [viewMyAccountAbility], ...attributes }) => {
  if (viewMyAccountAbility !== AbilityState.Available)
    return null;

  return (
    <div className="AccountMenu_item stock-tracking-link">
      <RichText textKey="Login_MyStockTracking" />
      </div>
  );
};

MyStockTrackingLink.propTypes = {
  abilities: abilitiesPropType.isRequired,
};

export default withAbilities(MyStockTrackingLink, [AbilityTo.StockTracking]);
import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import QuantityContext from './QuantityContext';

///114880: [Autolack] 3.9. Stock transaction � Add to on-hand stock
const QuantityProvider = ({ children }) => {
  const [state, setState] = useState(() => ({
    quantities: new Map(),
    linesDependency: {},
  }));

  const quantities = state.quantities;

  const setQuantity = useCallback((lineId, quantity) => {
    setState(({ quantities }) => {
      const line = quantities.get(lineId);
      if (line) {
        line.quantity = quantity;
      } else {
        quantities.set(lineId, { quantity });
      }

      return { quantities, linesDependency: {} };
    });
  }, []);


  const value = {
    quantities,
    setQuantity,
  };

  return (
    <QuantityContext.Provider value={value}>
      {children}
    </QuantityContext.Provider>
  );
};

QuantityProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default QuantityProvider;

export const UpdateType = Object.freeze({
  Added: 'Added',
  Removing: 'Removing',
  Removed: 'Removed',
  Duplicate: 'Duplicate',
});

export const StockType = Object.freeze({
  Low: 'Low',
  Optimal: 'Optimal',
});
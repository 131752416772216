import LanguageSelectorBase from './LanguageSelectorBase';
import { TogglePresentationOption } from './constants';

const defaultOptions = {
  togglePresentation: TogglePresentationOption.FlagOnly,
  showFlagsInDropdown: true,
};

const LanguageSelector = () => <LanguageSelectorBase options={defaultOptions} />;

export default LanguageSelector;

import styles from './Summary.module.scss';
import PropTypes from 'prop-types';
import { connect,useDispatch } from 'react-redux';
import { SimpleText } from 'components/sanaText';
import ProductLine from './ProductLine';
import Footer from './Footer';
import { useState,useEffect } from 'react';
import { loadSelectedProducts } from 'behavior/stockNotifications';
import { browserStorage as localStorage } from 'utils/localStorage';

const Popup = ({ items, totalCount }) => {
  const dispatch = useDispatch();
  const addedListToBasketKey = 'stockNotifications.products';

  if (!items && totalCount === 0)
    return null;

useEffect(() => {    
    isProductsAvailable();
}, [items,totalCount]);

  function isProductsAvailable(){
     let products = localStorage.getItem(addedListToBasketKey);
     if(products!==null && products.length>0){
        return true;
     }
     else
        return false;
  }

  return (
    <>
      <span className={`${styles.title} ${totalCount ? styles.mainTitle : styles.altTitle} Alert_block Alert_warning`}>
        <SimpleText textKey={totalCount ? 'StockNotifications_WarningHeader' : 'StockNotifications_DefaultHeader'} />
      </span>
      <div className={styles.productList}>
        {
          items.map((line, index) => {
            return (
              <ProductLine key={index} line={line} isProductsAvailable={isProductsAvailable} />
            );
          })
        }
      </div>
      {isProductsAvailable() &&<Footer />}
    </>
  );
};

Popup.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      productId: PropTypes.string.isRequired,
    }),
  ),
  totalCount: PropTypes.number.isRequired,
};

export default Popup;

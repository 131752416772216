exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ClosedStore_content{-webkit-flex-grow:1;flex-grow:1;background-position:50%;background-repeat:no-repeat;background-size:cover;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center}.ClosedStore_content,.ClosedStore_form-column{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column}.ClosedStore_form{padding:2em 2em 0;margin:auto;background-color:var(--page_BackgroundColor,#fff)}.ClosedStore_logo{display:-webkit-flex;display:flex;max-width:none;padding-bottom:1.1em}.ClosedStore_logo>a{pointer-events:none}.ClosedStore_logo img{width:100%}.ClosedStore_header-text{font-family:var(--headingText_FontFamily,\"Pathway Gothic One\"),sans-serif;font-size:var(--headingText_FontSizeH3,1.8em);text-transform:var(--headingText_H3_TextTransform,uppercase);padding-bottom:.5rem}.ClosedStore_description-text{padding-bottom:1.5rem}.ClosedStore_footer{padding-top:.76em;padding-bottom:.76em}@media (min-width:var(--breakpoints_Small,600)px),print{.ClosedStore_footer{padding-top:1.5em;padding-bottom:2.9em}}", ""]);

// exports
exports.locals = {
	"content": "ClosedStore_content",
	"form-column": "ClosedStore_form-column",
	"formColumn": "ClosedStore_form-column",
	"form": "ClosedStore_form",
	"logo": "ClosedStore_logo",
	"header-text": "ClosedStore_header-text",
	"headerText": "ClosedStore_header-text",
	"description-text": "ClosedStore_description-text",
	"descriptionText": "ClosedStore_description-text",
	"footer": "ClosedStore_footer"
};
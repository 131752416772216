import styles from './Summary.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components/primitives/links';
import { UseSanaButton } from 'components/primitives/buttons';
import { SimpleText } from 'components/sanaText';
import { routesBuilder } from 'routes';
import { AbilityState } from 'behavior/user/constants';
import { abilityPropType } from 'behavior/user';
import { useRoutesChecker } from 'components/primitives/checkers';
import { RouteName } from 'routes';
import { DocumentType } from 'behavior/documents';
import { useDispatch } from 'react-redux';
import { addProductsToBasket } from 'behavior/stockNotifications/actions';

const footerBtnClassName = `${btnStyles.btn} ${btnStyles.btnBase} ${btnStyles.btnAction} ${styles.footerBtn}`;

const Footer = () => {
  const dispatch = useDispatch();
  const addSelectedProducts = () => {
    dispatch(addProductsToBasket());
  }
  /*to={routesBuilder.forBasket}*/
  return (
    <div className={styles.panelFooter}>
      <div>
      </div>
      {
        <div>
          <UseSanaButton textKey="AddToBasket" dontWait>
            {text => (
              <Link
                to={routesBuilder.forBasket}
                className={footerBtnClassName}
                onClick={() => addSelectedProducts()}
              >
                <span className="btn-cnt">{text}</span>
              </Link>
            )}
          </UseSanaButton>
        </div>
      }

    </div>
  );
};

Footer.propTypes = {
  createOrderAbility: abilityPropType,
  isBlocked: PropTypes.bool,
};

export default memo(Footer);

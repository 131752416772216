import styles from './Header.module.scss';
import { useRef, useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';
import { Container, Row, Col } from 'components/primitives/grid';
import { Logo } from 'components/objects/logo';
import { AccountMenu } from 'components/objects/accountMenu';
import { Search } from 'components/objects/search';
import { ModalNavOpener, ModalNav, useFocusResetOnLocationChange } from 'components/objects/navigation';
import { useLayoutShifter } from 'utils/layout';
import { isModifierKeyPressed } from 'utils/helpers';
import { orientationChange$, useEventObservable } from 'utils/rxjs';
import FocusLock from 'react-focus-lock';
import { BasketSummary } from 'components/objects/basket';
import { ProductSuggestions } from 'components/objects/productSuggestions';
import { setFocusWithoutScrolling } from 'utils/dom';
import { isBrowser } from 'utils/detections';
import { LanguageSelector } from 'components/objects/languageSelector';
import { StockNotifications } from 'components/objects/stockNotifications';

const ELEMENT_NAME = 'stickyHeader';
const SHIFT_TYPE = 'TOP'; /*:ShiftType*/

const StickyHeaderTemplate = ({ isDesktop, isLoading, noGutters }) => {
  const [isModalMode, setModalMode] = useState(false);
  const [searchIsFocused, setSearchIsFocused] = useState(false);
  const [animated, setAnimated] = useState();
  const isFirstRender = useRef(true);
  const activeElementRef = useRef();
  const headerWrapperRef = useRef();
  const headerRef = useRef();
  const prevHeightRef = useRef(0);
  const { topShiftBlockHeight, bottomShiftBlockHeight, updateElementsAffectingShiftData } = useLayoutShifter();

  const toggleHeaderMode = useCallback(state => setModalMode(state ?? (prev => !prev)), []);
  const unsetModalMode = useCallback(() => setModalMode(false), []);
  const handleSkipLinkClick = useCallback(() => toggleHeaderMode(true), [toggleHeaderMode]);
  const handleMenuOpenerClick = useCallback(() => toggleHeaderMode(), [toggleHeaderMode]);

  const disableModalMode = useCallback(e => {
    if (e.type === 'click') {
      const { target, currentTarget: { lastElementChild } } = e;

      if (target !== lastElementChild)
        return;
    } else {
      const { key, which } = e;
      const isTargetKey = key === 'Escape' || which === 27;

      if (!isTargetKey || isModifierKeyPressed(e))
        return;
    }

    unsetModalMode();
  }, []);

  const handleResize = useCallback(() => {
    const { offsetHeight } = headerRef.current;
    if (offsetHeight === prevHeightRef.current)
      return;

    const headerWrapperStyle = headerWrapperRef.current.style;
    headerWrapperStyle.paddingTop = parseInt(headerWrapperStyle.paddingTop || 0, 10) - prevHeightRef.current + offsetHeight + 'px';
    prevHeightRef.current = offsetHeight;
    updateElementsAffectingShiftData(SHIFT_TYPE, ELEMENT_NAME, offsetHeight, !isDesktop);
  }, [isDesktop]);

  useEventObservable(orientationChange$, unsetModalMode);

  useEffect(() => {
    const height = headerRef.current.offsetHeight;
    updateElementsAffectingShiftData(SHIFT_TYPE, ELEMENT_NAME, height, !isDesktop);
    prevHeightRef.current = height;

    if (!isDesktop) {
      return () => updateElementsAffectingShiftData(SHIFT_TYPE, ELEMENT_NAME);
    }

    // Animation should be applied after small delay to work properly in IE11 and Legacy MS Edge.
    const timeoutId = setTimeout(() => setAnimated(true));

    return () => {
      clearTimeout(timeoutId);
      updateElementsAffectingShiftData(SHIFT_TYPE, ELEMENT_NAME);
    };
  }, [isDesktop]);

  useEffect(() => {
    const { style: headerStyle, offsetHeight } = headerRef.current;
    const { style: headerWrapperStyle } = headerWrapperRef.current;

    headerStyle.top = `${topShiftBlockHeight}px`;
    headerWrapperStyle.paddingTop = `${topShiftBlockHeight + offsetHeight}px`;
    headerWrapperStyle.bottom = `${bottomShiftBlockHeight}px`;
  }, [topShiftBlockHeight, bottomShiftBlockHeight]);

  const handleFocus = useMemo(() => {
    if (isDesktop)
      return null;

    return e => setSearchIsFocused(e && e.type === 'focus');
  }, [isDesktop]);

  useEffect(() => {
    // Additional focus reset to override react-focus-lock default behaviour of setting focus
    // on first focusable element inside lock on deactivation. Focus will remain on active element
    // if it is present and is not a descendant of sticky header or will be set on layout element otherwise
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (isModalMode && isLoading)
      activeElementRef.current = document.activeElement;

    if (isModalMode || isLoading || activeElementRef.current === undefined)
      return;

    const [, menuElement] = headerWrapperRef.current.children;
    let targetElement = activeElementRef.current;
    // If targetElement is not present, it is located inside of top navigation menu or is not visible on the screen
    // set targetElement value to layout element.
    if (!targetElement || menuElement.contains(targetElement) || targetElement.offsetWidth === 0)
      targetElement = document.getElementById('layout');

    setFocusWithoutScrolling(targetElement);
    activeElementRef.current = undefined;
  }, [isLoading, isModalMode]);

  useFocusResetOnLocationChange(!isModalMode, unsetModalMode);

  const whiteList = isBrowser
    ? useMemo(() => {
      if (window.parent)
        return node => window.parent.document.documentElement.contains(node);

      return null;
    }, [!window.parent])
    : null;

  const headerClassName = `${styles.stickyHeader} ${isDesktop ? styles.desktop : ''} ${animated ? styles.animate : ''}`;

  return (
    <FocusLock disabled={!isModalMode} whiteList={whiteList}>
      {/*eslint-disable-next-line jsx-a11y/no-static-element-interactions*//*There is a role here actually.*/}
      <div
        role={isModalMode ? 'dialog' : 'presentation'}
        aria-modal={isModalMode ? 'true' : null}
        aria-keyshortcuts={isModalMode ? 'Esc' : null}
        className={styles.stickyHeaderWrapper}
        ref={headerWrapperRef}
        onKeyDown={disableModalMode}
        onClick={disableModalMode}
        suppressHydrationWarning
      >
        {/* data-scroll-lock-fill-gap - fills gap on element on right side with padding (default) when body scroll disabled */}
        <div className={headerClassName} ref={headerRef} data-scroll-lock-fill-gap>
          <ReactResizeDetector handleHeight onResize={handleResize} />
          <Container fluid={!isDesktop} className={`${styles.stickyHeaderContainer}${noGutters ? ` ${styles.noGutters}` : ''}`}>
            <Row crossAxisAlign="center" style={{ minHeight: styles.stickyHeight }} noGutters>
              <Col xs="auto"><ModalNavOpener visible={isModalMode} onClick={handleMenuOpenerClick} /></Col>
              <Col className={`${styles.middleSection} ${searchIsFocused ? styles.isFocused : ''}`}>
                <Logo className={styles.logo} small />
                <Search className={styles.searchBox} onFocus={handleFocus} onBlur={handleFocus} id="header-search-sticky" />
              </Col>
              <Col xs="auto" className={styles.column}>
                <ProductSuggestions hideLabel className={styles.suggestions} />
                <LanguageSelector />
                <AccountMenu />
                <StockNotifications hideLabel />
                <BasketSummary hideLabel />
              </Col>
            </Row>
          </Container>
        </div>
        {isBrowser &&
          <ModalNav
            visible={isModalMode}
            forDesktop={isDesktop}
            noGutters={noGutters}
            handleSkipLinkClick={handleSkipLinkClick}
          />
        }
      </div>
    </FocusLock>
  );
};

StickyHeaderTemplate.propTypes = {
  isDesktop: PropTypes.bool,
  noGutters: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default connect(({ isLoading }) => ({ isLoading }))(StickyHeaderTemplate);

import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PageComponentNames } from 'behavior/pages';
import { RouteName } from 'routes';
import { initSystemPageContent } from '../system';
import { loadCreateReturnOrderPageQuery, loadCreateReturnOrderPreviewPageQuery } from './queries';
import { getBackTo } from '../helpers';

export default (routeData, state$, { api }) => {
  const { params: { id, originalOrderId: orderId, previewToken } } = routeData;

  if (previewToken) {
    return api.graphApi(loadCreateReturnOrderPreviewPageQuery).pipe(
      map(({ pages, settings: { documents: { returnOrder } } }) => {
        const page = pages.createDocBasedReturnOrder;
        if (!page)
          return null;
  
        page.component = PageComponentNames.CreateDocBasedReturnOrder;
        page.returnReasons = returnOrder.reasons;
        page.fileUploadSettings = returnOrder.attachments;
        page.splitLinesSupported = returnOrder.splitLinesSupported;
        page.invoice = {
          canBeReturned: true,
          currency: { id: '', symbol: '' },
          lines: Array.from(Array(3)).map(() => ({
            lineNo: '',
            quantity: 1,
            uom: { minimumQuantity: 1, maximumQuantity: 1, quantityStep: 1 },
            availableReturnQuantity: 1,
          })),
        };
  
        return { page };
      }),
      initSystemPageContent(),
    );
  }

  if (!id)
    return of(null);

  return api.graphApi(loadCreateReturnOrderPageQuery, { id, orderId }).pipe(
    map(({ pages, documents: { invoices: { invoice } }, settings: { documents: { returnOrder } } }) => {
      const page = pages.createDocBasedReturnOrder;
      if (!page || !invoice)
        return null;

      page.component = PageComponentNames.CreateDocBasedReturnOrder;

      page.backTo = getBackTo(state$, [
        RouteName.CreateDocBasedReturnOrder,
      ], routeData.params && routeData.params.language);

      page.returnReasons = returnOrder.reasons;
      page.fileUploadSettings = returnOrder.attachments;
      page.splitLinesSupported = returnOrder.splitLinesSupported;

      invoice.lines = invoice.lines.itemLines.reduce((resultLines, { product, ...line }) => {
        const hasSublines = !!line.sublines?.length;

        if (hasSublines) {
          for (const subline of line.sublines)
            resultLines.push({
              ...subline,
              productId: product.id,
              title: line.title,
              variantTitle: subline.title,
              variationId: subline.variationId,
              uom: getLineUom(subline, product),
              availableReturnQuantity: product.exists ? subline.availableReturnQuantity : 0,
            });
        } else {
          resultLines.push({
            ...line,
            productId: product.id,
            uom: getLineUom(line, product),
            availableReturnQuantity: product.exists ? line.availableReturnQuantity : 0,
          });
        }

        return resultLines;
      }, []);

      page.invoice = invoice;

      return { page };
    }),
    initSystemPageContent(),
  );
};

function getLineUom(line, product) {
  if (!product.exists)
    return null;

  if (!line.uom)
    return { minimumQuantity: 1, maximumQuantity: line.availableReturnQuantity };

  const productUom = product.uoms.find(uom => uom.id === line.uom.id);
  const uom = { ...line.uom, ...productUom, maximumQuantity: line.availableReturnQuantity };
  
  if (uom.minimumQuantity == null)
    uom.minimumQuantity = uom.quantityStep;

  return uom;
}

import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { rewriteTo } from 'behavior/routing';

export const pageSize = 20;

export const useLoadData = route => {
    const dispatch = useDispatch();

    return useCallback(
        (pageIndex, filterDate) => {
            const filter = filterDate??{ 'filter':{ 'option':'' } };   
            const routeData = {
                ...route,
                params:{ pageIndex, filter },
                options: { onlyItems: true },
            };
            dispatch(rewriteTo(routeData, true));
        },
        [route],
    );
};
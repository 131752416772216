import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { useIsMobileViewport } from 'utils/hooks';
import { QuantityTextBox } from 'components/primitives/product';
import deleteImage from './images/delete.png';
import styles from './StockReduction.module.scss';
import StockReductionContext from './StockReductionContext';

const Item = ({ item, quantityModel, separator, deleteFromList }) => {
  const isMobile = useIsMobileViewport();
  const [comment, setComment] = useState(item.comment);
  const {
    deleteLine: deleteLineFromContext,
    updateQuantity,
    updateComment,
  } = useContext(StockReductionContext);

  const onCommentChange = value => {
    setComment(value);
    updateComment(item.id, value);
  };

  const onDeleteLine = e => {
    deleteLineFromContext(item.id);
    deleteFromList(item.id);
  };

  return (
    <tbody>
      <tr>
        <td className={!isMobile ? 'vertical-align-top' : 'mobile-table-row'}>
          <div className={!isMobile ? 'visually-hidden' : 'mobile-table-name'}>
            {' '}
            <SimpleText textKey="StockReduction_List_ItemNo" />
          </div>
          <div className="mobile-table-value">
            <span>{item.productId}</span>
            {item.variantId && (
              <div>
                <strong>
                  <SimpleText textKey="StockReduction_List_Variant" />
                </strong>
                <span>{item.variantId}</span>
              </div>
            )}
            {item.barcode && (
              <div>
                <strong>
                  <SimpleText textKey="StockReduction_List_Ean" />
                </strong>
                <span>{item.barcode}</span>
              </div>
            )}
          </div>
        </td>
        <td className={!isMobile ? 'vertical-align-top' : 'mobile-table-row'}>
          <div className={!isMobile ? 'visually-hidden' : 'mobile-table-name'}>
            {' '}
            <SimpleText textKey="StockReduction_List_Title" />
          </div>
          <div className="mobile-table-value">
            <span>{item.title}</span>
            {item.manufacture && (
              <div>
                <strong>
                  <SimpleText textKey="StockReduction_List_Manufacture" />
                </strong>
                <span>{item.manufacture}</span>
              </div>
            )}
          </div>
        </td>
        <td className={!isMobile ? 'table-text-center vertical-align-top' : 'mobile-table-row'}>
          <div className={!isMobile ? 'visually-hidden' : 'mobile-table-name'}>
            {' '}
            <SimpleText textKey="StockReduction_List_Quantity" />
          </div>
          <div className="mobile-table-value">
            <QuantityTextBox
              id={'qty' + item.id}
              quantity={quantityModel}
              value={item.quantity}
              onChange={newQuantity => updateQuantity(item.id, newQuantity)}
              separator={separator}
              hideControlsOnBlur
              showControlsUnderInputMd
            />
          </div>
        </td>
        <td className={!isMobile ? 'table-text-center vertical-align-top' : 'mobile-table-row'}>
          <div className={!isMobile ? 'visually-hidden' : 'mobile-table-name'}>
            {' '}
            <SimpleText textKey="StockReduction_List_Comment" />
          </div>
          <div className="mobile-table-value">
            <div className="Input_tbx stock-reduction-custom-input">
              <input
                type="text"
                id={'comment' + item.id}
                maxLength={150}
                value={comment}
                onChange={e => onCommentChange(e.target.value)}
              />
            </div>
          </div>
        </td>
        <td
          className={
            !isMobile ? 'table-text-center dk-pdr-none vertical-align-top' : 'mobile-table-row'
          }
        >
          <div className={!isMobile ? 'visually-hidden' : 'mobile-table-name'}>
            &nbsp;
          </div>
          <div className="mobile-table-value table-btn-right">
            <button
              onClick={onDeleteLine}
              className={`${styles.nonArrowBtn} Button_btn table-btn stock-tracking-delete-btn`}
            >
              <img src={deleteImage} alt="Delete" />
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  );
};

Item.propTypes = {
  item: PropTypes.object.isRequired,
  quantityModel: PropTypes.object.isRequired,
  separator: PropTypes.string,
  deleteFromList: PropTypes.func.isRequired,
};

export default Item;

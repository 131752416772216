exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".LargeGallery_slide{position:absolute;top:0;right:0;bottom:0;left:0;margin:auto;max-height:95%;max-width:95%}.LargeGallery_spinner{display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center}", ""]);

// exports
exports.locals = {
	"slide": "LargeGallery_slide",
	"spinner": "LargeGallery_spinner"
};
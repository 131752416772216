import styles from './MyAccount.module.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { Link } from 'components/primitives/links';
import { routesBuilder } from 'routes';

const AccountInfo = ({ name, email }) => (
  <table className={styles.accountInfo}>
    <tbody>
      <tr>
        <td><SimpleText textKey="MyAccount_Name" /></td>
        <td>{name}</td>
      </tr>
      <tr>
        <td><SimpleText textKey="MyAccount_EmailAddress" /></td>
        <td>{email}</td>
      </tr>
      <tr>
        <td><SimpleText textKey="MyAccount_Password" /></td>
        <td>
          <Link to={routesBuilder.forChangePassword}>
            <SimpleText textKey="ChangePassword" />
          </Link>
        </td>
      </tr>
    </tbody>
  </table>
);

AccountInfo.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
};

export default connect(
  ({ user: { name, email } }) => ({ name, email }),
)(AccountInfo);

import styles from './LanguageSelector.module.scss';
import { memo, useCallback, useState } from 'react';
import useLanguageSelectorBehavior from './useLanguageSelectorBehavior';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import MobileHeader from './MobileHeader';
import { Placeholder } from 'components/primitives/placeholders';
import Body from './Body';
import { VerticalSliding } from 'components/primitives/transitions';
import { useOnChange } from 'utils/hooks';

const MobileLanguageSelector = () => {
  const { 
    selectedLanguage,
    sortedLanguages,
    alternateUrls,
    loadAlternateUrls,
  } = useLanguageSelectorBehavior();
  const [opened, setOpened] = useState(false);
  const isLoading = useSelector(state => state.isLoading);

  const handleOpen = useCallback(e => {
    if (e.type === 'keydown') {
      const isTargetKey = e.key === 'Enter' || e.which === 13;

      if (!isTargetKey)
        return;
    }

    loadAlternateUrls();
    setOpened(!opened);
  }, [opened, loadAlternateUrls]);
  const handleClose = useCallback(() => setOpened(false), []);
  const handleExit = useCallback(node => node.classList.add(styles.collapsing), []);
  const handleExited = useCallback(node => node.classList.remove(styles.collapsing), []);

  useOnChange(() => isLoading && handleClose(), [isLoading], false);

  if (sortedLanguages.length <= 1)
    return null;

  return (
    <div className={styles.mobileContainer}>
      <div
        tabIndex="0"
        role="button"
        disabled={isLoading || null}
        className={styles.mobileOpener}
        onKeyDown={handleOpen}
        onClick={handleOpen}
        aria-controls="mobileLanguageSelector"
      >
        <MobileHeader selectedLanguage={selectedLanguage} />
        <span className={styles.caret}>
          <FontAwesomeIcon icon={faCaretDown} rotation={opened ? 180 : null} />
        </span>
      </div>
      <VerticalSliding
        id="mobileLanguageSelector"
        containerClass={styles.contentHolder}
        expanded={opened}
        onClick={handleClose}
        onExit={handleExit}
        onExited={handleExited}
      >
        {alternateUrls && alternateUrls.length 
          ? (
            <Body 
              selectedLanguage={selectedLanguage} 
              languages={sortedLanguages} 
              alternateUrls={alternateUrls}
              showFlags
              className={styles.mobileItem}
            />
          )
          : (
            <div className={`${styles.placeholder} ${styles.mobile}`}>
              <Placeholder lineCount={sortedLanguages.length} className={styles.placeholderItem} />
            </div>
          )
        }
      </VerticalSliding>
    </div>
  );
};

export default memo(MobileLanguageSelector);

import { stockTrackingPageQuery } from './queries';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { PageComponentNames } from '../componentNames';
import { initSystemPageContent } from '../system';
import { loadstockTrackings, stockTrackingsReceived } from './actions';
import { loadSystemPageQuery } from '../system/queries';
import { generateKey } from 'utils/helpers';

export const defultSize = 20;

export default ({ params, options: pageOptions }, _state$, { api }) => {
  if (params?.previewToken) {
    return api.graphApi(loadSystemPageQuery('stockTrackings')).pipe(
      map(({ pages: { stockTrackings: page } }) => {
        if (!page)
          return null;

        page.component = PageComponentNames.StockTrackings;
        const stockTrackings = Array.from(Array(5)).map(() => ({
          id: generateKey(),
          lines: [],
        }));

        return {
          page,
          action$: of(stockTrackingsReceived({ stockTrackings })),
        };
      }),
      initSystemPageContent(),
    );
  }

  const backTo = pageOptions && pageOptions.backTo;
  const { filter, pageIndex, pageSize } = params;
  const index = pageIndex ?? 0;
  const size = pageSize ?? defultSize;

  let options = { filter: { option: '' }, page: { index, size } };
  if (filter) {
    options = { ...filter, page: { index, size } };
  }

  const onlyItems = pageOptions && pageOptions.onlyItems;

  if (onlyItems)
    return of({
      action$: of(loadstockTrackings(options)),
      page: {
        ..._state$.value.page,
        filter,
      },
    });

  return api.graphApi(stockTrackingPageQuery, { options }).pipe(
    map(({ pages: { stockTrackings: page }, stockTracking: { list } }) => {
      if (!page)
        return null;

      page.component = PageComponentNames.StockTrackings;
      page.size = size;
      page.index = index;
      page.backTo = backTo;
      return { page, action$: of(stockTrackingsReceived({ list })) };
    }),
    initSystemPageContent(),
  );
};

import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import Actions from './Actions';
import { StockBox } from './StockBox';
import { StockType } from 'behavior/pages/stockTrackings';
import { getFormatNumber } from 'utils/format';
import { useState } from 'react';
import statusIn from './images/check-lg.png';
import statusOut from './images/warn-lg.png';
import statusLow from './images/alert-lg.png';
import { useIsMobileViewport } from 'utils/hooks';
const Item = ({ tracking, culture }) => {
  const [editMode, setEditMode] = useState(false);
  const stockIndicator = () => {
    if (tracking == null) 
return;

    if (tracking.onHandStock < tracking.lowStock)
      return (
        <>
          <img src={statusOut} alt="Out" />
        </>
      );
    else if (tracking.onHandStock < tracking.optimalStock)
      return (
        <>
          <img src={statusLow} alt="Low" />
        </>
      );
    else
      return (
        <>
          <img src={statusIn} alt="In" />
        </>
      );
  };

  const formatNumber = getFormatNumber(culture);
  const isMobile = useIsMobileViewport();
  return (
    <>
      {tracking && (
        <tr>
          <td className={!isMobile ? 'vertical-align-top' : 'mobile-table-row'}>
            <div
              className={!isMobile ? 'visually-hidden' : 'mobile-table-name'}
            >
              <SimpleText textKey="StockTrackings_ItemNo" />
            </div>
            <div className="mobile-table-value">
              {tracking.variantId !== '' ? tracking.variantId : tracking.productId}
              {tracking.barcode && (
                <div className="variant-id-block">
                  <strong>
                    <SimpleText textKey="StockTrackings_List_Barcode" />
                  </strong>
                  <span> {tracking.barcode}</span>
                </div>
              )}
            </div>
          </td>
          <td className={!isMobile ? 'vertical-align-top' : 'mobile-table-row'}>
            <div
              className={!isMobile ? 'visually-hidden' : 'mobile-table-name'}
            >
              <SimpleText textKey="StockTrackings_Title" />
            </div>
            <div className="mobile-table-value">
              {tracking.title}
              {tracking.manufacturer && (
                <div>
                  <strong>
                    <SimpleText textKey="StockTrackings_List_Manufacture" />
                  </strong>
                  <span> {tracking.manufacturer}</span>
                </div>
              )}
            </div>
          </td>
          <td className={!isMobile ? 'table-text-center vertical-align-top' : 'mobile-table-row'}>
            <div
              className={!isMobile ? 'visually-hidden' : 'mobile-table-name'}
            >
              <SimpleText textKey="StockTrackings_List_LowStock" />
            </div>
            <div className="mobile-table-value tb-center-block">
              {
                <StockBox
                  key={'low_' + tracking.id}
                  value={tracking.lowStock}
                  type={StockType.Low}
                  tracking={tracking}
                  culture={culture}
                  editMode={editMode}
                  isEditing={setEditMode}
                  allowEmpty
                />
              }
            </div>
          </td>
          <td className={!isMobile ? 'table-text-center vertical-align-top' : 'mobile-table-row'}>
            <div
              className={!isMobile ? 'visually-hidden' : 'mobile-table-name'}
            >
              {' '}
              <SimpleText textKey="StockTrackings_List_OptimalStock" />
            </div>
            <div className="mobile-table-value tb-center-block">
              {
                <StockBox
                  key={'optimal_' + tracking.id}
                  value={tracking.optimalStock}
                  type={StockType.Optimal}
                  tracking={tracking}
                  culture={culture}
                  editMode={editMode}
                  isEditing={setEditMode}
                />
              }
            </div>
          </td>
          <td className={!isMobile ? 'table-text-center vertical-align-top' : 'mobile-table-row'}>
            <div
              className={!isMobile ? 'visually-hidden' : 'mobile-table-name'}
            >
              {' '}
              <SimpleText textKey="StockTrackings_List_OnHandStock" />
            </div>
            <div className="mobile-table-value tb-center-block">
              <span className="onhand-stock-no">{formatNumber(tracking.onHandStock)}</span>
              <br />
              <span className="onhand-stock-label">{tracking.unitOfMeasure}</span>
            </div>
          </td>
          <td className={!isMobile ? 'table-text-center vertical-align-middle' : 'mobile-table-row'}>
            <div
              className={!isMobile ? 'visually-hidden' : 'mobile-table-name'}
            >
              <SimpleText textKey="StockTrackings_List_Status" />
            </div>
            <div className="mobile-table-value">{stockIndicator()}</div>
          </td>
          <td className={!isMobile ? 'dk-pdr-none' : 'mobile-table-row'}>
            <div
              className={!isMobile ? 'visually-hidden' : 'mobile-table-name'}
            >
              &nbsp;
            </div>
            <div className="mobile-table-value">
              <Actions tracking={tracking} />
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

Item.propTypes = {
  tracking: PropTypes.object,
  culture: PropTypes.string,
};

export default Item;

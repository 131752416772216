import { useContext, useEffect, useState } from 'react';
import styles from './StockReduction.module.scss';
import tableStyles from 'components/primitives/table/Table.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SimpleText } from 'components/sanaText';
import { useSelector } from 'react-redux';
import { createQuantityModel } from 'components/primitives/product';
import { getNumberDecimalsSeparator } from 'utils/format';
import StockReductionContext from './StockReductionContext';
import {
  deleteFromList,
  addStockReductionList,
  deleteList,
  UpdateType,
} from 'behavior/pages/stockReduction';
import { SanaButton } from 'components/primitives/buttons';
import { toasts } from 'behavior/toasts';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import { DangerAlert } from 'components/primitives/alerts';
import Item from './Item';

const List = ({
  items,
  addedItem,
  status,
  deleteFromList,
  deleteList,
  addStockReductionList,
  result,
}) => {
  const {
    lines,
    addLine: updateContextLine,
    reset: resetContext,
    updateQuantity: updateContextQuantity,
    updateComment: updateContextComment,
  } = useContext(StockReductionContext);
  const [addedSuccessfully, addedPatially, listMsgNotAdded, listMsgAdjusted] = useSanaTexts([
    'StockReduction_Added_Successfully',
    'StockReduction_Added_Partially',
    'StockReduction_List_Message_NotAdded',
    'StockReduction_List_Message_Adjusted',
  ]).texts;
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (addedItem) {
      updateContextLine(addedItem);
      const { id, quantity, comment } = addedItem;
      updateContextQuantity(id, { isValid: true, value: quantity });
      updateContextComment(id, comment);
    }

    if (status === UpdateType.Saved) {
      resetContext();
      const searchInput = document.getElementById('productSelectorSearch');
      if (searchInput) {
        searchInput.value = '';
      }

      result?.length > 0 ? toasts.warning(makeSimpleText(addedPatially)) : toasts.success(makeSimpleText(addedSuccessfully));
    }
    setIsSubmitting(false);
  }, [addedItem]);

  const { culture } = useSelector(
    ({ localization }) => ({
      culture: localization.currentLanguage.cultureName,
    }),
  );

  const separator = getNumberDecimalsSeparator(culture);

  const onSubmit = e => {
    e.preventDefault();
    e.stopPropagation();

    const list = [];

    for (const [id, { quantity, comment }] of lines) {
      if (!quantity.isValid) {
        const elementId = 'qty' + id;
        document.getElementById(elementId).focus();
        return;
      }

      list.push({
        trackingId: id,
        quantity: quantity.value,
        comment,
      });
    }

    if (!list.length) {
      return;
    }
    setIsSubmitting(true);
    addStockReductionList(list);
  };

  const clearList = e => {
    e.preventDefault();
    e.stopPropagation();

    deleteList();
    resetContext();
  };

  return (
    <div>
      {result && result.length > 0 && (
        <DangerAlert role="alert">
        <div>
        <SimpleText textKey="StockReduction_List_Added_Partially" />
        <br />
        {result.map((item, index) => {        
                const message = item.onHandStock > 0 ? listMsgAdjusted : listMsgNotAdded;
                return (<div key={index}>{makeSimpleText(message.replace('[PRODUCT_ID]', item.variantId ? item.variantId : item.productId).replace('[ON_HAND_STOCK]', item.onHandStock))}</div>);
              })}
          </div>
        </DangerAlert>
      )}
      
      {items && items.length > 0 && (
        <form onSubmit={onSubmit}>
          <div className={styles.tableWrapper}>
            <table className={`${tableStyles.table} stock-tracking-table stock-reduction-table`}>
              <thead>
                <tr>
                  <th>
                    <SimpleText textKey="StockReduction_List_ItemNo" />
                  </th>
                  <th>
                    <SimpleText textKey="StockReduction_List_Title" />
                  </th>
                  <th className={`${styles.textCenter} `}>
                    <SimpleText textKey="StockReduction_List_Quantity" />
                  </th>
                  <th>
                    <SimpleText textKey="StockReduction_List_Comment" />
                  </th>
                  <th />
                </tr>
              </thead>
              {items.map((item, index) => {        
                const quantityModel = createQuantityModel(item.uom, false);
                return ( <Item key={index} item={item} quantityModel={quantityModel} separator={separator} deleteFromList={deleteFromList} />);
              })}
            </table>
          </div>
          <div className={styles.btnWrapper}>
            <SanaButton textKey="Cancel" onClick={e => clearList(e)} disabled={isSubmitting} />
            <SanaButton className={`${btnStyles.btnAction}`}  textKey="AddStockReduction" type="submit" disabled={isSubmitting} />
            </div>
        </form>
      )}
    </div>
  );
};

List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  addedItem: PropTypes.object,
  addStockReductionList: PropTypes.func.isRequired,
  deleteFromList: PropTypes.func.isRequired,
  deleteList: PropTypes.func.isRequired,
  status: PropTypes.string,  
  result: PropTypes.object,
};

export default connect(null, {
  deleteFromList,
  deleteList,
  addStockReductionList,
})(List);
import buttonStyles from 'components/primitives/buttons/Button.module.scss';
import styles from './StockTransactions.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { Modal, useModal } from 'components/objects/modals';
import { SanaButton } from 'components/primitives/buttons';
import { SimpleText } from 'components/sanaText';
import { reverseReduction } from 'behavior/pages/stockTransaction/actions';
import { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import resetImage from './images/reload.png';

const ReverseReductionConfirmationModal = ({ trackingId, transactionId, isReduceStock, isReversed, isExpiredForReversal }) => {

  const { opened, show, hide } = useModal();
  const reverseReason = useRef();

  const onClick = () => {
    show();
  };

  const revearseReduction = useCallback(() => {
    const input = {
      trackingId: trackingId,
      transactionId: transactionId,
      reverseReason: reverseReason.current.value
    };
    dispatch(reverseReduction(input));
  }, []);

  const dispatch = useDispatch();

  return (
    <>
      <div>
        {isReduceStock && !isReversed && !isExpiredForReversal && <button className={`${styles.imgButton}`} onClick={() => onClick()}><img src={resetImage} alt="Reset" /></button>}
      </div>
      <Modal opened={opened} hide={hide} resetContentOnClose>
        <div>
          <div>
            <h2 className={`${styles.modelHeader}`}>  <SimpleText textKey="StockTransactions_ReverseReductionPopup_Header" /> </h2>
            <p className={`${styles.modelContent}`}><SimpleText textKey="StockTransactions_ReverseReductionPopup_Body" /></p>
            <textarea required name="comment" id="reverseReason" className={`${styles.textArea} form-control`} ref={reverseReason} maxLength="150" minLength="20" style={{ marginBottom: '20px' }} />
            <div className={`${styles.popupButtonWrapper}`}>
              <SanaButton
                textKey="Cancel"
                className={`${buttonStyles.btnExtraSmall} ${buttonStyles.noIcon}`}
                onClick={hide}
              />
              {
                <SanaButton
                  textKey="StockTransactions_ReverseReductionPopup_Proceed"
                  className={`${buttonStyles.btnExtraSmall} ${buttonStyles.noIcon} ${buttonStyles.btnAction}`}
                  onClick={revearseReduction}
                />
              }
            </div>
          </div>
        </div>
      </Modal>
    </>

  );
};

ReverseReductionConfirmationModal.propTypes = {
  opened: PropTypes.bool.isRequired,
};

export default memo(ReverseReductionConfirmationModal);

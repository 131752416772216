import {
  STOCK_REDUCTION_TO_LIST_ADD,
  STOCK_REDUCTION_TO_LIST_ADDED,
  STOCK_REDUCTION_SAVED,
  STOCK_REDUCTION_PRODUCTS_RECEIVED,
  STOCK_REDUCTION_DELETE_FROM_LIST,
  STOCK_REDUCTION_DELETE_LIST,
} from './actions';
import { createReducer } from 'utils/redux';
import { arrayToObject } from 'utils/helpers';
import { UpdateType } from './constants';

export default createReducer(null, {
  [STOCK_REDUCTION_TO_LIST_ADD]: onProductAddToReductionList,
  [STOCK_REDUCTION_TO_LIST_ADDED]: onProductAddedToReductionList,
  [STOCK_REDUCTION_SAVED]: onStockReductionListAdded,
  [STOCK_REDUCTION_PRODUCTS_RECEIVED]: onProductsReceived,
  [STOCK_REDUCTION_DELETE_FROM_LIST]: onDeleteFromList,
  [STOCK_REDUCTION_DELETE_LIST]: onDeleteList,
});

function onProductAddedToReductionList(state, action) {
  const record = action.payload;

  if (record) {
    if (!state.items) {
      return {
        ...state,
        items: [record],
        addedItem: record,
        status: UpdateType.Added,
        result : null,
      };
    } else if (state.items.some(item => item.id === record.id)) {
      if (record.isBarcodeScanResult) {
        const quantity =
          state.items.find(item => item.id === record.id).quantity + 1;
          record.quantity =   quantity;
        
        return {
          ...state,
          items: state.items.map(item =>
            item.id === record.id ? { ...item, quantity } : item,
          ),
          status: UpdateType.QuantityAdded,
          addedItem: record,
        };
      } else {
        return {
          ...state,
          status: UpdateType.AlreadyAdded,
        };
      }
    } else if (state.items.length === 20) {
      return {
        ...state,
        status: UpdateType.ListFull,
      };
    }

    return {
      ...state,
      items: state.items.concat(record),
      addedItem: record,
      status: UpdateType.Added,
    };
  } else {
    return {
      ...state,
      status: UpdateType.NotExist,
    };
  }
}

function onDeleteFromList(state, action) {
  const id = action.payload;
  return {
    ...state,
    items: state.items.filter(item => item.id !== id),
    status: UpdateType.Removed,
  };
}

function onDeleteList(state, action) {
  return {
    ...state,
    items: null,
    addedItem: null,
    status: UpdateType.Removed,
  };
}

function onProductAddToReductionList(state, action) {
  return {
    ...state,
    status: UpdateType.Adding,
  };
}

function onStockReductionListAdded(state, action) {
  return {
    ...state,
    items: null,
    addedItem: null,
    status: UpdateType.Saved,
    result: action.payload?.result,
  };
}

function onProductsReceived(state, action) {
  return {
    ...state,
    products: {
      ...state.products,
      ...arrayToObject(action.payload.products, product => product.id),
    },
  };
}

import styles from './BasketB2B.module.scss';
import modalStyles from 'components/objects/modals/Modal.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { saveOrderTemplate } from 'behavior/pages/orderTemplates';
import { Modal, useModal } from 'components/objects/modals';
import { SanaForm, FormGroup, useFormLockerOnSubmit } from 'components/objects/forms';
import { TextBoxField } from 'components/objects/forms/fields';
import { Button } from 'components/primitives/buttons';
import { useSimpleTexts } from 'components/sanaText';
import { FormLockKeys } from 'behavior/pages';
import { toasts } from 'behavior/toasts';
import { useUpdateContext, usePendingChangesContext } from '../updateContext';
import { useIsQuickOrderMode } from '../hooks';
import { useOnChange } from 'utils/hooks';
import { SaveIcon } from 'components/primitives/icons';
import { useHandlerLockerInPreview } from 'components/objects/preview';

const SaveOrderTemplate = ({ placeholder, isEmpty, savingResult, modelExpired, modifiedDate, saveOrderTemplate }) => {
  const { submit, getModifiedLines } = useUpdateContext();
  const pendingChanges = usePendingChangesContext();
  const isQuickOrderMode = useIsQuickOrderMode();
  const { opened, show, hide } = useModal();
  const { texts, textsLoaded } = useSaveOrderTemplateTexts();

  const handleSubmit = useFormLockerOnSubmit(
    formData => saveOrderTemplate(formData),
    FormLockKeys.SaveOrderTemplate,
    [],
  );

  const handleClick = useHandlerLockerInPreview(() => {
    // If basket is empty or has invalid quantity box inputs then do nothing.
    if (isEmpty || !getModifiedLines())
      return;

    if (pendingChanges || isQuickOrderMode) {
      const isBasketUpdated = submit();
      if (!isBasketUpdated)
        return;
    }

    show();
  });

  useHidePopupOnBasketPageExpire(opened, hide, modelExpired, modifiedDate);

  useEffect(() => {
    if (!savingResult)
      return;

    const { failureText } = savingResult;

    if (failureText === undefined)
      return;

    hide();

    if (failureText === null) {
      toasts.success(texts.saveSuccessfulPopup, { className: styles.toast });
    } else {
      toasts.error(failureText, { className: styles.toast });
    }
  }, [savingResult]);

  if (!textsLoaded)
    return placeholder;

  return (
    <>
      <button
        className={`${linkStyles.link} ${styles.saveTemplateBtn}`}
        aria-disabled={isEmpty}
        tabIndex={isEmpty ? -1 : null}
        onClick={handleClick}
      >
        <span className={styles.iconWrapper} aria-hidden>
          <SaveIcon className={styles.icon} />
        </span>
        {texts.saveAsTemplateBtn}
      </button>
      <Modal opened={opened} hide={hide} className={styles.saveTemplatePopup} resetContentOnClose>
        <h2 className={`h3 ${modalStyles.title}`}>{texts.savePopupHeader}</h2>
        <SanaForm
          name="SaveOrderTemplate"
          onSubmit={handleSubmit}
          shouldReset={savingResult}
        >
          <FormGroup
            fieldName="name"
            fieldTitle={texts.templateNameTitle}
            fieldComponent={TextBoxField}
            maxLength={100}
            required
            autoFocus
            validation={{
              required: true,
              uniqueOrderTemplate: true,
            }}
          />
          <div className={styles.saveTemplateActions}>
            <Button
              type="button"
              className={`${btnStyles.btn} ${btnStyles.btnExtraSmall} ${btnStyles.noIcon}`}
              onClick={hide}
              aria-keyshortcuts="Escape"
            >
              {texts.cancelBtn}
            </Button>
            <Button
              type="submit"
              className={`${btnStyles.btn} ${btnStyles.btnExtraSmall} ${btnStyles.noIcon} ${btnStyles.btnAction}`}
            >
              {texts.saveBtn}
            </Button>
          </div>
        </SanaForm>
      </Modal>
    </>
  );
};

SaveOrderTemplate.propTypes = {
  placeholder: PropTypes.node,
  isEmpty: PropTypes.bool.isRequired,
  savingResult: PropTypes.shape({
    failureText: PropTypes.string,
  }),
  modelExpired: PropTypes.bool,
  modifiedDate: PropTypes.number,
  saveOrderTemplate: PropTypes.func.isRequired,
};

export default connect(
  ({ orderTemplates: { savingResult }, basket: { modelExpired, modifiedDate } }) => ({ savingResult, modelExpired, modifiedDate }),
  { saveOrderTemplate },
)(SaveOrderTemplate);

function useHidePopupOnBasketPageExpire(opened, hide, modelExpired, modifiedDate) {
  const isBasketPageExpired = useRef(false);

  useOnChange(() => {
    if (!modelExpired)
      return;

    isBasketPageExpired.current = true;
  }, [modelExpired && modifiedDate], false);

  useEffect(() => {
    if (!opened || modelExpired || !isBasketPageExpired.current)
      return;

    isBasketPageExpired.current = false;
    hide();
  }, [modelExpired, opened, hide], false);
}

function useSaveOrderTemplateTexts() {
  const {
    texts: [
      saveAsTemplateBtn,
      savePopupHeader,
      saveSuccessfulPopup,
      saveBtn,
      cancelBtn,
      templateNameTitle,
    ],
    loaded: textsLoaded,
  } = useSimpleTexts([
    'ButtonText_SaveAsTemplate',
    'OrderTemplate_SavePopupHeader',
    'OrderTemplate_SaveSuccessfully',
    'ButtonText_Save',
    'ButtonText_Cancel',
    'OrderTemplate_SavePopupTemplateName',
  ]);

  return {
    texts: {
      saveAsTemplateBtn,
      savePopupHeader,
      saveSuccessfulPopup,
      saveBtn,
      cancelBtn,
      templateNameTitle,
    },
    textsLoaded,
  };
}
import styles from '../SalesAgreements.module.scss';
import PropTypes from 'prop-types';
import { Col, Row } from 'components/primitives/grid';
import { Select } from 'components/primitives/form';
import { focusById } from 'utils/dom';

const VariantDropdownRow = ({ id, value, groupName, variantComponents, onChange }) => {
  const items = variantComponents.map(c => ({ name: c.name, value: c.id }));
  const labelId = id + '_lb';

  return (
    <Row className={styles.orderBoxRow} crossAxisAlign="center">
      <Col xs={4} sm={3}>
        {/* onClick is used to simulate default label behavior. */}
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/click-events-have-key-events */}
        <label className={styles.orderBoxLabel} id={labelId} onClick={focusById.bind(null, id)}>{groupName}</label>
      </Col>
      <Col xs={8} sm={9}>
        <Select
          items={items}
          onChange={onChange}
          value={value}
          id={id}
          labelId={labelId}
          className={styles.variant}
          optionClassName={styles.variantOption}
        />
      </Col>
    </Row>
  );
};

VariantDropdownRow.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  variantComponents: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  })),
  onChange: PropTypes.func,
};

export default VariantDropdownRow;

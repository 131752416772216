import { useState, useEffect, useMemo } from 'react';
import { Row, Col } from 'components/primitives/grid';
import styles from './ProductSelector.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import PropTypes from 'prop-types';
import VariantDropdowns from './VariantDropdowns';
import QuantityBox from './QuantityBox';
import { SimpleText } from 'components/sanaText';

const QUANTITY_INPUT_ID = 'productSelectorQuantity';
const LOW_STOCK_INPUT_ID = 'productSelectorLowStock';
const OPTIMAL_STOCK_INPUT_ID = 'productSelectorOptimalStock';

const ProductPanel = ({
  texts,
  product,
  onProductAdd,
  productAddDisabled,
  allowAddUnorderableProduct,
  searchInputId,
  preSelectedVariantId,
  isStockTracking,
}) => {
  const {
    id,
    title,
    hasVariants,
    variantComponentGroups,
    isOrderable,
    uom,
    barcode,
  } = product;

  const { addBtn, productCannotBeOrderedMessage } = texts;
  const productUomId = uom && uom.id;
  const [startingStock, setStartingStock] = useState(0);
  const [lowStock, setLowStock] = useState(1);
  const [optimalStock, setOptomalStock] = useState(1);
  const [uomId, setUomId] = useState(productUomId);
  const [variantId, setVariantId] = useState('');
  const [barcodeVal, setBarcodeVal] = useState(barcode);
  const componentsAvailable = useMemo(
    () => variantComponentGroups.every(group => group.components.length > 0),
    [variantComponentGroups],
  );

  useEffect(() => {
    setUomId(productUomId);

    if (!hasVariants) 
setVariantId('');
  }, [id]);

  const onSubmit = e => {
    e.preventDefault();
    e.stopPropagation();

    if (!startingStock.isValid) {
      document.getElementById(QUANTITY_INPUT_ID).focus();
      return;
    }
    if (isStockTracking) {
      if (!lowStock.isValid) {
        document.getElementById(LOW_STOCK_INPUT_ID).focus();
        return;
      }

      if (!optimalStock.isValid) {
        document.getElementById(OPTIMAL_STOCK_INPUT_ID).focus();
        return;
      }

      if (optimalStock.value <= lowStock.value) {
        document.getElementById(OPTIMAL_STOCK_INPUT_ID).focus();
        return;
      }
    }

    const line = isStockTracking
      ? {
          productId: id,
          variantId,
          uomId,
          onHandStock: startingStock.value,
          lowStock: lowStock.value,
          optimalStock: optimalStock.value,
        }
      : {
          productId: id,
          variantId,
          uomId,
          quantity: startingStock.value,
        };

    onProductAdd(line, product);

    const searchInput = document.getElementById(searchInputId);
    searchInput.focus();
    searchInput.select();
  };

  return (
    <div className={styles.productPanelWrapper}>
      <form
        onSubmit={onSubmit}
        className={`${styles.product} ${
          allowAddUnorderableProduct || isOrderable ? '' : styles.cannotOrder
        }`}
      >
        <Row>
          <Col xs="12">
            <div className={styles.selectVariantContainer}>
              {hasVariants && (
                <VariantDropdowns
                  productId={id}
                  preSelectedVariantId={preSelectedVariantId}
                  variantComponentGroups={variantComponentGroups}
                  updateVariantId={setVariantId}
                  updateBarcode={setBarcodeVal}
                />
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="7" sm="12">
            <div className={`${styles.productInfo} stocktracking-product-info`}>
              <div className={styles.itemnoBlock}>
                <div className={styles.formBlock}>
                  <span className={styles.label}>
                    <SimpleText textKey="StockTrackings_ItemNo" />
                  </span>
                  <span className={styles.value}> {id}</span>
                </div>
                {hasVariants && (
                  <div className={styles.formBlock}>
                    <span className={styles.label}>
                      <SimpleText textKey="StockTrackings_VariantId" />
                    </span>
                    <span className={styles.value}> {variantId}</span>
                  </div>
                )}
              </div>
              <div className={styles.itemnameBlock}>
                <div className={styles.formBlock}>
                  <span className={styles.label}>
                    <SimpleText textKey="StockTrackings_ItemName" />
                  </span>
                  <span className={styles.value}> {title}</span>
                </div>
              </div>
              <div className={styles.barcodeBlock}>
                <div className={styles.formBlock}>
                  <span className={styles.label}>
                    <SimpleText textKey="StockTrackings_Barcode" />
                  </span>
                  <span className={styles.value}> {barcodeVal}</span>
                </div>
              </div>
            </div>
          </Col>
          <Col xs="12" md="5" sm="12">
            {(allowAddUnorderableProduct || isOrderable) &&
            componentsAvailable ? (
              <div className={styles.qtyBoxWrapper}>
                <div className={styles.qtyBox}>
                  {isStockTracking && (
                    <span className={styles.qtyBoxLabel}>
                      <SimpleText textKey="StockTrackings_StartingStock" />
                    </span>
                  )}
                  <QuantityBox
                    inputId={QUANTITY_INPUT_ID}
                    product={product}
                    value={startingStock && startingStock.value}
                    updateQuantity={setStartingStock}
                    uomId={uomId}
                    updateUom={setUomId}
                    allowEmpty
                  />
                </div>
                {isStockTracking && (
                  <>
                    <div className={styles.qtyBox}>
                      <span className={styles.qtyBoxLabel}>
                        <SimpleText textKey="StockTrackings_LowStock" />
                      </span>
                      <QuantityBox
                        inputId={LOW_STOCK_INPUT_ID}
                        product={product}
                        value={lowStock && lowStock.value}
                        updateQuantity={setLowStock}
                        uomId={uomId}
                        updateUom={setUomId}
                        allowEmpty
                      />
                    </div>
                    <div className={styles.qtyBox}>
                      <span className={styles.qtyBoxLabel}>
                        <SimpleText textKey="StockTrackings_OptimalStock" />
                      </span>
                      <QuantityBox
                        inputId={OPTIMAL_STOCK_INPUT_ID}
                        product={product}
                        value={optimalStock && optimalStock.value}
                        updateQuantity={setOptomalStock}
                        uomId={uomId}
                        updateUom={setUomId}
                      />
                    </div>
                  </>
                )}
                {optimalStock &&
                  lowStock &&
                  optimalStock.value <= lowStock.value && (
                    <div className="optimal-stockvalue-text">
                      <SimpleText textKey="StockTrackings_OptimalStockValidation" />
                    </div>
                  )}

                <div className={styles.actions}>
                  <button
                    className={`${btnStyles.btn} ${btnStyles.btnSmall} ${btnStyles.btnAction} ${styles.btnAdd}`}
                    type="submit"
                    aria-disabled={productAddDisabled}
                  >
                    <span className="btn-cnt">{addBtn}</span>
                  </button>
                </div>
              </div>
            ) : (
              <div className={styles.msg}>{productCannotBeOrderedMessage}</div>
            )}
          </Col>
        </Row>
      </form>
    </div>
  );
};

ProductPanel.propTypes = {
  texts: PropTypes.shape({
    addBtn: PropTypes.string,
    productCannotBeOrderedMessage: PropTypes.node,
  }).isRequired,
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    variantComponentGroups: PropTypes.arrayOf(
      PropTypes.shape({
        components: PropTypes.array.isRequired,
      }),
    ).isRequired,
    isOrderable: PropTypes.bool,
    uom: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  onProductAdd: PropTypes.func.isRequired,
  productAddDisabled: PropTypes.bool,
  allowAddUnorderableProduct: PropTypes.bool,
  productClickTrackingSource: PropTypes.string.isRequired,
  searchInputId: PropTypes.string.isRequired,
  preSelectedVariantId: PropTypes.string,
  isStockTracking: PropTypes.bool.isRequired,
};

export default ProductPanel;

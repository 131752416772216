export const STOCK_NOTIFICATIONS_REQUESTED = 'STOCK_NOTIFICATIONS_REQUESTED';
export const loadstockNotifications = calculated => ({
  type: STOCK_NOTIFICATIONS_REQUESTED,
  payload: { calculated },
});

export const STOCK_NOTIFICATIONS_RECEIVED = 'STOCK_NOTIFICATIONS_RECEIVED';
export const stockNotificationReceived = stockNotificationList => ({
  type: STOCK_NOTIFICATIONS_RECEIVED,
  payload: stockNotificationList,
});

export const STOCK_NOTIFICATIONS_ADD_PRODUCT = 'STOCK_NOTIFICATIONS_ADD_PRODUCT';
export const addProductToBasketList = product => ({
  type: STOCK_NOTIFICATIONS_ADD_PRODUCT,
  payload: { product },
});

export const STOCK_NOTIFICATIONS_REMOVE_PRODUCT = 'STOCK_NOTIFICATIONS_REMOVE_PRODUCT';
export const removeProductFromBasketList = product => ({
  type: STOCK_NOTIFICATIONS_REMOVE_PRODUCT,
  payload: { product },
});

export const STOCK_NOTIFICATIONS_CREATE_BASKET_LINES = 'STOCK_NOTIFICATIONS_CREATE_BASKET_LINES';
export const addProductsToBasket = () => ({
  type: STOCK_NOTIFICATIONS_CREATE_BASKET_LINES,
});

export const STOCK_NOTIFICATIONS_ADD_PRODUCTS = 'STOCK_NOTIFICATIONS_ADD_PRODUCTS';
export const addProducts = lines => ({
  type: STOCK_NOTIFICATIONS_ADD_PRODUCTS,
  payload: { lines },
});

export const STOCK_NOTIFICATIONS_BASKET_UPDATED = 'STOCK_NOTIFICATIONS_BASKET_UPDATED';
export const basketUpdated = (modifiedDate) => ({
  type: STOCK_NOTIFICATIONS_BASKET_UPDATED,
  payload: {
    modifiedDate,    
  },
});
export const STOCK_NOTIFICATIONS_SELECTED_PRODUCTS_TO_ADD = 'STOCK_NOTIFICATIONS_SELECTED_PRODUCTS_TO_ADD';
export const selectedProductsToAdd = isProductsAdded => ({
  type: STOCK_NOTIFICATIONS_SELECTED_PRODUCTS_TO_ADD,
  payload: isProductsAdded,
});







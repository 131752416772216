import styles from './Header.module.scss';
import { memo, useState, useCallback } from 'react';
import { Col, Container, Row } from 'components/primitives/grid';
import { Logo } from 'components/objects/logo';
import { Search } from 'components/objects/search';
import MobileTopMenuOpener from './MobileTopMenuOpener';
import MobileTopMenu from './MobileTopMenu';
import { BasketSummary } from 'components/objects/basket';
import { StockNotifications } from 'components/objects/stockNotifications';
import { ProductSuggestions } from 'components/objects/productSuggestions';
import { isBrowser } from 'utils/detections';
import { MobileAccountMenu, MobileAccountMenuOpener } from 'components/objects/accountMenu';

const MobileHeaderTemplate = () => {
  const [expanded, setExpanded] = useState({});

  const setLoginInfoExpanded = useCallback(expanded =>
    setExpanded(prev => prev.loginInfo === expanded ? prev : { loginInfo: expanded }), []);
  const setMenuExpanded = useCallback(expanded =>
    setExpanded(prev => prev.menu === expanded ? prev : { menu: expanded }), []);

  const { menu: menuExpanded, loginInfo: loginInfoExpanded } = expanded;

  return (
    <>
      <Container fluid className={`${styles.mobileTopContainer} ${styles.noGutters}`}>
        <Row crossAxisAlign="center" noGutters>
          <Col xs="auto" className={styles.noGutters}>
            <MobileTopMenuOpener expanded={menuExpanded} setExpanded={setMenuExpanded} />
          </Col>
          <Col className={`${styles.column} ${styles.logoWrapper}`}>
            <Logo className={styles.logo} small />
          </Col>
          <Col xs="auto" className={styles.noGutters}>
            <MobileAccountMenuOpener expanded={loginInfoExpanded} setExpanded={setLoginInfoExpanded} />
          </Col>
          <Col xs="auto">
            <StockNotifications compact hideLabel/>
          </Col>
          <Col xs="auto">
            <BasketSummary compact hideLabel />
          </Col>
        </Row>
      </Container>
      <Container fluid className={`${styles.mobileMiddleContainer} ${styles.noGutters}`}>
        <MobileAccountMenu expanded={loginInfoExpanded} setExpanded={setLoginInfoExpanded} />
        <Search className={styles.searchBox} id="header-search-mobile" />
        <ProductSuggestions />
      </Container>
      <Container
        fluid
        suppressHydrationWarning
        className={`${styles.topMenuContainer} ${styles.noGutters}`}
      >
        {isBrowser && <MobileTopMenu expanded={menuExpanded} setExpanded={setMenuExpanded} />}
      </Container>
    </>
  );
};

export default memo(MobileHeaderTemplate);

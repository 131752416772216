import { useState, useCallback, useRef, useMemo } from 'react';
import { BrowserMultiFormatReader } from '@zxing/library';

const useBarcodeReader = () => {
  const scanningInterval = 400;
  const [barcode, setBarcode] = useState('');
  const [error, setError] = useState('');
  const [scanning, setScanning] = useState(true);
  const webcamRef = useRef(null);
  const barcodeReader = useMemo(() => new BrowserMultiFormatReader(), []);
  const captureInterval = useRef();

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current && webcamRef.current.getScreenshot();
    if (imageSrc) {
      setScanning(true);

      barcodeReader
        .decodeFromImage(undefined, imageSrc)
        .then(result => {
          setError('');
          setBarcode(result.text);
          clearInterval(captureInterval.current);     //otherwise the camera will work continuely
          setScanning(false);
        })
        .catch(err => {
          setError(err.message);
        });
    }
  }, [barcodeReader]);

  const startScanning = useCallback(() => {
      captureInterval.current = setInterval(capture, scanningInterval);
    },[]);

    const stopScanning = useCallback(() => {      
      clearInterval(captureInterval.current);
      setBarcode('');
      setScanning(false);
    },[]);

  const resetValues = () => {
    captureInterval.current = setInterval(capture, scanningInterval);
    setBarcode('');
    setError('');
    setScanning(true);
  };

  return {
    webcamRef,
    barcode,
    error,
    scanning,
    resetValues,
    setBarcode,
    setError,
    startScanning,
    stopScanning,
  };
};

export default useBarcodeReader;

import { memo } from 'react';
import PropTypes from 'prop-types';
import { abilityPropType } from 'behavior/user';
import SummaryLine from './SummaryLine';
import { ProductTrackingContext } from 'components/objects/analytics';
import { EVENT_SOURCES } from 'behavior/analytics';
import { iEquals } from 'utils/helpers';

const ProductLine = ({ line,isProductsAvailable }) => {
  const { id, productId, createdDate, modifiedDate, lowStock, onHandStock, optimalStock, title, variantId, unitOfMeasue, uom, actionedBy, isActioned,eanId } = line;
  return (
    <SummaryLine
      id={id}
      createdDate={createdDate}
      productId={productId}
      lowStock={lowStock}
      onHandStock={onHandStock}
      optimalStock={optimalStock}
      productTitle={title}
      variantTitle={variantId}
      uomId={uom}
      uomDescription={unitOfMeasue}
      modifiedDate={modifiedDate}
      actionedBy={actionedBy}
      isActioned={isActioned}
      isProductsAvailable={isProductsAvailable}
      eanId={eanId}
    />
  );
};

ProductLine.propTypes = {
  line: PropTypes.shape({
    id: PropTypes.string,
    productId: PropTypes.string.isRequired,
    eanId:PropTypes.string,
    createdDate: PropTypes.string,
    lowStock: PropTypes.number.isRequired,
    onHandStock: PropTypes.number.isRequired,
    optimalStock: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    variantId: PropTypes.string,
    unitOfMeasue: PropTypes.string,
    uom: PropTypes.string,
    modifiedDate: PropTypes.string,
    actionedBy: PropTypes.string,
    isActioned: PropTypes.bool,
    isProductsAvailable:PropTypes.bool,
  }),
};

export default memo(ProductLine);
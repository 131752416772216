import {
  STOCK_REDUCTION_TO_LIST_ADD,
  addToReductionListResultReceived,
  STOCK_REDUCTION_SAVE,
  addStockReductionListResultReceived,
} from './actions';
import { combineEpics, ofType } from 'redux-observable';
import { mergeMap, startWith, pluck } from 'rxjs/operators';
import {
  setLoadingIndicator,
  unsetLoadingIndicator,
} from 'behavior/loadingIndicator';
import { addStockReductionsQuery, getStockTrackingQuery } from './queries';
import {  clearProduct } from 'behavior/productSelector';

const getStockTrackingEpic = (actions$, _state$, { api, logger }) => {
  return actions$.pipe(
    ofType(STOCK_REDUCTION_TO_LIST_ADD),
    mergeMap(action => {
      const { item } = action.payload;
      const { productId, variantId, quantity, barcode, comment } = item;
      return api
        .graphApi(getStockTrackingQuery, { productId, variantId, barcode })
        .pipe(
          pluck('stockTracking', 'get'),
          mergeMap(item => {
            if (item) {
              item.quantity = quantity;
              item.comment = comment ? comment : '';
              
              if (barcode) 
                item.isBarcodeScanResult = true;
              else 
                item.isBarcodeScanResult = false;
            }

            return [
              addToReductionListResultReceived(item),
              unsetLoadingIndicator(),
            ];
          }),
          startWith(setLoadingIndicator()),
        );
    }),
  );
};

const addStockReductionsEpic = (actions$, _state$, { api, logger }) => {
  return actions$.pipe(
    ofType(STOCK_REDUCTION_SAVE),
    mergeMap(action => api.graphApi(addStockReductionsQuery, action.payload).pipe(
          pluck('stockTrackings', 'reduceOnHandStock'),
          mergeMap(result => [addStockReductionListResultReceived(result),unsetLoadingIndicator(), clearProduct()]),
          startWith(setLoadingIndicator()),
        ),
    ),
  );
};

export default combineEpics(addStockReductionsEpic, getStockTrackingEpic);

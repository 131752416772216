import PropTypes from 'prop-types';
import { CheckoutProcessNames } from 'behavior/pages/checkout';

const extendedTextsPropTypes = PropTypes.arrayOf(PropTypes.shape({
  text: PropTypes.string,
}));

const serviceLinesPropTypes = PropTypes.arrayOf(PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  price: PropTypes.number,
}));

export const overviewLinesPropTypes = {
  productLines: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      product: PropTypes.shape({
        id: PropTypes.string.isRequired,
        image: PropTypes.shape({
          small: PropTypes.string,
        }),
        isSpecialShipping: PropTypes.bool,
        surchargeValue: PropTypes.number, //133701 - [RFC Surcharges] 3.1. Display surcharge notices on the web shop
        surchargeType: PropTypes.string,   //133701 - [RFC Surcharges] 3.1. Display surcharge notices on the web shop
        images: PropTypes.arrayOf(PropTypes.shape({
          small: PropTypes.string,
          variantId: PropTypes.string,
        })),
        title: PropTypes.string.isRequired,
      }),
      subLines: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        quantity: PropTypes.number.isRequired,
        uom: PropTypes.shape({
          id: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
        }),
        variationId: PropTypes.string.isRequired,
        isVariantSpecialShipping: PropTypes.bool.isRequired,//[114886] 3.12. Warning on products that require additional shipping
        surchargeValue: PropTypes.number,   //133701 - [RFC Surcharges] 3.1. Display surcharge notices on the web shop
        surchargeType: PropTypes.string,     //133701 - [RFC Surcharges] 3.1. Display surcharge notices on the web shop
        extendedTexts: extendedTextsPropTypes,
        serviceLines: serviceLinesPropTypes,
      })),
      discount: PropTypes.number,
      quantity: PropTypes.number.isRequired,
      uom: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    })),
    totalCount: PropTypes.number.isRequired,
  }),
  serviceLines: serviceLinesPropTypes,
  linesLoaded: PropTypes.bool.isRequired,
  noImageUrl: PropTypes.string.isRequired,
  culture: PropTypes.string,
  currency: PropTypes.object,
  showImages: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
};

export const checkoutProcessNamePropType = PropTypes.oneOf(Object.values(CheckoutProcessNames));

export const addressStepPropTypes = {
  info: PropTypes.shape({
    shippingAddresses: PropTypes.array,
    billingAddress: PropTypes.shape({
      formatted: PropTypes.string,
    }),
    shippingAddress: PropTypes.shape({
      address: PropTypes.object,
      shippingOption: PropTypes.string,
    }),
    shippingTemplateFields: PropTypes.array,
    isQuote: PropTypes.bool,
  }),
  isCompleted: PropTypes.bool,
};

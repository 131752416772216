import { of, EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { checkoutInfoUpdated } from './actions';
import { getRefreshCheckoutQuery } from './queries';
import { navigateTo } from 'behavior/events';
import { DocumentType } from 'behavior/documents';
import {
  adjustPaymentMethodData,
  adjustShippingMethodData,
  adjustGuestProfileData,
  adjustCheckoutAddresses,
  updateShippingAddressIfNeeded,
} from './helpers';
import { routesBuilder } from 'routes';

export function navigateOnIncorrect(checkoutInfo) {
  if (checkoutInfo && checkoutInfo.quote)
    return navigateTo(routesBuilder.forDocument(checkoutInfo.quote.id, DocumentType.Quote), checkoutInfo.quote.url);

  return navigateTo(routesBuilder.forBasket());
}

export function refreshCheckoutData(state$, deps) {
  const state = state$.value,
    page = state.page, isGuest = page.info.isGuest || false;

  return deps.api.graphApi(getRefreshCheckoutQuery(isGuest, !!page.info.quote), {
    asQuote: page.info?.isQuote || false,
    maxLines: state.settings.checkout.maxOverviewLines + 1,
  }).pipe(
    mergeMap(({ checkout, viewer }) => {
      if (!checkout)
        return EMPTY;

      if (!checkout.valid)
        return of(navigateOnIncorrect(page.info));

      adjustPaymentMethodData(checkout);
      adjustShippingMethodData(checkout);
      adjustGuestProfileData(checkout);

      if (!isGuest)
        adjustCheckoutAddresses(checkout, viewer);

      return updateShippingAddressIfNeeded(checkout, state$, deps).pipe(
        mergeMap(updateAddress => {
          if (updateAddress)
            return of(checkoutInfoUpdated(checkout), updateAddress);

          return of(checkoutInfoUpdated(checkout));
        }),
      );
    }),
  );
}

exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".TermsAndAgreements_terms-container .TermsAndAgreements_chb{margin-left:17.7em;margin-right:0}.TermsAndAgreements_btn-close{display:block;margin:30px 0 0 auto}.TermsAndAgreements_popup{\n  /*!*/}", ""]);

// exports
exports.locals = {
	"terms-container": "TermsAndAgreements_terms-container",
	"termsContainer": "TermsAndAgreements_terms-container",
	"chb": "TermsAndAgreements_chb",
	"btn-close": "TermsAndAgreements_btn-close",
	"btnClose": "TermsAndAgreements_btn-close",
	"popup": "TermsAndAgreements_popup"
};
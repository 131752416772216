import { ofType } from 'redux-observable';
import { merge, of, EMPTY } from 'rxjs';
import { switchMap, map, mergeMap, startWith } from 'rxjs/operators';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import {
  AGREEMENT_APPLY,
  AGREEMENT_CANCEL,
  agreementApplied,
  agreementCanceled,
  AGREEMENT_APPLIED,
  AGREEMENT_TERMS_APPLY,
} from './actions';
import {
  applySalesAgreementMutation,
  cancelSalesAgreementMutation,
  applyTermsAutomaticallyMutation,
} from './queries';
import { retryWithToast } from 'behavior/errorHandling';
import { navigateTo } from 'behavior/events';
import { RouteName, routesBuilder } from 'routes';
import {
  modifyBasket,
  BASKET_REMOVE_AGREEMENT,
} from 'behavior/basket/actions';
import { skipIfPreviewWithToast } from 'behavior/preview';

export default function salesAgreementsEpic(action$, state$, { api, logger }) {
  const setLoading = setLoadingIndicator();
  const unsetLoading = unsetLoadingIndicator();

  const apply$ = action$.pipe(
    ofType(AGREEMENT_APPLY),
    skipIfPreviewWithToast(state$, { api }),
    switchMap(({ payload }) =>
      api.graphApi(applySalesAgreementMutation, { agreementId: payload.salesAgreementId }).pipe(
        map(({ salesAgreement }) => {
          if (salesAgreement.apply.success)
            return agreementApplied(payload.salesAgreementId);

          throw new Error(`Sales agreement with ID/Title: "${payload.salesAgreementId}" is not available anymore.`);
        }),
      ),
    ),
  );

  const cancel$ = action$.pipe(
    ofType(AGREEMENT_CANCEL),
    switchMap(() =>
      api.graphApi(cancelSalesAgreementMutation).pipe(
        map(() => agreementCanceled()),
      ),
    ),
  );

  const appliedAgreement$ = action$.pipe(
    ofType(AGREEMENT_APPLIED),
    switchMap(() => {
      const { routing } = state$.value;
      const routeData = routing.routeData;
      const routeName = routeData && routeData.routeName;

      if (routeName === RouteName.SalesAgreements)
        return of(navigateTo(routesBuilder.forBasket()));

      return EMPTY;
    }),
  );

  const applyTermsAutomatically$ = action$.pipe(
    ofType(AGREEMENT_TERMS_APPLY),
    switchMap(() =>
      api.graphApi(applyTermsAutomaticallyMutation).pipe(
        mergeMap(() => [unsetLoading, modifyBasket([])]),
        retryWithToast(action$, logger, _ => of(unsetLoading)),
        startWith(setLoading),
      ),
    ),
  );

  const removeAgreement$ = action$.pipe(
    ofType(BASKET_REMOVE_AGREEMENT),
    switchMap(() =>
      api.graphApi(cancelSalesAgreementMutation).pipe(
        mergeMap(() => [unsetLoading, modifyBasket([])]),
        retryWithToast(action$, logger, _ => of(unsetLoading)),
        startWith(setLoading),
      ),
    ),
  );

  return merge(apply$, cancel$, appliedAgreement$, applyTermsAutomatically$, removeAgreement$);
}

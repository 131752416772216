export const getProductLines = (products) => {
  const lines = [];
  products.map(product => {
    let splittedProps = product.split('|');

    let prId = splittedProps[0].split('productId-')[1];
    let uom = splittedProps[1].split('uomId-')[1];
    let vrId = splittedProps[2].split('variantId-')[1];
    let qty = splittedProps[3].split('quantity-')[1];

    const line = {
      productId: prId,
      uomId: uom,
      quantity: qty,
    };

    if (vrId)
      line.variantId = vrId;

    lines.push(line);
  });
  
  return lines;
};
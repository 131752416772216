import styles from './ProductSelector.module.scss';
import { memo, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useOnCustomerChanged } from 'utils/hooks';
import { searchProducts, requestProduct, clearProduct } from 'behavior/productSelector';
import ProductPanel from './ProductPanel';
import SearchBox from './SearchBox';
import LoadingIndicator from './LoadingIndicator';

const SEARCH_INPUT_ID = 'productSelectorSearch';

const ProductSelector = ({
  texts,
  onProductAdd,
  productAddDisabled,
  allowAddUnorderableProduct,
  productClickTrackingSource,
}) => {
  const dispatch = useDispatch();
  const { suggestions, selectedProduct } = useSelector(({ productSelector }) => ({
    suggestions: productSelector.suggestions,
    selectedProduct: productSelector.selectedProduct,
  }), shallowEqual);
  const productLoadingState = useState(false);
  const [productLoading, setProductLoading] = productLoadingState;
  const [variantId, setVariantId] = useState(undefined);

  const onProductSearch = useCallback((keywords, count) => dispatch(searchProducts(keywords, count)), []);
  const onProductSelect = useCallback((id, variantId) => {
    dispatch(requestProduct(id));
    if (variantId) {
      setVariantId(variantId);
    }
  }, []);
  const onProductClear = useCallback(() => dispatch(clearProduct()), []);
  
  useEffect(() => {
    setProductLoading(false);
  }, [selectedProduct]);

  useOnCustomerChanged(() => {
    onProductClear();
  });

  useEffect(() => () => {
    onProductClear();
  }, []);

  return (
    <div className={styles.box}>
      <SearchBox
        texts={texts}
        id={SEARCH_INPUT_ID}
        productLoadingState={productLoadingState}
        suggestions={suggestions}
        selectedProduct={selectedProduct}
        onProductSearch={onProductSearch}
        onProductSelect={onProductSelect}
        onProductClear={onProductClear}
      />
      <div className={styles.productWrapper}>
        {productLoading
          ? <LoadingIndicator text={texts.loadingText} />
          : !selectedProduct ? null : (
            <ProductPanel
              texts={texts}
              product={selectedProduct}
              preSelectedVariantId={variantId}
              onProductAdd={onProductAdd}
              productAddDisabled={productAddDisabled}
              allowAddUnorderableProduct={allowAddUnorderableProduct}
              productClickTrackingSource={productClickTrackingSource}
              searchInputId={SEARCH_INPUT_ID}
            />
          )}
      </div>
    </div>
  );
};

ProductSelector.propTypes = {
  texts: PropTypes.shape({
    searchBoxPlaceholder: PropTypes.string,
    addBtn: PropTypes.string,
    addBtnAlt: PropTypes.string,
    productNotFoundText: PropTypes.node,
    productCannotBeOrderedMessage: PropTypes.node,
    loadingText: PropTypes.string,
  }).isRequired,
  onProductAdd: PropTypes.func.isRequired,
  productAddDisabled: PropTypes.bool,
  allowAddUnorderableProduct: PropTypes.bool,
  productClickTrackingSource: PropTypes.string.isRequired,
};

export default memo(ProductSelector);
import {
  STOCK_NOTIFICATIONS_RECEIVED,
  STOCK_NOTIFICATIONS_BASKET_UPDATED,
  STOCK_NOTIFICATIONS_SELECTED_PRODUCTS_TO_ADD,
} from '../actions';
import { createReducer } from 'utils/redux';
import { initialState } from './constants';

import {
  STOCK_NOTIFICATIONS_BASKET_CHANGE_COMPLETED,
} from 'behavior/events';

export default createReducer(initialState, {
  [STOCK_NOTIFICATIONS_RECEIVED]: onStockNotificationsReceived,
  [STOCK_NOTIFICATIONS_BASKET_UPDATED]: onBasketUpdated,
  [STOCK_NOTIFICATIONS_SELECTED_PRODUCTS_TO_ADD]:onProductAdded,
  [STOCK_NOTIFICATIONS_BASKET_CHANGE_COMPLETED]: onBasketChangeCompleted,
});

function onStockNotificationsReceived(state, action) {

  const { stockNotificationList } = action.payload;

  if (!stockNotificationList)
    return { ...state };

  const {
    items,
    totalCount,
    showNotifications,
  } = stockNotificationList;
  const result = {
    items,
    totalCount,
    showNotifications,
  };

  return result;
}

function onProductAdded(state, action) {
 
  const { productsAvailable } = action.payload;

  if (!productsAvailable)
    return { ...state };

  const {
    isProductsAvailable,
  } = productsAvailable;
  const result = {
    isProductsAvailable,
  };

  return result;
}

function onBasketUpdated(state, action) {
  const { modifiedDate } = action.payload;

  return {
    ...state,
    updating: false,
    updated: {
      ...state.updated,      
      date: modifiedDate,
    },
  };
}

function onBasketChangeCompleted(state, action) {
  window.location.reload();
  //const { updatedLinesAmount } = action.payload;

  //const updatedBySync = true;
  //const updatedByBasket = false;
  //const hasUpdatedLines = updatedLinesAmount > 0;

  //const summaryExpired = updatedBySync
  //  ? updatedByDate !== state.modifiedDate
  //  : hasUpdatedLines || updatedByBasket;

  //const modifiedDate = summaryExpired
  //  ? updatedByDate || Date.now()
  //  : state.modifiedDate;

  //const loading = hasUpdatedLines && !updatedByBasket;

  //return {
  //  ...state,
  //  modifiedDate,
  //  modelExpired: updatedBySync,
  //  summary: {
  //    ...state.summary,
  //    expired: summaryExpired,
  //    loading,
  //    loaded: !loading,
  //  },
  //  updated: {
  //    ...state.updated,
  //    linesAmount: updatedLinesAmount,
  //  },
  /*};*/
}


import PropTypes from 'prop-types';
import Paging from 'components/primitives/paging';

const LinksPaging = ({ pageIndex, pageSize, totalCount, onPageChange }) => {
  const getUrl = page => {
    return '';
  };

  return (
    <Paging
      pageIndex={pageIndex}
      pageSize={pageSize}
      totalCount={totalCount}
      onSelect={onPageChange}
      scrollToId="stockTrackingList"
      getUrl={getUrl}
    />
  );
};

LinksPaging.propTypes = {
  pageIndex: PropTypes.number,
  pageSize: PropTypes.number,
  totalCount: PropTypes.number,
  onPageChange: PropTypes.func,
};

export default(LinksPaging);
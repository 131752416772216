import styles from './Summary.module.scss';
import { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { routesBuilder } from 'routes';
import { UomTitle } from 'components/primitives/product';
import { SimpleText } from 'components/sanaText';
import { abilityPropType } from 'behavior/user';
import { Link } from 'components/primitives/links';
import { WithProductClickTracking } from 'components/objects/analytics';
import { addProductToBasketList, removeProductFromBasketList } from 'behavior/stockNotifications/actions';
import { useDispatch } from 'react-redux';
import { variant } from '../product/Details.module.scss';

const SummaryLine = ({
  productId,
  eanId,
  lowStock,
  onHandStock,
  optimalStock,
  productTitle,
  variantTitle,
  uomId,
  uomDescription,
  actionedBy,
  isActioned,
  isProductsAvailable,
}) => {
  const route = routesBuilder.forProduct.bind(null, productId);
  const productUrl = 'w';
  const dispatch = useDispatch();
  const quantityToAdd = optimalStock - onHandStock;
  const productIdToShow = variantTitle ? variantTitle : productId;

  const handleCheck = event => {
    const product = 'productId-' + productId + '|uomId-' + uomId + '|variantId-' + variantTitle + '|quantity-' + quantityToAdd;
    if (event.target.checked) {
      isProductsAvailable();
      dispatch(addProductToBasketList(product));
    } else {
      isProductsAvailable();
      dispatch(removeProductFromBasketList(product));
    }
  };

  return (
    <div>
      <table>
        <tbody>
          <tr>
            {!isActioned &&
              <td className="check-box" colSpan="2">
              <div className="check-box-wrapper">
                  <input
                    type="checkbox"
                    name="options"
                    value={productId}
                    onClick={handleCheck}
                  />
                </div>
              </td>
            }
            {isActioned &&
              <td className="empty-td" />
            }
            <td className="detail" colSpan="4">
              <div className={styles.productTile}>
                <div className={styles.description}>
                  <span className={styles.productTitle}>{productTitle}</span>
                  <div className={styles.quantityPrice}>
                    <div className={styles.quantity}>
                      <span className={styles.lblQuantity}> <SimpleText textKey="StockNotificationsLine_ProductId" /></span>
                      <span className={styles.lblQuantity}>{productIdToShow}</span>
                    </div>
                  </div>
                  {/*{variantTitle &&*/}
                  {/*  <div className="variant">*/}
                  {/*    <span className={styles.lblQuantity}> <SimpleText textKey='StockNotificationsLine_VariantId' /></span>*/}
                  {/*    <span className={styles.variantTitle}>{variantTitle}*/}
                  {/*    </span>*/}
                  {/*  </div>*/}
                  {/*}*/}
                  {eanId &&
                    <div className={styles.quantityPrice}>
                      <div className={styles.quantity}>
                        <span className={styles.lblQuantity}> <SimpleText textKey="StockNotificationsLine_EANId" /></span>
                        <span className={styles.lblQuantity}>{eanId}</span>
                      </div>
                    </div>
                  }
                </div>
              </div>
              {isActioned && actionedBy &&
                <div className="action">
                  <span className={`${styles.lblQuantity} lbl-quantity-wrapper`}><SimpleText textKey="StockNotificationsLine_ActionedBy" /> <span className={styles.lblQuantity}>{actionedBy}</span>{ }</span>
                </div>
              }
            </td>
            <td className="on-hand" colSpan="2">
              <div className={styles.productTile}>
                <div className={styles.description}>
                  <div className={styles.quantityPrice}>
                    <div className={styles.quantity}>
                      <span className={styles.lblQuantity}> <SimpleText textKey="StockNotificationsLine_OnHandHeader" /></span>
                    </div>
                  </div>
                  {variantTitle &&
                    <div>
                      <span className={styles.lblQuantity}>{onHandStock}</span>
                    </div>
                  }
                  <div className={styles.quantityPrice}>
                    <div className={styles.quantity}>
                      <span className={styles.lblQuantity}>{onHandStock}</span>
                    </div>
                  </div>
                  <div className={styles.lblTextUom}>
                    <UomTitle id={uomId} description={uomDescription || ''} />
                  </div>
                </div>
              </div>
            </td>
            <td className="optimal" colSpan="2">
              <div className={styles.productTile}>
                <div className={styles.description}>
                  <div className={styles.quantityPrice}>
                    <div className={styles.quantity}>
                      <span className={styles.lblQuantity}> <SimpleText textKey="StockNotificationsLine_OptimalHeader" /></span>
                    </div>
                  </div>
                  {variantTitle &&
                    <div>
                      <span className={styles.lblQuantity}>{optimalStock}</span>
                    </div>
                  }
                  <div className={styles.quantityPrice}>
                    <div className={styles.quantity}>
                      <span className={styles.lblQuantity}>{optimalStock}</span>
                    </div>
                  </div>
                  <div className={styles.lblTextUom}>
                    <UomTitle id={uomId} description={uomDescription || ''} />
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
  );
};

SummaryLine.propTypes = {
  productId: PropTypes.string.isRequired,
  eanId: PropTypes.string,
  productUrl: PropTypes.string,
  productTitle: PropTypes.string,
  productImage: PropTypes.shape({
    small: PropTypes.string,
  }),
  variantTitle: PropTypes.string,
  uomId: PropTypes.string,
  uomDescription: PropTypes.string,
  quantity: PropTypes.number,
  price: PropTypes.number,
  currencyInfo: PropTypes.object,
  viewUomAbility: abilityPropType,
};

export default memo(SummaryLine);
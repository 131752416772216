import styles from '../Details.module.scss';

import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'components/primitives/grid';
import { SimpleText } from 'components/sanaText';
import { Placeholder } from 'components/primitives/placeholders';
import AgreementSelectorContext from './AgreementSelectorContext';
import ProductContext from '../ProductContext';
import { changeProductVariantForSalesAgreement } from 'behavior/pages/product';
import AgreementLinesDropdown from './AgreementLinesDropdown';

const AgreementSelector = ({
  salesAgreement,
  allowUomSelection,
  canViewUom,
}) => {
  const { uomId, updateUomId, variantId } = useContext(ProductContext);
  const { selectedAgreementLine, updateSelectedAgreementTerm } = useContext(AgreementSelectorContext);

  const linesToDisplay = salesAgreement.linesToDisplay;
  const productUomId = uomId?.toUpperCase();
  const preSelectedLineId = salesAgreement?.preSelectedLine?.id;

  const handleChange = (lineId, uomId) => {
    if (!lineId) {
      updateSelectedAgreementTerm({ lineId });
      return;
    }

    const newLine = getLine(linesToDisplay, lineId);
    updateSelectedAgreementTerm({
      lineId,
      isMaxEnforced: newLine?.isMaxEnforced,
      quantityRemaining: newLine?.quantities.remaining,
    });

    const newLineUom = newLine?.uom?.id;
    if (newLineUom && newLineUom.toUpperCase() !== uomId.toUpperCase())
      updateUomId(newLineUom);
  };

  useEffect(() => {
    if (!preSelectedLineId || !linesToDisplay?.length)
      return;

    const lineUom = getUom(linesToDisplay, preSelectedLineId);
    if (!allowUomSelection && canViewUom && lineUom && productUomId !== lineUom.id?.toUpperCase())
      return;

    selectedAgreementLine
      ? updateSelectedAgreementTerm({})
      : handleChange(preSelectedLineId, productUomId);
  }, [linesToDisplay, preSelectedLineId]);

  useEffect(() => {
    if (!selectedAgreementLine || !selectedAgreementLine.lineId || !linesToDisplay)
      return;

    // when product UOM is changed, the agreements dropdown should be reset
    const selectedAgreementUomId = getUom(linesToDisplay, selectedAgreementLine.lineId)?.id;
    if (selectedAgreementUomId && selectedAgreementUomId.toUpperCase() !== productUomId)
      updateSelectedAgreementTerm({ lineId: null });
  }, [linesToDisplay, productUomId, selectedAgreementLine]);

  const dispatch = useDispatch();

  useEffect(() => {
    variantId && dispatch(changeProductVariantForSalesAgreement(variantId, canViewUom, allowUomSelection));
  }, [variantId]);

  if (!linesToDisplay?.length)
    return null;

  return (
    <Row className={styles.row} crossAxisAlign="center">
      <Col xs={3} lg={2} className={styles.ellipsis} id="agreement_selector_Label">
        <SimpleText
          textKey="AgreementLine"
          placeholder={<Placeholder className={styles.agreementSelectorPlaceholder} />}
        />
      </Col>
      <Col xs={9} lg={10} aria-labelledby="agreement_selector_Label">
        <AgreementLinesDropdown
          agreementLines={linesToDisplay}
          selectedLineId={selectedAgreementLine?.lineId}
          canViewUom={canViewUom}
          onChange={lineId => handleChange(lineId, productUomId)}
        />
      </Col>
    </Row>
  );
};

AgreementSelector.propTypes = {
  salesAgreement: PropTypes.shape({
    linesToDisplay: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        isMaxEnforced: PropTypes.bool,
        uom: PropTypes.shape({
          id: PropTypes.string,
        }),
        quantities: PropTypes.shape({
          remaining: PropTypes.number,
        }).isRequired,
      }),
    ),
  }).isRequired,
  allowUomSelection: PropTypes.bool,
  canViewUom: PropTypes.bool,
};

export default AgreementSelector;

const getUom = (lines, lineNo) => getLine(lines, lineNo)?.uom;
const getLine = (lines, lineNo) => lines.find(line => line.id === lineNo);

import styles from '../../Checkout.module.scss';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { Link } from 'components/primitives/links';
import { Steps } from 'behavior/pages/checkout';
import StepDoneMark from '../StepDoneMark';
import { useStepNavigationInfo } from '../../hooks';

const ShippingMethodStep = ({
  className,
  isQuote,
  isPromotion,
  isCompleted,
  asLink,
  children,
}) => {
  const headerText = (
    <SimpleText
      textKey={`${isQuote ? 'Quote' : ''}CheckoutStep_ShippingMethods`}
    />
  );
  const { to, url } = useStepNavigationInfo(Steps.Shipping, isPromotion, isQuote);

  return (
    <section className={className}>
      <div className={styles.header}>
        <h2>
          {asLink ? <Link to={to} url={url}>{headerText}</Link> : headerText}
        </h2>
        {isCompleted && <StepDoneMark />}
      </div>
      {children &&
        <div className={styles.body}>
          {children}
        </div>
      }
    </section>
  );
};

ShippingMethodStep.propTypes = {
  className: PropTypes.string,
  isQuote: PropTypes.bool,
  isPromotion: PropTypes.bool,
  isCompleted: PropTypes.bool,
  asLink: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default ShippingMethodStep;

import styles from './StockTrackingsList.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  deleteStockTracking,
} from 'behavior/pages/stockTrackings';
import { useEffect } from 'react';
import deleteImage from './images/delete.png';
import trancationImage from './images/transaction.png';
import useConfirmation from 'components/objects/confirmation';
import { RichText } from 'components/sanaText';
import { Link } from 'components/primitives/links';
import { routesBuilder } from 'routes';
import { useCurrentRouteAsBackTo } from 'utils/hooks';

const Actions = ({
  tracking,
  status,
  deleteStockTracking,
}) => {
  const { showConfirmation, clearConfirmation, confirmationModal } = useConfirmation();
  useEffect(() => clearConfirmation, []);
  const deleteItem = () => {
    const remove = () => (deleteStockTracking(tracking.id));
    showConfirmation(
      remove,
      'StockTracking_Item_DeleteHeader',
      <RichText textKey="StockTracking_Item_DeleteMessage" />,
      { okText: 'ButtonText_Delete' },
      undefined,
    );
  };

  const backTo = useCurrentRouteAsBackTo();

  return (
    <div className="table-btn-right">
      <button onClick={deleteItem} className={`${styles.nonArrowBtn} Button_btn table-btn stock-tracking-delete-btn`}>
        <img src={deleteImage} alt="Delete" />
      </button>
      <Link to={routesBuilder.forStockTransaction(tracking.productId, tracking.variantId, backTo) }>
        <button className={`${styles.nonArrowBtn} Button_btn Button_btn-action table-btn stock-tracking-transaction-btn`}> <img src={trancationImage} alt="Trancation" /></button>
      </Link>    
      {confirmationModal}
    </div>
  );
};

Actions.propTypes = {
  tracking: PropTypes.object,
  status: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }),
  deleteStockTracking: PropTypes.func.isRequired,
};

export default connect(({ stockTrackings: { status } }) => ({ status }), {
  deleteStockTracking,
})(Actions);

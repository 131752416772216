import styles from './ProductSelector.module.scss';
import { memo, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { QuantityTextBox, UomSelector, createQuantityModel, useCanViewUom } from 'components/primitives/product';
import { getNumberDecimalsSeparator } from 'utils/format';

const QuantityBox = ({
  inputId,
  product,
  value,
  updateQuantity,
  uomId,
  updateUom,
  hideControlsOnBlur,
  allowEmpty,
}) => {
  const { id, uoms, isOrderable } = product;
  const canViewUom = useCanViewUom();
  const { allowUOMSelection, culture } = useSelector(({ settings, localization }) => ({
    allowUOMSelection: settings.product && settings.product.allowUOMSelection,
    culture: localization.currentLanguage && localization.currentLanguage.cultureName,
  }), shallowEqual);

  const quantity = useMemo(() => {
    const uom = product.uoms && product.uoms.find(u => u.id === uomId);
    const model = createQuantityModel(uom, allowEmpty);

    if(allowEmpty){
      model.minimum  = model.initial = 0;
    }

    return model;
  }, [product && product.id, uomId, inputId]);

  useEffect(() => {
    updateQuantity({ value: quantity.initial, isValid: true });
  }, [quantity]);

  const onUomChange = useCallback(uomId => void (updateUom(uomId)), [updateUom]);

  const showUnitOfMeasure = canViewUom && uomId
    && uoms && uoms.length > 0
    && (isOrderable || allowUOMSelection);

  const separator = getNumberDecimalsSeparator(culture);

  return (
    <div className={styles.quantityBox}>
      {quantity &&
        <div className={styles.quantity}>
          <QuantityTextBox
            id={inputId}
            quantity={quantity}
            value={value}
            onChange={updateQuantity}
            separator={separator}
            hideControlsOnBlur={hideControlsOnBlur}
          />
        </div>
      }
      {showUnitOfMeasure && (
        <div className={styles.uomSelector}>
          <UomSelector
            className={styles.uom}
            productId={id}
            allowUOMSelection={allowUOMSelection}
            uomId={uomId}
            uoms={uoms}
            onUomChange={onUomChange}
            isOrderable={isOrderable}
          />
        </div>
      )}
    </div>
  );
};

QuantityBox.propTypes = {
  inputId: PropTypes.string.isRequired,
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    uoms: PropTypes.array,
    isOrderable: PropTypes.bool.isRequired,
  }).isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  updateQuantity: PropTypes.func.isRequired,
  updateUom: PropTypes.func.isRequired,
  uomId: PropTypes.string,
  hideControlsOnBlur: PropTypes.bool,
  allowEmpty: PropTypes.bool,
};

export default memo(QuantityBox);

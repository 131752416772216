import PropTypes from 'prop-types';
import { SanaButton } from 'components/primitives/buttons';
import { LinkButton } from 'components/primitives/links';
import { SimpleText } from 'components/sanaText';
import { RouteName, routesBuilder } from 'routes';
import { OverlayActionLink } from './constants';
import btnStyles from 'components/primitives/buttons/Button.module.scss';

const ActionLink = ({ link, routeName, ...props }) => {
  switch (link) {
    case OverlayActionLink.Basket:
      if (routeName === RouteName.BasketPage)
        return <SanaButton textKey="BarCodeScanner_BasketLink" className={btnStyles.btnAction} {...props} />;

      return (
        <LinkButton to={routesBuilder.forBasket()} className={btnStyles.btnAction} {...props}>
          <SimpleText textKey="ButtonText_BarCodeScanner_BasketLink" />
        </LinkButton>
      );
    case OverlayActionLink.Checkout:
      if (routeName === RouteName.Checkout)
        return <SanaButton textKey="BarCodeScanner_CheckoutLink" className={btnStyles.btnAction} {...props} />;

      return (
        <LinkButton to={routesBuilder.forCheckout()} className={btnStyles.btnAction} {...props}>
          <SimpleText textKey="ButtonText_BarCodeScanner_CheckoutLink" />
        </LinkButton>
      );
    default:
      return null;
  }
};

ActionLink.propTypes = {
  link: PropTypes.number.isRequired,
  routeName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ActionLink;

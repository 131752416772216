import { Col, Row } from 'components/primitives/grid';
import { connect } from 'react-redux';
import { QuantityTextBox, createQuantityModel, adjustForShipping } from 'components/primitives/product';
import { getNumberDecimalsSeparator } from 'utils/format';
import { useMemo, useState, useCallback } from 'react';

///114880: [Autolack] 3.9. Stock transaction – Add to on-hand stock

const QuantityBox = ({ culture, uom, lineId, initialQuantity, quantityShippedRemained, getCurrentQuantity, updateQuantity }) => {
  const lineCurrentQuantity = getCurrentQuantity();
  const quantityValue = lineCurrentQuantity != null
    ? lineCurrentQuantity.value
    : initialQuantity;

  const [, forceUpdate] = useState();
  let quantityModel = useMemo(() => createQuantityModel(uom, false), [uom]);
  quantityModel = adjustForShipping(quantityModel, quantityShippedRemained);
  const separator = getNumberDecimalsSeparator(culture);
  const quantityTextBoxId = `${lineId}_qty`;
  const handleQuantityChange = useCallback(quantity => {
    updateQuantity(quantity);
    forceUpdate(quantity);
  }, [updateQuantity]);

  return (
    <Row crossAxisAlign="center">
      <Col xs={8} sm={9}>
        <QuantityTextBox
          id={quantityTextBoxId}
          quantity={quantityModel}
          value={quantityValue}
          onChange={handleQuantityChange}
          separator={separator}
          allowResetOnStepDecrease
        />
      </Col>
    </Row>
  );
};

export default connect(
  ({ localization }) => ({
    culture: localization.currentLanguage && localization.currentLanguage.cultureName,
  }),
)(QuantityBox);

import styles from '../BasketB2B.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { UseSanaButton } from 'components/primitives/buttons';
import { addBasketToWishList } from 'behavior/wishList/actions';
import { useModal } from 'components/objects/modals';
import { useSanaTexts } from 'components/sanaText';
import { useOnChange } from 'utils/hooks';
import { ConfirmationModal } from 'components/objects/wishList';
import { HeartIcon } from 'components/primitives/icons';

const AddToWishlist = ({ placeholder, isEmpty, wishList, addBasketToWishList }) => {
  const { opened, toggle } = useModal();
  const [
    succeedTitle,
    succeedDescription,
  ] = useSanaTexts([
    'WishList_ProductsAddedSuccessfully',
    'WishList_ProductSuccessfullyAddedPopupDescription',
  ]).texts;
  const addToWishList = () => {
    addBasketToWishList();
  };

  useOnChange(() => {
    toggle();
  }, [wishList], false);

  return (
    <>
      <UseSanaButton textKey="AddToWishList" placeholder={placeholder}>
        {text => (
          <button
            className={`${linkStyles.link} ${styles.wishlistBtn}`}
            aria-disabled={isEmpty}
            tabIndex={isEmpty ? -1 : null}
            onClick={addToWishList}
          >
            <span className={styles.iconWrapper} aria-hidden>
              <HeartIcon className={styles.icon} />
            </span>
            {text}
          </button>
        )}
      </UseSanaButton>
      <ConfirmationModal opened={opened} hide={toggle}>
        <h2>{succeedTitle}</h2>
        {succeedDescription}
      </ConfirmationModal>
    </>
  );
};

AddToWishlist.propTypes = {
  placeholder: PropTypes.node,
  isEmpty: PropTypes.bool.isRequired,
};

const mapStateToProps = ({
  wishList,
}) => ({
  wishList,
});

const mapDispatchToProps = {
  addBasketToWishList,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddToWishlist);
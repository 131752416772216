import styles from './Registration.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import formStyles from 'components/objects/forms/SanaForm.module.scss';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'components/primitives/grid';
import {
  SanaForm, EntityTemplateFields, FormGroup,
  CaptchaFormGroup, useCaptchaOnSubmit, extractEscapedTemplateFields,
} from 'components/objects/forms';
import { EmailAddressField, FileField, TextBoxField } from 'components/objects/forms/fields';
import { SimpleText, RichText, useSimpleTexts } from 'components/sanaText';
import { connect, useSelector } from 'react-redux';
import { registerBusiness } from 'behavior/user';
import { toasts } from 'behavior/toasts';
import { FormLockKeys } from 'behavior/pages';
import { useHandlerLockerInPreview } from 'components/objects/preview';
import TermsAndAgreements from './termsAndAgreements';

export const REGISTRATION_FORM_NAME = 'BusinessRegistration';

const Form = ({ templateFields, businessRegistrationResult, registerBusiness, fileUploadSettings, termsAndAgreementsFormikRef }) => {
 
  const [isSpinnerDisplayed, displaySpinner] = useState(false);

  const { onSubmit, captchaRef } = useCaptchaOnSubmit(formData => {
   
    displaySpinner(true);
    registerBusiness(createRegistrationData(formData, templateFields), formData.files);
  }, FormLockKeys.B2BRegistration, [templateFields]);

  const { name, email } = useSelector(state => state.user);

  const [newCustomer, setNewCustomer] = useState(true);

  useEffect(() => {

    if (businessRegistrationResult == null)
      return;
    if (businessRegistrationResult.isRegistered) {
      toasts.success(<RichText textKey="B2BRegistrationForm_SuccessfullyRegistered" disableInsiteEditor />);
      return;
    }
    if (businessRegistrationResult.failureText) {
      toasts.error(businessRegistrationResult.failureText);
      captchaRef.current.reset();
    }

    displaySpinner(false);
  }, [businessRegistrationResult]);

  const submitButtonTextKey = "Submit";

  const anyCustomerDataFields = !!(templateFields && templateFields.length);
  const [
    emailTitle,
    customerNumberTitle,
    shopAccountNameTitle,
    fileAttachmentsTitle,
    companyNameTitle,
  ] = useSimpleTexts([
    'EmailAddress',
    'BusinessRegistration_CustomerNo',
    'BusinessRegistration_ShopAccountName',
    'ReturnRequest_FileAttachments',
    'BusinessRegistration_CompanyName',
  ]).texts;
  return (
    <SanaForm
      onSubmit={useHandlerLockerInPreview(onSubmit)}
      name={REGISTRATION_FORM_NAME}
      className={styles.form}
    >
      <Row>
        {anyCustomerDataFields && (
          <Col xs={12} md={6}>
            <fieldset className={`${formStyles.fieldset} ${styles.customerDataSection}`}>
              <div style={{ fontWeight: "bold", marginBottom: "16px" }}>
                <RichText textKey="CreateAccount_IsAlreadyRegisteredCompany_Description" />
              </div>

              <div className="btn-group" data-toggle="buttons">
                <label className="btn btn-secondary active" style={{ paddingRight: "10px" }}>
                  <input
                    type="radio"
                    name="options"
                    value='No'
                    defaultChecked
                    onChange={() => setNewCustomer(true)} /> <SimpleText textKey="No" />
                </label>

                <label className="btn btn-secondary">
                  <input
                    type="radio"
                    name="options"
                    value='Yes'
                    onChange={() => setNewCustomer(false)} /> <SimpleText textKey="Yes" />
                </label>
              </div>

              <div style={{ fontWeight: "bold" }}>
                <h3><RichText textKey="CreateAccount_PersonalDataHeader_B2B" /></h3>
              </div>
              <div className={styles.formContent}>
              <FormGroup
                fieldName="shopAccountName"
                fieldTitle={shopAccountNameTitle}
                fieldComponent={TextBoxField}
                required
                validation={{
                  required: true,
                }}
              />
              <FormGroup
                fieldName="companyName"
                fieldTitle={companyNameTitle}
                fieldComponent={TextBoxField}
                required
                validation={{
                    required: true,
                }}
              />
              </div>
              <div className={styles.formContent}>
                <EntityTemplateFields applyNamesEscaping fields={templateFields} />
              </div>
            </fieldset>
          </Col>
        )}
        <Col
          xs={12}
          md={anyCustomerDataFields ? 6 : 8}
          lg={anyCustomerDataFields ? null : 7}
        >
             <div className={styles.formContent}>
          <fieldset className={formStyles.fieldset}>
            {newCustomer && <div className="outer-div">
              <div style={{ fontWeight: "bold" }}>
                <h3><RichText textKey="BusinessRegistration_DocumentsHeader" /></h3>
              </div>
              <div className={styles.loginSectionDescription}>
                <RichText textKey="BusinessRegistration_DocumentsDescriptionLabel" />
              </div>
              <FormGroup
                fieldName="files"
                fieldTitle={fileAttachmentsTitle}
                fieldComponent={FileField}
                required
                validation={{
                  required: true,
                }}
                multiple={true}
              />
            </div>}
            <div style={{ fontWeight: "bold" }}>
              <h3><RichText textKey="CreateAccount_LoginDataHeader" /></h3>
            </div>
            {/*<div className={styles.loginSectionDescription}>*/}
            {/*  <RichText textKey="CreateAccount_LoginDataDescriptionLabel" />*/}
            {/*</div>*/}
            {!newCustomer && <div className="outer-div">
              <FormGroup
                fieldName="existingCustomerNo"
                fieldTitle={customerNumberTitle}
                fieldComponent={TextBoxField}
                maxLength={80}
                required
                validation={{
                  required: true,
                }}
              />
            </div>}
            <div className={styles.formContent}>
              <FormGroup
                fieldName="email"
                fieldTitle={emailTitle}
                fieldComponent={EmailAddressField}
                maxLength={80}
                required
                validation={{
                  required: true,
                  uniqueEmail: true,
                }}
              />
            </div>
          </fieldset>
          </div>
          <div className={styles.formContent , styles.floatRightBlock} >
            <CaptchaFormGroup ref={captchaRef} />
          </div>
          <div className="SanaForm_form-row">
            <div className="SanaForm_control form-row-control">
              <div className="SanaForm_label form-row-label">

              </div>
              <div className={`${styles.formContent} tc_condition-block`}>
            <div>
              <TermsAndAgreements
                submitButtonTextKey={submitButtonTextKey}
                formikRef={termsAndAgreementsFormikRef}
                onSubmit={onSubmit}
              />
            </div>
          </div>
            </div>
          </div>
  
          <div className={`${styles.formContent} float_right-btn`}>
            <SanaForm.SubmitRow textKey="Submit" btnClass={isSpinnerDisplayed ? btnStyles.loading : '' } />
          </div>
        </Col>
      </Row>
    </SanaForm >
  );
};

Form.propTypes = {
  templateFields: PropTypes.array,
  businessRegistrationResult: PropTypes.object,
  registerBusiness: PropTypes.func.isRequired,
};

export default connect(undefined, { registerBusiness })(Form);

function createRegistrationData(formData, templateFields) {

  let newfileNames = null;
  if (formData.files != null && formData.files.length > 0) {
    newfileNames = Array.from(formData.files).map(file => file.name);
  }

  const { email, captchaToken, existingCustomerNo, shopAccountName, companyName, fileNames, ...customerData } = formData;
  return { email, captchaToken, existingCustomerNo, shopAccountName, companyName, fileNames: newfileNames, customerData: extractEscapedTemplateFields(customerData, templateFields) };
}
exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Footer_top-row{padding-top:1.53em;padding-bottom:1.95em}.Footer_bottom-row{padding-top:.76em;padding-bottom:.76em;text-align:center}.Footer_sitemap-col{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:flex-start;align-items:flex-start}.Footer_sitemap-col>div{width:100%;max-width:100%}.Footer_hide{display:none}.Footer_title{text-transform:var(--headingText_H2_TextTransform,uppercase);margin:0;padding:0}@media (min-width:var(--breakpoints_Small,600)px),print{.Footer_top-row{padding-top:2.9em;padding-bottom:1.9em}.Footer_bottom-row{padding-top:1.5em;padding-bottom:2.9em;text-align:left}.Footer_sitemap-col>div{width:auto}}.Footer_sitemap-title{\n  /*!*/}", ""]);

// exports
exports.locals = {
	"top-row": "Footer_top-row",
	"topRow": "Footer_top-row",
	"bottom-row": "Footer_bottom-row",
	"bottomRow": "Footer_bottom-row",
	"sitemap-col": "Footer_sitemap-col",
	"sitemapCol": "Footer_sitemap-col",
	"hide": "Footer_hide",
	"title": "Footer_title",
	"sitemap-title": "Footer_sitemap-title",
	"sitemapTitle": "Footer_sitemap-title"
};
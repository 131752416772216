import { SETTINGS_LOADED, SETTINGS_UPDATING } from './actions';

const initialState = {
  loaded: false,
  updating: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SETTINGS_LOADED:
      if (!action.payload)
        return { ...state, error: true, updating: false };

      return mergeSettings({ ...state, error: false, loaded: true, updating: false }, action.payload);
    case SETTINGS_UPDATING:
      return { ...state, updating: true };
    default:
      return state;
  }
}

function mergeSettings(oldValue, newValue) {
  if (newValue == null || typeof newValue !== 'object')
    return newValue;

  if (Array.isArray(newValue))
    return newValue;

  for (const [key, value] of Object.entries(newValue))
    oldValue[key] = mergeSettings({ ...oldValue[key] }, value);

  return oldValue;
}

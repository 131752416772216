import PropTypes from 'prop-types';
import Form from './Form';
import { RichText } from 'components/sanaText';

const Page = ({ templateFields, businessRegistrationResult }) => (
  <>
    <div>
      <RichText textKey="CreateAccount_MainIntroductionLabel_B2B" />
    </div>
    <Form templateFields={templateFields} businessRegistrationResult={businessRegistrationResult} />
  </>
);

Page.propTypes = {
  templateFields: PropTypes.array.isRequired,
  businessRegistrationResult: PropTypes.object,
};

Page.selectPropsFromPage = ({ templateFields, businessRegistrationResult }) => ({ templateFields, businessRegistrationResult });

export default Page;
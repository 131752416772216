import type { Epic } from 'behavior/types';
import type { Api } from 'utils/api';
import type { StateObservable } from 'redux-observable';
import type { Action } from 'redux';
import type { AppState } from 'behavior';
import { of, merge, Subject, EMPTY } from 'rxjs';
import { mergeMap, map, tap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { loadTextsQuery } from './queries';
import { arrayToObject } from 'utils/helpers';
import { ADMINTEXTS_REQUESTED, textsLoaded, AdminTextsAction } from './actions';
import { retryWithToast } from 'behavior/errorHandling';
import { bufferBatchForLoading } from 'utils/rxjs';

type AdminTextsEpic = Epic<AdminTextsAction> & {
  pushText?: (key: string, text: string | null) => void;
}

type ApiResponse = {
  adminTexts: {
    key: string;
    value: string | null;
  }[];
}

const epic: AdminTextsEpic = function textsEpic(action$, _state$, { api, logger, completePendingActions$ }) {
  const usedKeys = new Set<string>();

  const getTexts = (keys: string[]) => {
    if (!keys.length)
      return EMPTY;

    return api.graphApi<ApiResponse>(loadTextsQuery, { keys }).pipe(
      map(data => textsLoaded(arrayToObject(data.adminTexts, s => s.key, s => s.value))),
      retryWithToast(action$, logger),
    );
  };

  const pushText$ = new Subject<Action>();
  epic.pushText = (key, text) => pushText$.next(textsLoaded({ [key]: text }));

  const load$ = action$.pipe(
    ofType(ADMINTEXTS_REQUESTED),
    bufferBatchForLoading(completePendingActions$),
    mergeMap(actions => {
      const keys = [];

      for (const action of actions)
        for (const key of action.payload) {
          if (usedKeys.has(key))
            continue;

          keys.push(key);
          usedKeys.add(key);
        }

      return getTexts(keys);
    }),
  );

  return merge(pushText$, load$);
};

export default epic;

export function requestAdminText(key: string, state$: StateObservable<AppState>, { api }: { api: Api }) {
  const existingText = state$.value.adminTexts[key];
  if (existingText)
    return of(existingText);

  return api.graphApi<ApiResponse>(loadTextsQuery, { keys: [key] }).pipe(
    map(data => data.adminTexts[0].value),
    tap(text => epic.pushText!(key, text)),
  );
}

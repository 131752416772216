import styles from './BarCodeScanner.module.scss';
import PropTypes from 'prop-types';
import { Button } from 'components/primitives/buttons';
import { useSanaTexts } from 'components/sanaText';
import { ToggleButtonStyle } from './constants';
import BarCodeScannerIcon from './BarCodeScannerIcon';
import btnStyles from 'components/primitives/buttons/Button.module.scss';

const ToggleButton = ({ style, icon, hoverIcon, onClick }) => {
  const [barCodeScannerText] = useSanaTexts(['ButtonText_BarCodeScanner']).texts;
  const additionalCSSVars = {
    '--barCodeScannerIconDisplay': icon ? '""' : null,
    '--barCodeScannerIcon': icon ? `url("${icon}")` : null,
    '--barCodeScannerIconHover': hoverIcon ? `url("${hoverIcon}")` : (icon ? `url("${icon}")` : null),
  };

  if (style === ToggleButtonStyle.ShowAsIconButton)
    return (
      <Button className={`${btnStyles.noIcon} ${btnStyles.btn} ${btnStyles.btnExtraSmall} ${styles.button}`} aria-label={`${barCodeScannerText}`} type="button" onClick={onClick}>
        <BarCodeScannerIcon className={styles.icon} icon={icon} style={additionalCSSVars} />
      </Button>
    );

  return (
    <button type="button" className={styles.link} aria-label={barCodeScannerText} onClick={onClick}>
      <BarCodeScannerIcon className={styles.icon} icon={icon} style={additionalCSSVars} />
      {style === ToggleButtonStyle.ShowAsIconWithText && <span>{barCodeScannerText}</span>}
    </button>
  );
};

ToggleButton.propTypes = {
  style: PropTypes.number,
  icon: PropTypes.string,
  hoverIcon: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default ToggleButton;

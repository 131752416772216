import styles from './SalesAgreements.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SanaButton } from 'components/primitives/buttons';
import { useModal } from 'components/objects/modals';
import ConfirmationModal from 'components/objects/profile/salesAgreements/ConfirmationModal';
import { applyAgreement, cancelAgreement } from 'behavior/salesAgreements';
import { Placeholder } from 'components/primitives/placeholders';
import { joinClasses } from 'utils/helpers';
import { useIsPreview } from 'components/objects/preview';

const ApplyCancelButton = ({ agreementId, selectedAgreementInfo, dispatch }) => {
  const { opened, show, hide } = useModal();
  const isPreview = useIsPreview();
  const placeholder = <Placeholder className={styles.buttonPlaceholder} />;

  if (!selectedAgreementInfo?.loaded && !isPreview)
    return placeholder;

  const { id, isAppliedToLines } = selectedAgreementInfo || {};
  const isApplied = id === agreementId;

  const action = () => {
    isApplied ? dispatch(cancelAgreement()) : dispatch(applyAgreement(agreementId));

    if (opened)
      hide();
  };

  return (
    <>
      <SanaButton
        textKey={isApplied ? 'Cancel' : 'Apply'}
        className={joinClasses(
          btnStyles.btnSmall,
          !isApplied && btnStyles.btnAction,
          btnStyles.noIcon,
        )}
        onClick={isAppliedToLines ? show : action}
        placeholder={placeholder}
      />
      <ConfirmationModal opened={opened} hide={hide} onConfirm={action} />
    </>
  );
};

ApplyCancelButton.propTypes = {
  selectedAgreementInfo: PropTypes.shape({
    id: PropTypes.string,
    isAppliedToLines: PropTypes.bool,
    loaded: PropTypes.bool,
  }),
  dispatch: PropTypes.func,
};

export default connect(({ basket: { salesAgreementInfo } }) => ({
  selectedAgreementInfo: salesAgreementInfo,
}))(ApplyCancelButton);
import PropTypes from 'prop-types';
import styles from '../../../forms/SanaForm.module.scss';
import { useState, useCallback, useMemo } from 'react';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import { SanaForm, FormGroup } from 'components/objects/forms';
import { useSimpleTexts } from 'components/sanaText';
import { Container, Row, Col } from 'components/primitives/grid';
import { TextBoxField, DatePickerField, DropdownField } from 'components/objects/forms/fields';
import { SanaButton } from 'components/primitives/buttons';

const Filter = ({ applyFilter, filter }) => {
  const minDate = new Date(2001, 0, 1);
  const maxDate = new Date(2100, 11, 31);

  const dateFieldProps = {
    fieldComponent: DatePickerField,
    minDate,
    maxDate
  };
  const onSubmit = useCallback(values => applyFilter({ filter: values }), []);

  const [
    itemNoTitle,
    shopAccountNameTitle,
    transactionTypeTitle,
    fromDateTitle,
    toDateTitle,
  ] = useSimpleTexts([
    'StockTransactions_Filter_ItemNo',
    'StockTransactions_Filter_ShopAccountName',
    'StockTransactions_Filter_TransactionType',
    'StockTransactions_Filter_FromDate',
    'StockTransactions_Filter_ToDate',
  ]).texts;

   return (
    <>
      <SanaForm
        name="Filter"
        initialValues={filter}
        onSubmit={onSubmit}
      >
          <Row>
             <Col xs={12} md={7} xl={7}>
                  <FormGroup
                    fieldName="itemNo"
                    fieldTitle={itemNoTitle}
                    fieldComponent={TextBoxField}
                    maxLength={50}
                  />

                  <FormGroup
                    fieldName="shopAccountName"
                    fieldTitle={shopAccountNameTitle}
                    fieldComponent={TextBoxField}
                    maxLength={50}
                  />

                  <FormGroup
                    fieldName="transactionType"
                    fieldTitle={transactionTypeTitle}
                    fieldComponent={DropdownField}
                    items={getFilterSeletion()}
                  />
             </Col>
             <Col xs={12} md={5} xl={5}>
               <FormGroup
                 fieldName="fromDate"
                 fieldTitle={fromDateTitle}
                 {...dateFieldProps}
               />
               <FormGroup
                 fieldName="toDate"
                 fieldTitle={toDateTitle}
                 {...dateFieldProps}
             />
             <div className={`${styles.control} form-row-control`}>
               <div className={`${styles.label} form-row-label`}>
                 <label>&nbsp;</label>
               </div>
               <div className={`${styles.field} form-row-field`}>
                 <SanaButton type="submit" textKey="StockTransactions_SearchButton" />
               </div>
               </div>
             </Col>
          </Row>
      </SanaForm>
    </>
  );
};

Filter.propTypes = {
  applyFilter: PropTypes.func,
  filter: PropTypes.object,
};

export default Filter;

function getFilterSeletion() {
  const texts = useSanaTexts([
    'StockTransactions_Filter_TransactionType_All',
    'StockTransactions_Filter_TransactionType_Reductions',
    'StockTransactions_Filter_TransactionType_Additions',
  ]).texts;
  return useMemo(() => {
    const [all, reductions, additions] = texts;
    return [
      { value: 'All', name: all },
      { value: 'Reductions', name: reductions },
      { value: 'Additions', name: additions },
    ];
  }, [texts]);
}
exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".StyleWrapper_wrapper{max-width:100%}.vd-drag-helper-block .StyleWrapper_wrapper{width:100%;height:100%}.StyleWrapper_overflow{overflow:hidden}.StyleWrapper_stretch-height{height:100%}.StyleWrapper_stretch-width{width:100%}.StyleWrapper_flex-stretch-height,.StyleWrapper_flex-stretch-width{-webkit-flex-grow:1;flex-grow:1}@media not all and (min-resolution:.001dpcm){.StyleWrapper_flex-stretch-height{height:100%}}@media (-ms-high-contrast:active),(-ms-high-contrast:none){.StyleWrapper_wrapper{min-width:1px;min-height:1px}}", ""]);

// exports
exports.locals = {
	"wrapper": "StyleWrapper_wrapper",
	"overflow": "StyleWrapper_overflow",
	"stretch-height": "StyleWrapper_stretch-height",
	"stretchHeight": "StyleWrapper_stretch-height",
	"stretch-width": "StyleWrapper_stretch-width",
	"stretchWidth": "StyleWrapper_stretch-width",
	"flex-stretch-height": "StyleWrapper_flex-stretch-height",
	"flexStretchHeight": "StyleWrapper_flex-stretch-height",
	"flex-stretch-width": "StyleWrapper_flex-stretch-width",
	"flexStretchWidth": "StyleWrapper_flex-stretch-width"
};
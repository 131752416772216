import { useState, useEffect } from 'react';
import { anyEqual, intersect } from 'utils/helpers';

const useVariantDropdownsBlock = (
  variantComponentGroups,
  updateVariantId,
  productId,  
  defaultVariantId,
  updateBarcode,
) => {
  const [selection, setSelection] = useState();

  const setNewSelection = newSelection => {
    const [selectedVariantId, validSelection, selectedBarcode] = getSelection(variantComponentGroups, newSelection, defaultVariantId);
    setSelection(validSelection);
    updateVariantId(selectedVariantId, validSelection);
    updateBarcode && updateBarcode(selectedBarcode);
  };

  useEffect(setNewSelection, [productId, variantComponentGroups == null, defaultVariantId]);

  const filteredVariantComponentGroups = !selection ? [] : function () {
    let availableVariants = null;

    return variantComponentGroups.map(group => {
      let { id, components } = group;
      const selectedComponent = selection[id];

      // Filtering components to display for selection based on available variants from parent group.
      if (!availableVariants) {
        availableVariants = selectedComponent.variants;
      } else {
        components = components.filter(availableComponentFilter.bind(null, availableVariants));
        availableVariants = intersect(selectedComponent.variants, availableVariants);
      }

      return { ...group, components };
    });
  }();

  return [
    filteredVariantComponentGroups,
    selection || {},
    setNewSelection,
  ];
};

export default useVariantDropdownsBlock;

function availableComponentFilter(availableVariants, component) {
  return anyEqual(component.variants, availableVariants);
}

function getSelection(variantComponentGroups, selection, defaultVariantId) {
  const newSelection = {};
  let availableVariants;
  let availableBarcodes;

  for (const group of variantComponentGroups) {
    let selectedComponent = null;

    if (selection) {
      selectedComponent = selection[group.id] || group.components[0];
    } else {
      const querySelectedComponent = defaultVariantId && group.components.find(c => anyEqual(c.variants, [defaultVariantId]));
      selectedComponent = querySelectedComponent || group.components[0];
    }

    if (!availableVariants) {
      availableVariants = selectedComponent.variants;
      availableBarcodes = selectedComponent.barcodes;
    } else {
      if (!anyEqual(selectedComponent.variants, availableVariants)) {
        selectedComponent = group.components.find(availableComponentFilter.bind(null, availableVariants));
      }

      availableVariants = intersect(selectedComponent.variants, availableVariants);
      availableBarcodes = intersect(selectedComponent.barcodes, availableBarcodes);
    }

    newSelection[group.id] = selectedComponent;
  }
  return [
    availableVariants[0],
    newSelection,
    availableBarcodes && availableBarcodes.length && availableBarcodes[0],
  ];
}

import styles from './Lines.module.scss';
import { useState, useCallback, useContext, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { joinClasses, iEquals } from 'utils/helpers';
import scrollIntoView from 'scroll-into-view';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import ProductInfo from './ProductInfo';
import DefaultTemplate from './templates/Default';
import MobileTemplate from './templates/Mobile';
import { ShowHideButton } from 'components/primitives/buttons';
import ActionLinks from './ActionLinks';
import PriceNode from '../PriceNode';
import Discount from './Discount';
import ProductLine, { productLinePropTypes } from './ProductLine';
import ReadonlyQuantityBox from './ReadonlyQuantityBox';
import { routesBuilder } from 'routes';
import { useUpdateContext } from '../updateContext';
import BasketLinesContext from './BasketLinesContext';
import { ProductTrackingContext } from 'components/objects/analytics';
import { EVENT_SOURCES } from 'behavior/analytics';

const GroupedProductLine = ({ line, productImage, product, modifiedDate, isSupplementary }) => {
  const {
    showImages,
    showPrice,
    showUom,
    currencyInfo,
    isMobile,
    lastModifiedLineId,
  } = useContext(BasketLinesContext);

  const [expanded, setExpanded] = useState(lastModifiedLineId === line.id);
  const [animated, setAnimated] = useState(false);
  const { quantities, deleteLines } = useUpdateContext();
  const timeoutRef = useRef();

  const { texts: [toggleBtnLabel] } = useSanaTexts(['Aria_Basket_ToggleVariants'], makeSimpleText);

  const { id, url, title: productTitle } = product;
  const { subTotal, discount, subLines, extendedTexts } = line;

  const route = routesBuilder.forProduct.bind(null, id);

  const setAnimatedState = useCallback(() => {
    setAnimated(true);
    timeoutRef.current = setTimeout(() => setAnimated(false), +styles.animationTime);
  }, []);
  const handleDelete = () => deleteLines(subLines.map(({ id }) => id));

  const toggleExpanded = () => {
    if (animated)
      return;

    if (expanded && setFocusToInvalidInput(subLines, quantities))
      return;

    setExpanded(!expanded);
    setAnimatedState();
  };

  const variantLineIds = useMemo(() => {
    const idsArray = [];

    for (const subLine of subLines) {
      const { id, serviceLines } = subLine;
      idsArray.push(`product_${id}`);

      if (serviceLines && serviceLines.length) {
        for (const serviceLineIndex in serviceLines) {
          idsArray.push(`product_${id}_${serviceLineIndex}`);
        }
      }
    }

    return idsArray.join(' ');
  }, [subLines]);

  const productActionBlock = (
    <ShowHideButton
      className={styles.btnShowHide}
      show={expanded}
      onClick={toggleExpanded}
      aria-label={toggleBtnLabel}
      aria-expanded={expanded}
      aria-controls={variantLineIds}
    />
  );

  const productInfo = (
    <ProductInfo
      productUrl={url}
      route={route}
      productTitle={productTitle}
      productId={id}
      variantTitle={line.title}
      extendedTexts={extendedTexts}
    />
  );

  const actionLinks = !isSupplementary && <ActionLinks productUrl={url} route={route} onDelete={handleDelete} />;

  const subTotalNode = showPrice && <PriceNode price={subTotal} currencyInfo={currencyInfo} />;
  const quantityBox = <ReadonlyQuantityBox quantity={subLines.length} />;
  const productDiscount = !!discount && <Discount discount={discount} currencyInfo={currencyInfo} />;

  const LineTemplate = isMobile ? MobileTemplate : DefaultTemplate;
  const lineStateClassName = expanded ? styles.expanded : styles.collapsed;

  useEffect(() => {
    setFocusToInvalidInput(subLines, quantities, () => setExpanded(true));
  }, []);

  useEffect(() => {
    if (!expanded || lastModifiedLineId === line.id)
      return;

    setExpanded(false);
    setAnimatedState();
  }, [modifiedDate, lastModifiedLineId]);

  useEffect(() => () => clearTimeout(timeoutRef.current), []);

  return (
    <ProductTrackingContext.Provider value={{ product, trackingSource: EVENT_SOURCES.shoppingCartPage }}>
      <LineTemplate
        className={`${lineStateClassName} ${styles.groupedProductLine}`}
        productActionBlock={productActionBlock}
        productInfo={productInfo}
        quantityBox={quantityBox}
        actionLinks={actionLinks}
        priceNode={showPrice || null}
        productDiscount={productDiscount}
        subTotal={subTotalNode}
        uomLabel={showUom}
        isGroupedLine
      />
      {subLines && subLines.map((subLine, index) => {
        const image = product.images.find(i => iEquals(i.variantId, subLine.variationId)) || productImage;
        const isLastGroupLine = index === subLines.length - 1;

        return (
          <ProductLine
            elementId={`product_${subLine.id}`}
            className={joinClasses(
              lineStateClassName,
              showImages && styles.withImage,
              animated && styles.animated,
            )}
            key={subLine.id}
            product={product}
            line={subLine}
            imageUrl={image && image.small}
            isVariantLine
            isLastGroupLine={isLastGroupLine}
            isSupplementary={isSupplementary}
          />
        );
      })}
    </ProductTrackingContext.Provider>
  );
};

GroupedProductLine.propTypes = {
  ...productLinePropTypes,
  productImage: PropTypes.object,
  modifiedDate: PropTypes.number,
};

export default connect(({ basket: { modifiedDate } }) => ({ modifiedDate }))(GroupedProductLine);

function setFocusToInvalidInput(subLines, quantities, callback) {
  const invalidSubLinesIds = subLines
    .filter(({ id }) => {
      const lineQuantityData = quantities.get(id);
      return typeof lineQuantityData !== 'undefined' && !lineQuantityData.isValid;
    })
    .map(({ id }) => id);

  if (invalidSubLinesIds.length) {
    const input = document.getElementById('qty' + invalidSubLinesIds[0]);
    if (input) {
      callback && callback();
      scrollIntoView(input, { time: 150 }, () => input.focus && input.focus());
      return true;
    }
    return false;
  }
}

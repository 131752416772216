import styles from './Login.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import PropTypes from 'prop-types';
import { Col, Row } from 'components/primitives/grid';
import { connect } from 'react-redux';
import { SanaLinkButton } from 'components/primitives/links';
import { RichText, SimpleText } from 'components/sanaText';
import { PromotionalText } from 'components/objects/promotionalTexts';
import LoginForm from './LoginForm';
import { routesBuilder } from 'routes';
import GuestCheckoutBlock from './GuestCheckoutBlock';

const Login = ({ registrationAllowed, b2BRegistrationAllowed }) => (
  <>
    <Row className={styles.content}>
      <Col
        xs={12}
        sm={registrationAllowed ? 6 : 12}
        md={registrationAllowed ? null : 8}
        lg={registrationAllowed ? null : 7}
        className={styles.customerBlock}
      >
        {registrationAllowed
          ? (
            <>
              <h3 className={styles.title}><SimpleText textKey="Login_AlreadyCustomerHeader" /></h3>
              <div className={`${styles.block} ${styles.alreadyCustomerIntro}`}>
                <RichText textKey="Login_AlreadyCustomerIntro" />
              </div>
            </>
          )
          : (
            <div className={`${styles.block} ${styles.intro}`}>
              <RichText textKey="Login_IntroductionText" />
            </div>
          )
        }
        <LoginForm />
      </Col>
   
      <Col xs={12} sm={6} className={styles.notCustomerBlock}>
        {registrationAllowed && (
          <Col>
            <div className={styles.block}>
              <h3 className={styles.title}><SimpleText textKey="Login_NotaCustomerYetHeader" /></h3>
              <div className={styles.block}><RichText textKey="Login_NotaCustomerYetIntro" /></div>
              <div>
                <SanaLinkButton to={routesBuilder.forRegistration} textKey="Login_CreateAccountButton" className={styles.newAccount} />
              </div>
            </div>

          </Col>
        )}
        {/*[Autolack] 4.1.	Register for B2B account*/}
        {b2BRegistrationAllowed && (
          <Col>
            <div className={styles.block}>
              <h3 className={styles.title}><SimpleText textKey="Login_NotaCustomerYetHeader_B2B" /></h3>
              <div className={styles.block}><RichText textKey="Login_NotaCustomerYetIntro_B2B" /></div>
              <div>
                <SanaLinkButton to={routesBuilder.forBusinessRegistration} textKey="Login_CreateAccountButton_B2B" className={`${styles.newAccount} ${btnStyles.btnAction}`} />
              </div>
            </div>
            <GuestCheckoutBlock />
          </Col>
        )}
      </Col>
    </Row>
    {!registrationAllowed && (
      <Row>
        <Col>
          <div className={styles.textFooter}><PromotionalText textKey="Login_Footer" /></div>
        </Col>
      </Row>
    )}
  </>
);

Login.propTypes = {
  registrationAllowed: PropTypes.bool,
  b2BRegistrationAllowed: PropTypes.bool,//[Autolack] 4.1.	Register for B2B account
};

export default connect(
  ({ settings: { profile } }) => ({ registrationAllowed: profile && profile.registrationAllowed, b2BRegistrationAllowed: profile.b2BRegistrationAllowed }),
)(Login);
import styles from '../WishList.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'components/sanaText';

const LineTemplate = ({
  productInfo,
  productActionBlock,
  actionLinks,
  priceNode,
  uomBlock,
  addToBasketBlock,
  isGroupedLine,
  className = null,
  ...attributes
}) => (
  <tbody className={className} {...attributes}>
    <tr className={styles.productBlock}>
      <td className={styles.productActionCell} aria-hidden>
        {productActionBlock}
      </td>
      <td className={styles.productInfoCell} colSpan="3" headers="wishListProduct" aria-describedby="wishListProduct">
        {productInfo}
      </td>
    </tr>
    <tr className={styles.priceBlock}>
      <td className={styles.uomCell} colSpan="3" headers="wishListUom" aria-describedby="wishListUom">
        {uomBlock}
      </td>
      {priceNode && (
        <td className={styles.costCell} headers="wishListPrice" aria-describedby="wishListPrice">
          {priceNode}
        </td>
      )}
    </tr>
    <tr className={styles.rowTotal}>
      <td colSpan={'3'} headers="wishListProduct" aria-describedby="wishListProduct">
        {actionLinks}
      </td>
      <td>
        {addToBasketBlock}
      </td>
      <td> {isSpecialShipping && (<div> <RichText textKey="SpecialShippingMessage" /> </div>)}</td>
    </tr>
  </tbody>
);

LineTemplate.propTypes = {
  productInfo: PropTypes.node.isRequired,
  productActionBlock: PropTypes.node,
  actionLinks: PropTypes.node,
  priceNode: PropTypes.node,
  uom: PropTypes.node,
  className: PropTypes.string,
  addToBasketBlock: PropTypes.node,
  isGroupedLine: PropTypes.bool,
  isSpecialShipping: PropTypes.bool,
};

export default memo(LineTemplate);

import styles from '../Lines.module.scss';
import { useUpdateContext } from '../../updateContext';
import LineTemplatePropTypes from './LineTemplatePropTypes';
import { RichText } from 'components/sanaText';
import { InfoAlert } from 'components/primitives/alerts';
import { useCurrencyInfo } from 'utils/hooks';
import { getCurrency } from 'utils/format';
import { FormattedPrice } from 'components/primitives/price';
import { useHasAbilities } from 'components/objects/user'; // [209289] TE: Remove discount info
import { AbilityTo } from 'behavior/user/constants'; // [209289] TE: Remove discount info

const LineTemplate = ({
  productInfo,
  agreementTerm,
  productActionBlock,
  actionLinks,
  quantityBox,
  uomLabel,
  priceNode,
  productDiscount,
  subTotal,
  className = null,
  isGroupedLine,
  id,
  isSpecialShipping,  //[114886] 3.12. Warning on products that require additional shipping
  isVariantSpecialShipping,
  surchargeValue, //133701 - [RFC Surcharges] 3.1. Display surcharge notices on the web shop
  surchargeType   //133701 - [RFC Surcharges] 3.1. Display surcharge notices on the web shop
}) => {
  const { isB2BPreset } = useUpdateContext();
  let percentageSurchargeType = surchargeType === "Percentage";

  const currencyInfo = useCurrencyInfo();
  const currencySymbol = getCurrency(currencyInfo.id, currencyInfo.cultureName);
  const discountAbility = useHasAbilities(AbilityTo.DiscountAbility)[0]; // [209289] TE: Remove discount info

  return (
    <>
      <tr className={className} id={id}>
        {(isB2BPreset || productActionBlock) && (
          <td className={styles.colProductAction} aria-hidden={!isGroupedLine}>
            {productActionBlock}
          </td>
        )}
        <td className={styles.colProductInfo} aria-describedby="basketProduct">
          {productInfo}
          {agreementTerm}
          {actionLinks}
        </td>
        <td className={styles.colPrice} aria-describedby="basketPrice" aria-hidden={!priceNode}>
          {priceNode}
        </td>
        <td className={styles.colQuantity} aria-describedby="basketQuantity">
          {quantityBox}
        </td>
        {uomLabel &&
          <td className={styles.colUom} aria-describedby="basketUom">
            {uomLabel}
          </td>
        }
        <td className={styles.colTotal} aria-describedby="basketTotal" aria-hidden={!subTotal}>
          {subTotal}
          {productDiscount && discountAbility && ( // [209289] TE: Remove discount info
            <>
              <br />
              {productDiscount}
            </>
          )}
          <div className="mtz-notice">
            {surchargeValue !== undefined && surchargeValue !== null && surchargeValue !== 0 && percentageSurchargeType && (
              <div className="mtz-notice-cart">
                <RichText
                  textKey={'SurchargeInformation_Percentage_Basket'}
                  formatWith={[surchargeValue]}
                />
              </div>
            )}
            {surchargeValue !== undefined && surchargeValue !== null && surchargeValue !== 0 && !percentageSurchargeType && (
              <div className="mtz-notice-cart">
                <RichText
                  textKey={'SurchargeInformation_ActualValue_Basket'}
                  formatWith={[<FormattedPrice price={surchargeValue} currencyInfo={currencyInfo} />]}
                />
              </div>
            )}
          </div>
        </td>
      </tr>
      <tr className="info_alert-wrapper">
        <td colSpan="6"> {(isSpecialShipping || isVariantSpecialShipping) && (<InfoAlert role="alert"><RichText textKey="SpecialShippingMessage" /> </InfoAlert>)}</td>
      </tr>
    </>
  );
};

LineTemplate.propTypes = LineTemplatePropTypes;

export default LineTemplate;
import { PageComponentNames } from '../componentNames';
import {
  VISUAL_DESIGNER_UPDATE_VIEW_PAGE,
  VISUAL_DESIGNER_UPDATE_TITLE,
  VISUAL_DESIGNER_PREVIEW_ADD_ROW,
  VISUAL_DESIGNER_PREVIEW_MOVE_ROW,
  VISUAL_DESIGNER_PREVIEW_DUPLICATE_ROW,
  VISUAL_DESIGNER_PREVIEW_DELETE_ROW,
  VISUAL_DESIGNER_PREVIEW_MOVE_CONTENT_ELEMENT,
} from './actions';
import { arrayMove, generateKey } from 'utils/helpers';
import { createReducer } from 'utils/redux';
import { mergePageContentData, modifyContentElementById, findContentElementById } from './helpers';

export const emptyRowModel = {
  rowAnimation: 'NONE',
  verticalAlignment: 'TOP',
  columns: [],
  name: 'Row',
  background: {},
  border: { style: 'NONE' },
  spacing: { margin: '0px 0px 30px 0px' },
  attributes: {},
  displayConfiguration: {},
  minHeight: {},
};

export default createReducer(null, {
  [VISUAL_DESIGNER_UPDATE_VIEW_PAGE]: onUpdateViewPage,
  [VISUAL_DESIGNER_UPDATE_TITLE]: onUpdateTitle,
  [VISUAL_DESIGNER_PREVIEW_ADD_ROW]: onVisualDesignerPreviewAddRow,
  [VISUAL_DESIGNER_PREVIEW_MOVE_ROW]: onVisualDesignerPreviewMoveRow,
  [VISUAL_DESIGNER_PREVIEW_DUPLICATE_ROW]: onVisualDesignerPreviewDuplicateRow,
  [VISUAL_DESIGNER_PREVIEW_DELETE_ROW]: onVisualDesignerPreviewDeleteRow,
  [VISUAL_DESIGNER_PREVIEW_MOVE_CONTENT_ELEMENT]: onVisualDesignerPreviewMoveContentElement,
});

function onUpdateViewPage(state, action) {
  const { page } = action.payload;
  page.content = mergePageContentData(state.content, page.content);

  return {
    ...state,
    ...page,
    revision: !state.revision ? 1 : state.revision + 1,
    component: PageComponentNames.Content,
  };
}

function onUpdateTitle(state, action) {
  const { defaultTitle, translationTitle } = action.payload;

  return {
    ...state,
    defaultTitle,
    translationTitle,
  };
}

function onVisualDesignerPreviewAddRow(state, action) {
  const { index, id } = action.payload;
  const newRow = { ...emptyRowModel, id: id || generateKey() };
  const content = [
    ...state.content.slice(0, index),
    newRow,
    ...state.content.slice(index),
  ];

  return {
    ...state,
    content,
  };
}

function onVisualDesignerPreviewMoveRow(state, action) {
  const {
    indexBefore,
    indexAfter,
  } = action.payload;

  const content = arrayMove(state.content, indexBefore, indexAfter);
  return {
    ...state,
    content,
  };
}

function onVisualDesignerPreviewDuplicateRow(state, action) {
  const { index, id } = action.payload;
  const newRow = { ...emptyRowModel, id: id || generateKey() };
  const content = [
    ...state.content.slice(0, index + 1),
    newRow,
    ...state.content.slice(index + 1),
  ];

  return {
    ...state,
    content,
  };
}

function onVisualDesignerPreviewDeleteRow(state, action) {
  const content = state.content.filter((_, i) => i !== action.payload.index);

  return {
    ...state,
    content,
  };
}

function onVisualDesignerPreviewMoveContentElement(state, action) {
  const {
    indexBefore,
    indexAfter,
    sourceElementId,
    targetElementId,
  } = action.payload;

  let newContent;

  const { row: sourceRow, column: sourceColumn } = findContentElementById(sourceElementId, state.content);

  if (!targetElementId || targetElementId === sourceElementId) {
    if (sourceColumn) {
      newContent = modifyContentElementById(sourceElementId, state.content, sourceElement => ({
        ...sourceElement,
        contentBlocks: arrayMove(sourceElement.contentBlocks, indexBefore, indexAfter),
      }));
    }
    else {
      newContent = modifyContentElementById(sourceElementId, state.content, sourceElement => ({
        ...sourceElement,
        columns: arrayMove(sourceElement.columns, indexBefore, indexAfter),
      }));
    }
  } else {
    const { row: targetRow, column: targetColumn } = findContentElementById(targetElementId, state.content);

    if (sourceColumn && targetColumn) {
      newContent = moveContentBlock(state.content, sourceRow, sourceColumn, indexBefore, targetRow, targetColumn, indexAfter);
    }
    else if (sourceRow && targetRow) {
      newContent = moveColumn(state.content, sourceRow, indexBefore, targetRow, indexAfter);
    }
  }

  return {
    ...state,
    content: newContent,
  };
}

function moveContentBlock(content, sourceRow, sourceColumn, indexBefore, targetRow, targetColumn, indexAfter) {
  const blockToMove = sourceColumn.contentBlocks[indexBefore];
  const newSourceColumn = {
    ...sourceColumn,
    contentBlocks: cloneAndDelete(sourceColumn.contentBlocks, indexBefore),
  };
  const newTargetColumn = {
    ...targetColumn,
    contentBlocks: cloneAndInsert(targetColumn.contentBlocks, indexAfter, blockToMove),
  };

  if (sourceRow === targetRow) {
    const newRow = {
      ...sourceRow,
      columns: sourceRow.columns.map(column => {
        if (column.id === newSourceColumn.id)
          return newSourceColumn;
        if (column.id === newTargetColumn.id)
          return newTargetColumn;
        return column;
      }),
    };

    return content.map(row => row.id === newRow.id ? newRow : row);
  }
  else {
    const newSourceRow = {
      ...sourceRow,
      columns: sourceRow.columns.map(column => column.id === newSourceColumn.id ? newSourceColumn : column),
    };
    const newTargetRow = {
      ...targetRow,
      columns: targetRow.columns.map(column => column.id === newTargetColumn.id ? newTargetColumn : column),
    };

    return content.map(row => {
      if (row.id === sourceRow.id)
        return newSourceRow;
      else if (row.id === targetRow.id)
        return newTargetRow;
      else
        return row;
    });
  }
}

function moveColumn(content, sourceRow, indexBefore, targetRow, indexAfter) {
  const columnToMove = sourceRow.columns[indexBefore];
  const newSourceRow = {
    ...sourceRow,
    columns: cloneAndDelete(sourceRow.columns, indexBefore),
  };
  const newTargetRow = {
    ...targetRow,
    columns: cloneAndInsert(targetRow.columns, indexAfter, columnToMove),
  };

  return content.map(row => {
    if (row.id === sourceRow.id)
      return newSourceRow;
    else if (row.id === targetRow.id)
      return newTargetRow;
    else
      return row;
  });
}

function cloneAndInsert(array, index, element) {
  const newArray = [...array];
  newArray.splice(index, 0, element);
  return newArray;
}

function cloneAndDelete(array, index) {
  const newArray = [...array];
  newArray.splice(index, 1);
  return newArray;
}
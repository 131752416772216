import { createReducer } from 'utils/redux';
import {
  PRODUCT_SELECTOR_SEARCH_SUGGESTIONS_RECEIVED,
  PRODUCT_SELECTOR_PRODUCT_RECEIVED,
  PRODUCT_SELECTOR_PRODUCT_CLEARED,
  PRODUCT_SELECTOR_SEARCH_BARCODE_REQUESTED,
  PRODUCT_SELECTOR_SEARCH_BARCODE_RECEIVED,
} from './actions';
import { UpdateType } from './constants';

export const initialState = {
  suggestions: [],
  selectedProduct: null,
  barcodeResult: null,
  status: null,
};

export default createReducer(initialState, {
  [PRODUCT_SELECTOR_SEARCH_SUGGESTIONS_RECEIVED]: onSearchSuggestionsReceived,
  [PRODUCT_SELECTOR_SEARCH_BARCODE_RECEIVED]: onSearchByBarcodeReceived,
  [PRODUCT_SELECTOR_PRODUCT_RECEIVED]: onProductReceived,
  [PRODUCT_SELECTOR_PRODUCT_CLEARED]: onProductCleared,
  [PRODUCT_SELECTOR_SEARCH_BARCODE_REQUESTED]: onSearchByBarcodeRequested, 
});

function onSearchSuggestionsReceived(state, action) {
  return { ...state, suggestions: action.payload.suggestions };
}

function onSearchByBarcodeRequested(state, action) {
  return { ...state, barcodeResult: null, status:null };
}

function onSearchByBarcodeReceived(state, action) {
  return { ...state, barcodeResult: action.payload.product, status:action.payload.product? UpdateType.Found :UpdateType.NotFound };
}

function onProductReceived(state, action) {
  return { ...state, selectedProduct: action.payload.product };
}

function onProductCleared(state) {
  return { ...state, selectedProduct: null, barcodeResult: null, status:null, suggestions:[] };
}
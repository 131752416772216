import type { SystemRouteData } from 'routes';
import { useMemo } from 'react';
import NavigationLink from './NavigationLink';
import { useRoutes } from 'components/primitives/route';

type Props = Omit<Parameters<typeof NavigationLink>[0], 'to' | 'url'> & {
  to: SystemRouteData;
}

const RouteLink = ({
  createLink,
  to,
  onClick,
  omitScroll,
  replaceHistory,
  options,
  disabled,
}: Props) => {
  const routes = useMemo(() => [to], [to]);
  const [url] = useRoutes(routes);

  if (!url)
    return createLink('#');

  return (
    <NavigationLink
      createLink={createLink}
      url={url}
      to={to}
      onClick={onClick}
      omitScroll={omitScroll}
      replaceHistory={replaceHistory}
      options={options}
      disabled={disabled}
    />
  );
};

export default RouteLink;

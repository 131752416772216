import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import StockReductionContext from './StockReductionContext';

const StockReductionProvider = ({ children }) => {
  const [state, setState] = useState(() => ({
    lines: new Map(),
    linesDependency: {},
  }));

  const lines = state.lines;

  const addLine = useCallback(line => {
      setState(({ lines }) => {
        const { id, title, quantity } = line;
        lines.set(id, { title , quantity: { isVAlid: true, value: quantity }, comment:'' });
  
        return { lines, linesDependency: {} };
      });
    },
    [],
  );

  const deleteLine = useCallback(id => {
    setState(({ lines }) => {
      lines.delete(id);

      return { lines, linesDependency: {} };
    });
  },
  [],
);

const reset = useCallback(() => {
    lines.clear();
  },
  [],
);

  const updateQuantity = useCallback((id, quantity) => {
    setState(({ lines }) => {
        const line = lines.get(id);
        if (line) {
          line.quantity = quantity;
        } else {
          lines.set(id, { quantity, comment: '' });
        }

      return { lines, linesDependency: {} };
    });
  }, []);

  const updateComment = useCallback((id, comment) => {
    setState(({ lines }) => {
        const line = lines.get(id);
        if (line) {
          line.comment = comment;
        } else {
        lines.set(id, { quantity: null, comment });
        }

      return { lines, linesDependency: {} };
    });
  }, []);

  const value = {
    lines,
    addLine,
    deleteLine,
    reset,
    updateQuantity,
    updateComment,
  };

  return (
    <StockReductionContext.Provider value={value}>
      {children}
    </StockReductionContext.Provider>
  );
};

StockReductionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default StockReductionProvider;

exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "a.ResetPassword_hyp-back{display:-webkit-inline-flex;display:inline-flex;-webkit-align-items:center;align-items:center;word-break:break-word}@supports (-ms-ime-align:auto){a.ResetPassword_hyp-back{overflow-wrap:break-word}}@media (-ms-high-contrast:active),(-ms-high-contrast:none){a.ResetPassword_hyp-back{word-wrap:break-word}}.ResetPassword_description{margin:0 0 1em}@media (min-width:var(--breakpoints_Small,600)px),print{.ResetPassword_form{max-width:46.75em}}@media (min-width:var(--breakpoints_Medium,960)px),print{.ResetPassword_form{max-width:none}}", ""]);

// exports
exports.locals = {
	"hyp-back": "ResetPassword_hyp-back",
	"hypBack": "ResetPassword_hyp-back",
	"description": "ResetPassword_description",
	"form": "ResetPassword_form"
};
import type { Toast } from './types';
import { NAVIGATED, completeNavigation } from 'behavior/routing';
import { SHOW_ERROR_TOAST, CLOSE_ERROR_TOAST, ErrorAction } from './actions';
import { APP_INIT_HYDRATE } from 'behavior/app';
import { initAppHydrate } from 'behavior/app';

export type ErrorsState = {
  toast: Toast | null;
  initial?: string | boolean;
};

const initialState: ErrorsState = {
  toast: null,
};

type Action = ErrorAction | ReturnType<
  | typeof initAppHydrate
  | typeof completeNavigation
>

export default function errorsReducer(state = initialState, action: Action): ErrorsState {
  if (action.type === SHOW_ERROR_TOAST)
    return { toast: action.payload };

  if (action.type === CLOSE_ERROR_TOAST)
    return initialState;
  else if (action.type === APP_INIT_HYDRATE && state.toast)
    return { toast: { type: 'reload', data: { timeId: getTimeId(state.toast) } } };
  else if (state.initial && action.type === NAVIGATED) {
    const newState = { ...state };
    delete newState.initial;
    return newState;
  }

  return state;
}

function getTimeId(toast: Toast) {
  if (toast.type === 'message')
    return;

  return toast.data?.timeId;
}
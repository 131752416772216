import styles from './BarCodeScanner.module.scss';
import { useState, useMemo, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'components/primitives/form';
import { AddProductStatus, addProduct } from 'behavior/barCodeScanner';
import debounce from 'lodash/debounce';
import { SearchIcon } from 'components/primitives/icons';

const BarCodeInput = () => {
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.barCodeScanner.status === AddProductStatus.Loading);

  const handleChange = useMemo(() => {
    const debouncedAddProduct = debounce(barCode => dispatch(addProduct(barCode)), 500);

    return e => {
      const value = e.target.value;
      setInputValue(value);
      value && debouncedAddProduct(value);
    };
  }, []);

  useEffect(() => {
    !isLoading && inputValue && setInputValue('');
  }, [isLoading]);

  useEffect(() => {
    inputRef.current.focus();

    const focusTimer = setInterval(() => {
      document.activeElement !== inputRef.current && inputRef.current.focus();
    }, 500);

    return () => clearInterval(focusTimer);
  }, []);

  return (
    <div className={styles.inputWrapper}>
      <Input inputRef={inputRef} type="text" value={inputValue} className={styles.input} onChange={handleChange} />
      <span className={styles.inputIcon} aria-hidden>
        <SearchIcon />
      </span>
    </div>
  );
};

export default BarCodeInput;

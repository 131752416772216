exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".RelatedProduct_panel{display:-webkit-flex;display:flex;-webkit-flex-wrap:wrap;flex-wrap:wrap}.RelatedProduct_tile{position:relative;margin:0 0 10px;padding:0 1%;width:50%;text-align:center;color:var(--regularTitle_FontColor,#333)}.RelatedProduct_tile figure{max-width:100%;width:180px;margin:0 auto}.RelatedProduct_tile figure>span{position:relative;vertical-align:bottom;width:100%;padding-top:100%}html.pointer-mouse .RelatedProduct_tile:hover{color:var(--regularTitle_FontColor,#333)}@supports (not (-webkit-hyphens:none)) and (not (-moz-appearance:none)) and (list-style-type:\"*\"){@media (pointer:coarse){html:not(.pointer-mouse) .RelatedProduct_tile:active{color:var(--regularTitle_FontColor,#333)}}}html.pointer-mouse .RelatedProduct_title:hover{text-decoration:underline}@supports (not (-webkit-hyphens:none)) and (not (-moz-appearance:none)) and (list-style-type:\"*\"){@media (pointer:coarse){html:not(.pointer-mouse) .RelatedProduct_title:active{text-decoration:underline}}}.RelatedProduct_thumbnail-img{display:block;position:absolute;max-width:100%;max-height:100%;top:0;left:0;right:0;bottom:0;margin:auto}@media (min-width:var(--breakpoints_Small,600)px),print{.RelatedProduct_tile{width:25%}.RelatedProduct_tile figure{margin:0}.RelatedProduct_title{text-align:left}}@media (min-width:var(--breakpoints_Medium,960)px),print{.RelatedProduct_tile{max-width:180px;width:25%}}@media (min-width:var(--breakpoints_Large,1280)px),print{.RelatedProduct_tile{width:50%}}", ""]);

// exports
exports.locals = {
	"panel": "RelatedProduct_panel",
	"tile": "RelatedProduct_tile",
	"title": "RelatedProduct_title",
	"thumbnail-img": "RelatedProduct_thumbnail-img",
	"thumbnailImg": "RelatedProduct_thumbnail-img"
};
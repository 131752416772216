import { RichText } from 'components/sanaText';
import List from './list/List';
import AddTracking from './addTracking/AddTracking';
import { routesBuilder } from 'routes';
import PageBreadcrumb from 'components/objects/pageBreadcrumb';
import BackLink from './BackLink';

const Page = () => {
  const stockTrackingRoute = routesBuilder.forStockTracking();
  return (
    <>
      <PageBreadcrumb backLinkComponent={BackLink} />
      <RichText textKey="StockTrackings_Heading" />
      <AddTracking stockTrackingRoute={stockTrackingRoute} />
      <List stockTrackingRoute={stockTrackingRoute} />
    </>
  );
};

export default Page;
import styles from './Summary.module.scss';
import { useEffect, useState, useRef, useMemo } from 'react';
import { joinClasses } from 'utils/helpers';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHeaderContext } from 'components/sections/headerContext';
import { loadstockNotifications, checkAddedProductsCount } from 'behavior/stockNotifications';
import LoadingIndicator from './LoadingIndicator';
import Popup from './Popup';
import { Dropdown } from 'components/primitives/dropdown';
import Header from './Header';
import PreloadTexts from './PreloadTexts';
import { throttle } from 'lodash';

const Summary = ({
  items,
  totalCount,
  showNotifications,
  isProductsAvailable,
  stockNotificationList,
  showSubtotal,
  loadstockNotifications,
  compact,
  hideLabel,

}) => {
  const headerContext = useHeaderContext();
  const [dropdownOptions, setDropdownOptions] = useState({ show: false });
  const [linesAmount, setLinesAmount] = useState(null);
  const timeoutId = useRef();
  const showTotals = showSubtotal && canViewPrices
  const [basketSummary, setBasketSummary] = useState(items);
  const throttledSetBasketSummary = useThrottle(setBasketSummary, 300);
  const throttledRequestBasketSummary = useThrottle(loadstockNotifications, 5000);
  const popupHovered = useRef();
  const isFirstRender = useRef(true);
  let x = 0;

  useEffect(() => {
    if (isFirstRender.current)
      return;
    loadstockNotifications(true);
  }, [items, totalCount, showNotifications]);

  useEffect(() => {
    throttledRequestBasketSummary(true);
  }, [items, totalCount, showNotifications]);

  let onHover = null;
  let onDropDownHide = null;

  if (!compact) {
    onHover = () => {
      clearTimeout(timeoutId.current);
      setLinesAmount(null);
      popupHovered.current = true;
    };
    onDropDownHide = () => {
      clearTimeout(timeoutId.current);
      setLinesAmount(null);
      popupHovered.current = false;
    };
  }

  return (

    <div
      className={joinClasses(
        'basket-summary ' + styles.summary,
        !totalCount && styles.isEmpty,
        compact && styles.compact,
      )}
      onMouseOver={onHover}
      onFocus={onHover}
      role="presentation"
    >
      <Dropdown
        bodyAlign="right"
        options={dropdownOptions}
        className={joinClasses(
          'basket-summary ' + styles.dropdown,
          !!totalCount && styles.isEmpty,
        )}
        header={<Header totalCount={totalCount} hideLabel={hideLabel} showNotifications={showNotifications} />}
        onDropDownHide={onDropDownHide}
        hideCaretDown
        disableAnimation
      >
        <>
          {showNotifications && totalCount > 0 && (
            <Popup totalCount={totalCount} items={items} isProductsAvailable={isProductsAvailable} />
          )}
        </>
      </Dropdown>
      <PreloadTexts />
    </div>
  );
};

Summary.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      productId: PropTypes.string.isRequired,
    }),
  ),
  totalCount: PropTypes.number,
  showNotifications: PropTypes.bool,
  updatingBy: PropTypes.string,
  loadstockNotifications: PropTypes.func.isRequired,
};

export default connect(
  ({
    stockNotifications: { items, totalCount, showNotifications },
  }) => ({
    items,
    totalCount,
    showNotifications,
  }), { loadstockNotifications }
)(Summary)

function useThrottle(func, wait) {
  const throttleRef = useRef();

  if (throttleRef.current == null)
    throttleRef.current = throttle(func, wait);

  return throttleRef.current;
}

exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".StockTransactionList_list-wrapper{width:100%}.StockTransactionList_list-wrapper .StockTransactionList_table-wrapper{margin-top:30px}.StockTransactionList_list-wrapper .StockTransactionList_table-wrapper table thead{border-top:1px solid var(--borderColor,#e6e6e6)}.StockTransactionList_list-wrapper .StockTransactionList_table-wrapper table .StockTransactionList_green-text,.StockTransactionList_list-wrapper .StockTransactionList_table-wrapper table .StockTransactionList_red-text{\n  /*!*/}.StockTransactionList_list-wrapper .StockTransactionList_table-wrapper .StockTransactionList_text-center{text-align:center}.StockTransactionList_non-arrow-btn{padding:0 .8em}.StockTransactionList_non-arrow-btn:before{display:none}.StockTransactionList_non-arrow-btn:after{display:none}@media (min-width:var(--breakpoints_Small,600)px){.StockTransactionList_list-wrapper{width:100%}.StockTransactionList_list-wrapper .StockTransactionList_table-wrapper{margin-top:30px}.StockTransactionList_list-wrapper .StockTransactionList_table-wrapper table thead{border-top:1px solid var(--borderColor,#e6e6e6)}.StockTransactionList_list-wrapper .StockTransactionList_table-wrapper table .StockTransactionList_stock-status-icon{vertical-align:top;width:20px}.StockTransactionList_list-wrapper .StockTransactionList_table-wrapper table .StockTransactionList_stock-status-icon img{margin-top:6px}.StockTransactionList_list-wrapper .StockTransactionList_table-wrapper .StockTransactionList_text-center{text-align:center}}", ""]);

// exports
exports.locals = {
	"list-wrapper": "StockTransactionList_list-wrapper",
	"listWrapper": "StockTransactionList_list-wrapper",
	"table-wrapper": "StockTransactionList_table-wrapper",
	"tableWrapper": "StockTransactionList_table-wrapper",
	"green-text": "StockTransactionList_green-text",
	"greenText": "StockTransactionList_green-text",
	"red-text": "StockTransactionList_red-text",
	"redText": "StockTransactionList_red-text",
	"text-center": "StockTransactionList_text-center",
	"textCenter": "StockTransactionList_text-center",
	"non-arrow-btn": "StockTransactionList_non-arrow-btn",
	"nonArrowBtn": "StockTransactionList_non-arrow-btn",
	"stock-status-icon": "StockTransactionList_stock-status-icon",
	"stockStatusIcon": "StockTransactionList_stock-status-icon"
};
import { useEffect } from 'react';
import styles from '../ProductSelector.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { Modal, useModal } from 'components/objects/modals';
import WebcamComponent from './WebcamComponent';
import useBarcodeReader from './useBarcodeReader';
import { Button } from 'components/primitives/buttons';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import barcodeImage from './image/barcode.png';
const BarcodeReader = ({ onBarcodeDetect, isStockTracking, enableReScan, reSet }) => {
  
  const { opened, show, hide } = useModal();
  const { webcamRef, barcode, scanning, resetValues, startScanning ,stopScanning } = useBarcodeReader();

  useEffect(() => {
    if (barcode) {
      onBarcodeDetect(barcode);
      // onBarcodeDetect('4024669720686');

      if (isStockTracking) {
        hidePopup();
      }
    }
  }, [barcode]);

  useEffect(() => {
    if (reSet) {
      resetValues();
    }
  }, [reSet]);

  const openPopup = () =>{
    show();
    startScanning();
  };

  const hidePopup = () =>{
    stopScanning();
    hide();
  };

  return (
    <>
      <button className="non-arrow-btn barcode-btn" onClick={openPopup}><img src={barcodeImage} alt="Barcode" /></button>
      <Modal
        opened={opened}
        hide={() => {
          hidePopup();
        }}
        resetContentOnClose
        onKeyDown={e => console.log(e)}
      >
        <>
          <h2><SimpleText textKey="Barcode_Scanner_Heading" /></h2>
          <WebcamComponent webcamRef={webcamRef} />
        </>
        <div><SimpleText textKey="Barcode_Scanner_Barcode" /> {barcode}
        {!isStockTracking && 
        <Button className={`${btnStyles.btn} ${btnStyles.btnExtraSmall} ${btnStyles.noIcon} ${styles.rescanBtn}`}  onClick={resetValues} disabled={scanning || !enableReScan}>        
        <SimpleText textKey="Barcode_Scanner_ScanAnotherProduct" />
        </Button>}
        </div>
      </Modal>
    </>
  );
};

BarcodeReader.prototype = {
  onBarcodeDetect: PropTypes.func.isRequired,
  isStockTracking: PropTypes.bool.isRequired,
  enableReScan: PropTypes.bool.isRequired,
  reSet: PropTypes.bool.isRequired,
};

export default BarcodeReader;

import styles from './StockTrackingsList.module.scss';
import { useMemo, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updateStockTracking, StockType } from 'behavior/pages/stockTrackings';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import {
  QuantityTextBox,
  createQuantityModel,
} from 'components/primitives/product';
import { getNumberDecimalsSeparator } from 'utils/format';
import { getFormatNumber } from 'utils/format';

export const StockBox = ({
  value,
  type,
  tracking,
  editMode,
  culture,
  isEditing,
  allowEmpty,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [stock, setStock] = useState(value);
  const [stockValid, setStockValid] = useState(true);
  const formatNumber = getFormatNumber(culture);
  const dispatch = useDispatch();
  const saveStock = useCallback(() => {
    if (stockValid && stock >=0) {
      const input = {
        trackingId: tracking.id,
        lowStock: type === StockType.Low ? stock : tracking.lowStock,
        optimalStock:
          type === StockType.Optimal ? stock : tracking.optimalStock,
      };
      dispatch(updateStockTracking(input));
      setIsEdit(false);
      isEditing(false);
    } else {
      document.getElementById(type + '_' + tracking.id).focus();
    }
  }, [stock]);

  const editStock = () => {
    isEditing(true);
    setIsEdit(true);
    setTimeout(function () {
      const element = document.getElementById(type + '_' + tracking.id);
      element && element.focus();
    }, 10);
  };

  const cancelEdit = () => {
    setStock(value);
    setIsEdit(false);
    isEditing(false);
    setStockValid(true);
  };

  const separator = getNumberDecimalsSeparator(culture);

  const quantityModel = useMemo(() => {
    const model = createQuantityModel(null, allowEmpty);
    if(allowEmpty){
      model.minimum  = model.initial = 0;
    }

    return model;
  });

  const handleQuantityChange = useCallback(
    quantity => {
      if (quantity.value === '') {
        setStock(0);
      } else {
        setStock(quantity.value);
      }

      if (
        (type === StockType.Low && quantity.value < tracking.optimalStock) ||
        (type === StockType.Optimal && quantity.value > tracking.lowStock)
      ) {
        setStockValid(true);
      } else {
        setStockValid(false);
      }
    },
    [tracking],
  );

  return (
    <div>
      {!isEdit && (
        <div>
          <span className="stock-no">{formatNumber(value)}</span>
          <br />
          <span className="stock-label">{tracking.unitOfMeasure}</span><br />
          <button onClick={editStock} disabled={editMode} className={styles.linkButton}>
            <SimpleText textKey="StockTracking_Stock_Edit" />
          </button>
        </div>
      )}

      {isEdit && (
        <div>
          <QuantityTextBox
            id={type + '_' + tracking.id}
            quantity={quantityModel}
            value={stock}
            onChange={handleQuantityChange}
            separator={separator}
            hideControlsOnBlur
            allowEmpty
          />
          <br />
          <span className="tracking-unitofmeasure">{tracking.unitOfMeasure}</span><br />
          <button onClick={cancelEdit} className={`${styles.linkButton} ${styles.cancelLink}`}>
            <SimpleText textKey="StockTracking_Stock_Cancel" />
          </button>
          <button onClick={saveStock} className={`${styles.linkButton} ${styles.saveLink}`}>
            <SimpleText textKey="StockTracking_Stock_Save" />
          </button>
          {!stockValid && (
            <div className="optimal-stock-validation-msg">
            <SimpleText textKey="StockTrackings_List_OptimalStockValidation" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

StockBox.propTypes = {
  tracking: PropTypes.object,
  culture: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  editMode: PropTypes.bool,
  type: PropTypes.string.isRequired,
  isEditing: PropTypes.func.isRequired,
  allowEmpty: PropTypes.bool,
};

export default StockBox;

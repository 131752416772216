import PropTypes from 'prop-types';
import ProductSuggestionsBase from './ProductSuggestionsBase';

const ProductSuggestions = ({ hideLabel, className }) => <ProductSuggestionsBase hideLabel={hideLabel} className={className} />;

ProductSuggestions.propTypes = {
  hideLabel: PropTypes.bool,
  className: PropTypes.string,
};

export default ProductSuggestions;

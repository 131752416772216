exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".DisabledSearchBox_fake-form{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center}.DisabledSearchBox_fake-input{color:var(--inputs_FontColor,#333);line-height:1.2;padding-left:1.2em;margin-right:4.2em}", ""]);

// exports
exports.locals = {
	"fake-form": "DisabledSearchBox_fake-form",
	"fakeForm": "DisabledSearchBox_fake-form",
	"fake-input": "DisabledSearchBox_fake-input",
	"fakeInput": "DisabledSearchBox_fake-input"
};
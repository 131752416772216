export const SortingModes = Object.freeze({
  DateAdded: 'ADDITION',
  RecentlyModified: 'RECENT',
});

export const Updaters = Object.freeze({
  Basket: 'BASKET',
  Sync: 'SYNC',
  QuickOrder: 'QUICK_ORDER',
  BarCodeScanner: 'BAR_CODE_SCANNER',
});

import { merge, of, throwError, timer } from 'rxjs';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { basketChangeStarted, stockNotificationsBasketChangeCompleted, navigateTo } from 'behavior/events';
import {
  catchError,
  switchMap,
  map,
  mergeMapTo,
  mergeMap,
  pluck,
  withLatestFrom,
  ignoreElements,
  tap,
  concatMap,
  startWith,
} from 'rxjs/operators';

import {
  STOCK_NOTIFICATIONS_REQUESTED,
  STOCK_NOTIFICATIONS_ADD_PRODUCT,
  STOCK_NOTIFICATIONS_REMOVE_PRODUCT,
  STOCK_NOTIFICATIONS_CREATE_BASKET_LINES,
  STOCK_NOTIFICATIONS_ADD_PRODUCTS,
  stockNotificationReceived,
  loadstockNotifications,
  selectedProductsToAdd,
  addProducts,
  basketUpdated,
} from '../actions';

import {
  stockNotificationsQuery,
  addProductsMutation,
} from '../queries';

import {
  getProductLines,
} from '../util';

import { retryWithToast } from 'behavior/errorHandling';

import { ofType } from 'redux-observable';
import { dispatch } from 'rxjs/internal/observable/range';

export default (action$, state$, { api, localStorage }) => {

  const addedListToBasketKey = 'stockNotifications.products';
  const addProductToBasketList$ = action$.pipe(
    ofType(STOCK_NOTIFICATIONS_ADD_PRODUCT),
    map(({ payload: { product } }) => {
      let products = localStorage.getItem(addedListToBasketKey);
      if (products !== null && products.some(id => id === product))
        return;
      if (products === null) {
        products = [product];
      }
      else {
        products = [...products, product];
      }
      localStorage.setItem(addedListToBasketKey, products);
    }),
    retryWithToast(action$),
    ignoreElements(),
  );

  const stockNotifications$ = action$.pipe(
    ofType(STOCK_NOTIFICATIONS_REQUESTED),
    switchMap(({ payload: { calculated } }) => timer(50).pipe(
      mergeMapTo(api.graphApi(stockNotificationsQuery, { calculated }).pipe(
        mergeMap(({ stockNotifications }) => [stockNotificationReceived(stockNotifications), unsetLoadingIndicator()]),
        catchError(e => {
          if (calculated)
            return of(loadstockNotifications(false));

          return throwError(e);
        }),
        retryWithToast(action$),
      )),
      /*takeUntil(reset$),*/
    )),
  );

  const removeProductFromBasketList$ = action$.pipe(
    ofType(STOCK_NOTIFICATIONS_REMOVE_PRODUCT),
    tap(({ payload: { product } }) => {
      let products = localStorage.getItem(addedListToBasketKey);
      if (products === null) {
        return;
      }
      else {
        products = products.filter(id => id !== product);
      }
      localStorage.setItem(addedListToBasketKey, products);
    }),
    retryWithToast(action$),
    ignoreElements(),
  );

  const createBasketLines$ = action$.pipe(
    ofType(STOCK_NOTIFICATIONS_CREATE_BASKET_LINES),
    concatMap(() => {
      const date = Date.now();
      const products = localStorage.getItem(addedListToBasketKey);
      const lines = getProductLines(products);
      const variables = { lines, addedLinesCount: lines.length, requestModifiedLines: false };
      return api.graphApi(addProductsMutation, variables).pipe(
        map(addedLines => {
          localStorage.removeItem(addedListToBasketKey);
          basketUpdated(date);
          return stockNotificationsBasketChangeCompleted(lines.length);
          //window.location.reload(true);

        }),
      );
    }),
  );

  return merge(
    stockNotifications$,
    addProductToBasketList$,
    removeProductFromBasketList$,
    createBasketLines$,
  );
};


import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AddReduction from './AddReduction';
import List from './List';
import StockReductionProvider from './StockReductionProvider';

const StockReduction = ({ items, addedItem, status, result }) => {
  return (
    <div>
      {<AddReduction items={items} status={status} addedItem={addedItem} />}
      <StockReductionProvider>
        <List items={items} addedItem={addedItem} status={status} result={result} />
      </StockReductionProvider>
    </div>
  );
};

StockReduction.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  addedItem: PropTypes.object,
  status: PropTypes.string,
  result: PropTypes.object,
};

export default connect(({ page: { items, addedItem, status, result } }) => ({
  items,
  addedItem,
  status,
  result,
}))(StockReduction);
import { createContext } from 'react';

const StockReductionContext = createContext({
  lines: null,
  addLine: () => null,
  deleteLine: () => null,
  reset: () => null,
  updateQuantities: () => null,
  updateComments: () => null,
});

export default StockReductionContext;

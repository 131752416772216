exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Usp_usp ul{width:100%;display:block;padding:0;margin:1em 0;font-family:var(--headingText_FontFamily,\"Pathway Gothic One\"),sans-serif;text-transform:uppercase;font-size:1.53em;line-height:1.3;color:var(--regularTitle_FontColor,#333)}.Usp_media{display:inline-block;max-width:100%;padding:0 .6em 0 0;box-sizing:border-box}.Usp_item{display:block;margin:.6em 0;padding:0}.Usp_icon{display:inline-block;margin:-.2em 0 0;vertical-align:middle;max-width:100%}@media (-ms-high-contrast:active),(-ms-high-contrast:none){.Usp_icon,::-ms-backdrop{max-width:128px}}.Usp_separator{display:none}@media (min-width:var(--breakpoints_Medium,960)px),print{.Usp_usp ul{-webkit-justify-content:space-between;justify-content:space-between;padding:.5em 0}.Usp_item,.Usp_usp ul{display:-webkit-flex;display:flex}.Usp_item{-webkit-justify-content:space-around;justify-content:space-around;-webkit-align-items:center;align-items:center;text-align:center}.Usp_usp li:first-child{text-align:left}.Usp_usp li:last-child{text-align:right}.Usp_separator{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;width:2em}.Usp_separator:before{content:\"\";display:block;width:2px;height:40px;margin:0 auto;background-color:var(--borderColor,#e6e6e6);-webkit-transform:rotate(10deg);transform:rotate(10deg)}}.Usp_text{\n  /*!*/}", ""]);

// exports
exports.locals = {
	"usp": "Usp_usp",
	"media": "Usp_media",
	"item": "Usp_item",
	"icon": "Usp_icon",
	"separator": "Usp_separator",
	"text": "Usp_text"
};
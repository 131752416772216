import styles from './Summary.module.scss';
import { memo, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { joinClasses } from 'utils/helpers';
import { Link } from 'components/primitives/links';
import { SimpleText } from 'components/sanaText';
import { routesBuilder } from 'routes';
import { useLayoutShifter, useExtras } from 'utils/layout';
//import { CartSummaryIcon } from 'components/primitives/icons';
import { HeartIcon, InfoIcon } from 'components/primitives/icons';
import bellIcon from './images/bell-xxlg.svg';

const Header = ({ totalCount, showNotifications, hideLabel, showFixed }) => {
  const extras = useExtras();
  const ref = useRef();
  const { topFixedElementsHeight } = useLayoutShifter();

  useEffect(() => {
    let getBasketPage = document.getElementById("basketPage")
      if ( getBasketPage === null) {
        var body = document.body;
        body.classList.remove("Basket-page");
        }
  },);
  
  useEffect(() => {
    if (!showFixed)
      return;
    // Updates basket link top style property depending on layout top shift value.
    /*ref.current.style.top = `${topFixedElementsHeight}px`;*/
  }, [showFixed, topFixedElementsHeight]);

  const notificationsIcon = <img className={`${styles.icon} bell-icon`} src={bellIcon} aria-hidden alt="bell-icon" />;

  const basketLink = (
    <span
      className={
        joinClasses(
          styles.header,
          !totalCount && styles.isEmpty,
          hideLabel && styles.withoutLabel,
          showFixed && styles.fixed,
          (totalCount !== 0 && showNotifications) && styles.showNotifications,
          (totalCount === 0 || !showNotifications) && styles.hideNotifications,
        )
      }
    >
      {notificationsIcon}
      <span className={`${styles.summaryNumber} summary-number-continer`}><span className="summary-number-block">{totalCount}</span></span>
    </span>

  );

  return (
    (
      <>
        {showFixed && extras ? ReactDOM.createPortal(basketLink, extras) : basketLink}
        <span className={`${styles.placeholder} ${showFixed ? styles.fixed : ''}`} aria-hidden>
          {notificationsIcon}
          <span className={`${styles.summaryNumber} summary-number-continer`}><span className="summary-number-block">{totalCount}</span></span>
        </span>
      </>
    )
  );
};

Header.propTypes = {
  totalCount: PropTypes.number,
  showNotifications: PropTypes.bool,
  hideLabel: PropTypes.bool,
  showFixed: PropTypes.bool,
};

export default memo(Header);

import styles from './BarCodeScanner.module.scss';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { SimpleText } from 'components/sanaText';
import { AbilityTo } from 'behavior/user/constants';
import { useHasAbilities } from 'components/objects/user';
import { Modal, useModal } from 'components/objects/modals';
import { SanaButton } from 'components/primitives/buttons';
import { RouteName } from 'routes';
import { modifyBasket } from 'behavior/basket';
import { refreshData as refreshCheckoutData } from 'behavior/pages/checkout';
import { clearResult } from 'behavior/barCodeScanner';
import ToggleButton from './ToggleButton';
import BarCodeInput from './BarCodeInput';
import ActionLink from './ActionLink';
import AddProductResultAlert from './AddProductResultAlert';

const BluetoothBarCodeScanner = ({ options }) => {
  const dispatch = useDispatch();
  const { routeName, anyProductAdded } = useSelector(state => ({
    routeName: state.routing.routeData?.routeName,
    anyProductAdded: state.barCodeScanner.anyProductAdded,
  }), shallowEqual);
  const { opened, show: showModal, hide: hideModal } = useModal();

  const handleModalHide = () => {
    hideModal();
    dispatch(clearResult());

    if (!anyProductAdded)
      return;

    if (routeName === RouteName.BasketPage)
      dispatch(modifyBasket());
    else if (routeName === RouteName.Checkout)
      dispatch(refreshCheckoutData());
  };

  return (
    <>
      <ToggleButton
        style={options.toggleButtonStyle}
        icon={options.toggleIcon}
        hoverIcon={options.toggleHoverIcon}
        onClick={() => showModal()}
      />
      <Modal opened={opened} hide={handleModalHide} resetContentOnClose>
        <h2><SimpleText textKey="BarCodeScanner_Header" /></h2>
        <SimpleText textKey="BarCodeScanner_Description" />
        <BarCodeInput />
        <AddProductResultAlert />
        <div className={styles.actions}>
          <SanaButton textKey="Close" aria-keyshortcuts="Escape" onClick={handleModalHide} />
          <ActionLink link={options.overlayActionLink} routeName={routeName} onClick={handleModalHide} />
        </div>
      </Modal>
    </>
  );
};

BluetoothBarCodeScanner.propTypes = {
  options: PropTypes.shape({
    toggleButtonStyle: PropTypes.number.isRequired,
    toggleIcon: PropTypes.string,
    toggleHoverIcon: PropTypes.string,
    overlayActionLink: PropTypes.number.isRequired,
  }),
};

const BluetoothBarCodeScannerBlock = ({ model, isDesignerMode }) => {
  const [canOrderProducts] = useHasAbilities(AbilityTo.OrderProducts);
  if (!canOrderProducts && !isDesignerMode)
    return null;

  return <BluetoothBarCodeScanner options={model} />;
};

BluetoothBarCodeScannerBlock.propTypes = {
  model: PropTypes.shape({
    toggleButtonStyle: PropTypes.number.isRequired,
    toggleIcon: PropTypes.string,
    toggleHoverIcon: PropTypes.string,
    overlayActionLink: PropTypes.number.isRequired,
  }),
  isDesignerMode: PropTypes.bool,
};

export default BluetoothBarCodeScannerBlock;

import { ofType } from 'redux-observable';
import { merge } from 'rxjs';
import {
  ADDRESS_SELECT,
  ADDRESS_SAVE,
  GUEST_SAVE,
  notifyAddressSelected,
  notifyAddressSaved,
  addressTemplateFieldsReceived,
  checkoutInfoUpdated,
} from './actions';
import { switchMap, map } from 'rxjs/operators';
import { retryWithToast, catchApiErrorWithToast } from 'behavior/errorHandling';
import {
  getSelectAddressMutation,
  getSaveAddressMutation,
  saveGuestMutation,
  reloadTemplateFieldsQuery,
} from './queries';
import { adjustShippingMethodData, adjustPaymentMethodData, adjustGuestProfileData, adjustCheckoutAddresses, navigateOnIncorrect } from './helpers';
import { skipIfPreview } from 'behavior/preview';

export default function createEpic(waitForSubmit) {
  return function (action$, state$, { api, logger }) {
    const isQuote = () => state$.value.page.info?.isQuote || false;
    const isPromotion = () => !!state$.value.page.info?.quote;

    const selectAddress$ = action$.pipe(
      ofType(ADDRESS_SELECT),
      skipIfPreview(state$),
      switchMap(({ payload }) => waitForSubmit(() => api.graphApi(getSelectAddressMutation(isPromotion()), {
        id: payload.id,
        asQuote: isQuote(),
        maxLines: state$.value.settings.checkout.maxOverviewLines + 1,
      }).pipe(
        map(({ checkout, viewer }) => {
          if (checkout) {
            const selectResult = checkout.address.select;
            adjustShippingMethodData(selectResult.info);
            adjustPaymentMethodData(selectResult.info);
            selectResult.info.pickupLocations = null;

            if (selectResult.success)
              return notifyAddressSelected(payload.id, selectResult.info);

            adjustCheckoutAddresses(selectResult.info, viewer);
            return checkoutInfoUpdated(selectResult.info);
          }

          return navigateOnIncorrect(state$.value.page.info);
        }),
        retryWithToast(action$, logger),
      ))),
    );

    const saveAddress$ = action$.pipe(
      ofType(ADDRESS_SAVE),
      skipIfPreview(state$),
      switchMap(action => waitForSubmit(() => api.graphApi(getSaveAddressMutation(isPromotion()), {
        input: action.payload,
        asQuote: isQuote(),
        maxLines: state$.value.settings.checkout.maxOverviewLines + 1,
      }).pipe(
        map(({ checkout, viewer }) => {
          if (checkout) {
            const saveResult = checkout.address.save;
            adjustShippingMethodData(saveResult.info);
            adjustPaymentMethodData(saveResult.info);
            saveResult.info.pickupLocations = null;

            if (saveResult.success)
              return notifyAddressSaved(saveResult.address.address, saveResult.info);

            adjustCheckoutAddresses(saveResult.info, viewer);
            return checkoutInfoUpdated(saveResult.info);
          }

          return navigateOnIncorrect(state$.value.page.info);
        }),
        catchApiErrorWithToast(undefined, api.graphApi(reloadTemplateFieldsQuery).pipe(
          map(({ entityTemplateFields }) => addressTemplateFieldsReceived(entityTemplateFields)),
        )),
        retryWithToast(action$, logger),
      ))),
    );

    const saveGuest$ = action$.pipe(
      ofType(GUEST_SAVE),
      skipIfPreview(state$),
      switchMap(action => waitForSubmit(() => api.graphApi(saveGuestMutation, {
        input: action.payload,
        maxLines: state$.value.settings.checkout.maxOverviewLines + 1,
      }).pipe(
        map(({ checkout }) => {
          if (checkout && checkout.address.saveGuest) {
            const saveResult = checkout.address.saveGuest;
            adjustShippingMethodData(saveResult.info);
            adjustPaymentMethodData(saveResult.info);
            adjustGuestProfileData(saveResult.info);
            saveResult.info.pickupLocations = null;

            return checkoutInfoUpdated(saveResult.info);
          }

          return navigateOnIncorrect(state$.value.page.info);
        }),
        catchApiErrorWithToast(undefined, api.graphApi(reloadTemplateFieldsQuery).pipe(
          map(({ entityTemplateFields }) => addressTemplateFieldsReceived(entityTemplateFields)),
        )),
        retryWithToast(action$, logger),
      ))),
    );

    return merge(selectAddress$, saveAddress$, saveGuest$);
  };
}

exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".StockReduction_table-wrapper{margin-top:30px}.StockReduction_table-wrapper table thead{border-top:1px solid var(--borderColor,#e6e6e6)}.StockReduction_table-wrapper .StockReduction_link-button{-webkit-appearance:none;border:none;outline:none;background-color:transparent;color:var(--hyperlink_FontColor,#1f7bc9);cursor:pointer}.StockReduction_table-wrapper .StockReduction_text-center{text-align:center}.StockReduction_non-arrow-btn{padding:0 .8em}.StockReduction_non-arrow-btn:before{display:none}.StockReduction_non-arrow-btn:after{display:none}@media (min-width:var(--breakpoints_Small,600)px){.StockReduction_filter-multi-select .StockReduction_non-arrow-btn{margin-left:15px}.StockReduction_table-wrapper{margin-top:30px}.StockReduction_table-wrapper table thead{border-top:1px solid var(--borderColor,#e6e6e6)}.StockReduction_table-wrapper .StockReduction_link-button{-webkit-appearance:none;border:none;outline:none;background-color:transparent;color:var(--hyperlink_FontColor,#1f7bc9);cursor:pointer}.StockReduction_table-wrapper .StockReduction_text-center{text-align:center}.StockReduction_btn-wrapper{margin:15px 0;float:right}.StockReduction_btn-wrapper .StockReduction_Button_btn-action{margin-left:15px}}", ""]);

// exports
exports.locals = {
	"table-wrapper": "StockReduction_table-wrapper",
	"tableWrapper": "StockReduction_table-wrapper",
	"link-button": "StockReduction_link-button",
	"linkButton": "StockReduction_link-button",
	"text-center": "StockReduction_text-center",
	"textCenter": "StockReduction_text-center",
	"non-arrow-btn": "StockReduction_non-arrow-btn",
	"nonArrowBtn": "StockReduction_non-arrow-btn",
	"filter-multi-select": "StockReduction_filter-multi-select",
	"filterMultiSelect": "StockReduction_filter-multi-select",
	"btn-wrapper": "StockReduction_btn-wrapper",
	"btnWrapper": "StockReduction_btn-wrapper",
	"Button_btn-action": "StockReduction_Button_btn-action",
	"buttonBtnAction": "StockReduction_Button_btn-action"
};
import styles from 'components/objects/product/Details.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Row } from 'components/primitives/grid';
import { SimpleText, RichText } from 'components/sanaText';
import {
  ProductPrice,
  BaseUomPrice,
  PricePer,
  ProductStock,
  OfflinePriceWarning,
  useCanViewUom,
  UomSelector,
} from 'components/primitives/product';
import { FormattedPrice } from 'components/primitives/price';
import { useProductContext } from './hooks';
import { AbilityTo, AbilityState } from 'behavior/user/constants';
import { abilitiesPropType } from 'behavior/user';
import { withAbilities } from 'components/objects/user';
import QuantityBox from './QuantityBox';
import OrderBoxForm from './OrderBoxForm';
import { SanaButton } from 'components/primitives/buttons';
import { VariantDropdowns } from './variantDropdowns';
import { VolumePricesButton } from './volumePrices';
import { showMatrix } from './showMatrix';
import { Placeholder } from 'components/primitives/placeholders';
import { usePrintMode, useCurrencyInfo } from 'utils/hooks';
import ConfigureButton from '../productConfigurator/ConfigureButton';
import { BomComponents } from './bomComponents';
import { VariantsMatrixPopup } from './variantsMatrix';
import { AddToWishlist } from './wishlist';
import { AgreementSelector } from './salesAgreements';
import { routesBuilder } from 'routes';
import { SanaLinkButton } from 'components/primitives/links';
import { InfoAlert } from 'components/primitives/alerts';
import { Link } from 'components/primitives/links';

const OrderBox = ({
  matrixPreset,
  abilities: [orderProductsAbility, useSalesAgreementsAbility, stockTrackingAbility],
  wishListEnabled,
  allowUOMSelection,
  salesAgreement,
}) => {
  const { product, calculatedInfo, pricesInclTax, updateUomId, uomId: selectedUomId, variantId } = useProductContext();
  let isVariantSpecialShipping = false;

  let deliveryTimeText = product.deliveryTimeText;
  let showDeliveryTimeText = deliveryTimeText !== null && deliveryTimeText !== '';

  let pricePerBaseUoM = product.pricePerBaseUoM;
  let baseUoMDescription = product.baseUoMDescription;

  let surchargeType = product.surchargeType;
  let surchargeValue = product.surchargeValue;

  let percentageSurchargeType = surchargeType === 'Percentage';

  if (product.hasVariants) {
    const selectedVariant = product.variants.find(v => v.id === variantId);
    isVariantSpecialShipping = selectedVariant ? selectedVariant.isVariantSpecialShipping : false;

    deliveryTimeText = selectedVariant ? selectedVariant.deliveryTimeText : deliveryTimeText;
    showDeliveryTimeText = deliveryTimeText !== null && deliveryTimeText !== '';

    pricePerBaseUoM = selectedVariant ? selectedVariant.pricePerBaseUoM : pricePerBaseUoM;
    baseUoMDescription = selectedVariant ? selectedVariant.baseUoMDescription : baseUoMDescription;

    surchargeType = selectedVariant ? selectedVariant.surchargeType : surchargeType;
    surchargeValue = selectedVariant ? selectedVariant.surchargeValue : surchargeValue;

    percentageSurchargeType = selectedVariant ? selectedVariant.surchargeType === 'Percentage' : false;

  }

  const isPrintMode = usePrintMode();
  const canViewUom = useCanViewUom();

  if (!product)
    return null;

  const { price, listPrice, inventory, isOrderable } = calculatedInfo;

  const {
    stockLevels,
    hasVariants,
    isOrderable: productOrderable,
    productConfiguratorInfo,
    hasVolumePrices,
  } = product;

  const currencyInfo = useCurrencyInfo();

  const uomId = canViewUom && product.uom && product.uom.id;
  const canOrderProducts = orderProductsAbility === AbilityState.Available;
  const canTrackStock = stockTrackingAbility === AbilityState.Available;

  const pricePlaceholder = <Placeholder className={styles.pricePlaceholder} />;
  const taxSuffixPlaceholder = <Placeholder className={styles.taxSuffixPlaceholder} />;
  const availabilityTextPlaceholder = <Placeholder className={styles.availabilityTextPlaceholder} />;
  const btnPlaceholder = <Placeholder className={styles.btnPlaceholder} />;
  const priceRowPlaceholder = <Placeholder className={styles.priceRowPlaceholder} />;
  const availabilityPlaceholder = <Placeholder className={styles.availabilityPlaceholder} />;

  if (matrixPreset && product.hasVariants) {
    const matrixSupported = product.variantComponentGroups?.length <= 2;
    if (isPrintMode && matrixSupported)
      return null;

    const calculationInfoIsLoaded = product.variants.some(v => v.isOrderable !== undefined);

    if (matrixSupported && !calculationInfoIsLoaded && productOrderable)
      return btnPlaceholder;

    if (productOrderable && showMatrix(product)) {
      const showBomComponents = !!product.bomComponents?.length;
      return (
        <>
          <VariantsMatrixPopup />
          {showBomComponents && <BomComponents />}
          {hasVolumePrices && <VolumePricesButton showCompleteList canOrderProducts={canOrderProducts} />}
        </>
      );
    }
  }

  const shouldShowOrderBox = price !== null
    || (inventory !== null && stockLevels != null)
    || (productOrderable && hasVariants)
    || (canOrderProducts && isOrderable && productOrderable)
    || (canOrderProducts && isOrderable === false);

  if (!shouldShowOrderBox)
    return null;

  const isProductOrderable = isOrderable && productOrderable;
  const showAddToWishList = wishListEnabled && !(productConfiguratorInfo && productConfiguratorInfo.isProductConfigurable);

  let orderBox = null;
  if (!isPrintMode) {
    if (canOrderProducts) {
      const shouldShowAgreementSelector = productOrderable
        && useSalesAgreementsAbility === AbilityState.Available
        && salesAgreement;
      orderBox = (
        <OrderBoxForm>
          {shouldShowAgreementSelector &&
            <AgreementSelector
              allowUomSelection={allowUOMSelection}
              canViewUom={canViewUom}
              salesAgreement={salesAgreement}
            />
          }
          <QuantityBox disabled={!isProductOrderable} />
          {showDeliveryTimeText &&
            <div className={styles.deliveryTime}>
              <RichText
                textKey={'ProductDeliveryInformation'}
                formatWith={[deliveryTimeText]}
              />
            </div>
          }
          {!showDeliveryTimeText && <div className={styles.deliveryTime}>
            <RichText
              textKey={'DefaultProductDeliveryInformation'}
            />
          </div>}

          <div>
            {(product.isSpecialShipping || isVariantSpecialShipping) && (<div> <InfoAlert role="alert"> <RichText textKey="SpecialShippingMessage" />  </InfoAlert></div>)}
          </div>
          {isProductOrderable &&
            <Row className={styles.row} crossAxisAlign="center">
              <Col xs={12} sm={{ size: 'auto', offset: 3 }} md={{ offset: 0 }} className={styles.limitedWidth}>

                {product.allowedForBToBOnly && <SanaLinkButton to={routesBuilder.forBusinessRegistration} textKey="RegisterAsB2BCustomer" className={`${btnStyles.btnSmall} reg-b2b-cus-btn`} />}
                {product.allowedForBToBOnly && (<div className="italic_text reg-b2b-desc"> <RichText textKey="OnlyAvailableForB2BCustomers" /> </div>)}
                {!product.allowedForBToBOnly && productConfiguratorInfo.isProductConfigurable && <ConfigureButton />}

                {!product.allowedForBToBOnly && !productConfiguratorInfo.isProductConfigurable &&
                  <SanaButton
                    textKey="AddToBasket"
                    type="submit"
                    className={`${btnStyles.btnAction} ${btnStyles.btnBig} ${styles.addToBasketBtn}`}
                    placeholder={btnPlaceholder}
                  />
                }

              </Col>
              <Col xs={12} className={styles.gutter} />
              <Col xs={12} sm="auto" className={`${styles.limitedWidth} pdp_link-block`}>
                {showAddToWishList &&
                  <div className={styles.afterBtnLinks}>
                    <AddToWishlist />
                  </div>
                }
                {canTrackStock && <Link className={`${linkStyles.link} new-order-link`} to={routesBuilder.forStockTracking(product.id, variantId)} rel="nofollow"><SimpleText textKey="StockTrackings_TrackStock" /></Link>}
              </Col>
            </Row>
          }
        </OrderBoxForm>
      );
    }
    else if (uomId && allowUOMSelection && product.uoms?.length > 1) {
      orderBox = (
        <Row>
          <Col xs={{ size: 9, offset: 3 }} lg={{ size: 10, offset: 2 }}>
            <UomSelector
              className={styles.uom}
              productId={product.id}
              allowUOMSelection={allowUOMSelection}
              uomId={selectedUomId || uomId}
              uoms={product.uoms}
              onUomChange={updateUomId}
              isOrderable={isOrderable}
            />
          </Col>
        </Row>
      );
    }
  }

  return (
    <>
      {!isPrintMode && <BomComponents />}
      <div className={styles.orderBox}>
        {price !== null && (
          typeof price !== 'undefined'
            ? (
              <Row className={styles.row} crossAxisAlign="center">
                <Col xs={3} lg={2} className={styles.ellipsis} id="price_Label">
                  <SimpleText textKey="Price" placeholder={pricePlaceholder} />
                </Col>
                <Col xs={9} lg={10} className={styles.field}>
                  <Row className={styles.prices}>
                    <div className="discount_price-block-container">
                      <ProductPrice salesPrice={price} basePrice={listPrice} />
                    </div>
                    <Col xs={12} sm="auto" className={styles.afterPrice}>
                      {uomId && <PricePer uomId={uomId} uoms={product.uoms} />}
                      <div>
                        {pricesInclTax != null && (
                          <RichText
                            textKey={pricesInclTax ? 'InclTaxSuffix' : 'ExclTaxSuffix'}
                            placeholder={taxSuffixPlaceholder}
                          />
                        )}
                      </div>
                      <div className="mtz-notice">
                        {surchargeValue !== 0 && percentageSurchargeType && (
                          <div className="mtz-notice-pdp">
                            <RichText
                              textKey={'SurchargeInformation_Percentage_PDP'}
                              formatWith={[surchargeValue]}
                            />
                          </div>
                        )}
                        {surchargeValue !== 0 && !percentageSurchargeType && (
                          <div className="mtz-notice-pdp">
                            <RichText
                              textKey={'SurchargeInformation_ActualValue_PDP'}
                              formatWith={[<FormattedPrice price={surchargeValue} currencyInfo={currencyInfo} />]}
                            />
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    {!(product.hasVariants) && product.pricePerBaseUoM !== 0 && (
                      <Col xs={12} sm="auto">
                        <span className={styles.pricePerBaseUom}>
                          <BaseUomPrice baseUomPrice={product.pricePerBaseUoM} baseUoMDescription={product.baseUoMDescription} />
                        </span>
                      </Col>
                    )}
                    {product.hasVariants && pricePerBaseUoM !== 0 && (
                      <Col xs={12} sm="auto">
                        <span className={styles.pricePerBaseUom}>
                          <BaseUomPrice baseUomPrice={pricePerBaseUoM} baseUoMDescription={baseUoMDescription} />
                        </span>
                      </Col>
                    )}
                  </Row>
                  {!isPrintMode && hasVolumePrices && <VolumePricesButton canOrderProducts={canOrderProducts} />}
                </Col>
                {!isPrintMode && (
                  <Col>
                    <OfflinePriceWarning />
                  </Col>
                )}
              </Row>
            )
            : priceRowPlaceholder
        )}
        {inventory !== null && stockLevels != null && (
          <>
            {
              typeof inventory === 'number'
                ? (
                  <Row className={styles.row} crossAxisAlign="center">
                    <Col xs={3} lg={2} className={styles.ellipsis} id="availability_Label">
                      <SimpleText textKey="Availability" placeholder={availabilityTextPlaceholder} />
                    </Col>
                    <Col xs={9} lg={10} aria-labelledby="availability_Label">
                      <ProductStock inventory={inventory} stockLevels={stockLevels} />
                    </Col>
                  </Row>
                )
                : availabilityPlaceholder
            }
          </>
        )}
        {productOrderable && hasVariants && <VariantDropdowns />}
        {orderBox}
        {canOrderProducts && isOrderable === false && (
          <div className={styles.cannotOrder}>
            <RichText textKey={hasVariants ? 'ProductVariantCannotBeOrderedAtThisTime' : 'ProductCannotBeOrderedAtThisTime'} />
            {showAddToWishList && !isPrintMode && <>{' '}<AddToWishlist /></>}
          </div>
        )}
      </div>
    </>
  );
};

OrderBox.propTypes = {
  abilities: abilitiesPropType,
  matrixPreset: PropTypes.bool,
  wishListEnabled: PropTypes.bool,
  allowUOMSelection: PropTypes.bool,
  salesAgreement: PropTypes.object,
};

const mapStateToProps = ({
  settings: {
    wishListEnabled,
    product,
  },
  page: { salesAgreement },
}) => ({
  wishListEnabled,
  allowUOMSelection: product && product.allowUOMSelection,
  salesAgreement,
});

export default withAbilities(
  connect(mapStateToProps)(OrderBox),
  [AbilityTo.OrderProducts, AbilityTo.UseSalesAgreements, AbilityTo.StockTracking],
);

import { RichText } from 'components/sanaText';
import List from './list/List';
import { routesBuilder } from 'routes';

const Page = () => {
  const stockTransactionRoute = routesBuilder.forStockTransaction();
  return (
    <>
        <div className="StockTransactions_list-block">
        <List stockTransactionRoute={stockTransactionRoute} />
        </div>
    </>
  );
}

export default Page;
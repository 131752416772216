import styles from './BarCodeScanner.module.scss';
import { useSelector } from 'react-redux';
import { AddProductStatus } from 'behavior/barCodeScanner';
import { SimpleText } from 'components/sanaText';
import { SuccessAlert, DangerAlert } from 'components/primitives/alerts';
import Spinner from 'components/primitives/spinner/Spinner';

const AddProductResultAlert = () => {
  const { status, productInfo } = useSelector(({ barCodeScanner }) => barCodeScanner);

  if (status === AddProductStatus.Loading)
    return (
      <div className={styles.loader}>
        <Spinner />
        <SimpleText textKey="Loading" />
      </div>
    );

  if (status === AddProductStatus.Success) {
    if (productInfo.variantTitle)
      return (
        <SuccessAlert role="alert">
          <SimpleText
            textKey="BarCodeScanner_ProductVariantAdded"
            formatWith={[productInfo.id, productInfo.title, productInfo.variantTitle]}
          />
        </SuccessAlert>
      );

    return (
      <SuccessAlert role="alert">
        <SimpleText
          textKey="BarCodeScanner_ProductAdded"
          formatWith={[productInfo.id, productInfo.title]}
        />
      </SuccessAlert>
    );
  }

  if (status === AddProductStatus.ProductNotFound)
    return <DangerAlert role="alert"><SimpleText textKey="BarCodeScanner_ProductNotFound" /></DangerAlert>;

  if (status === AddProductStatus.MultipleProductsFound)
    return <DangerAlert role="alert"><SimpleText textKey="BarCodeScanner_MultipleProductsFound" /></DangerAlert>;

  if (status === AddProductStatus.ProductOutOfStock)
    return <DangerAlert role="alert"><SimpleText textKey="BarCodeScanner_ProductOutOfStock" /></DangerAlert>;

  if (status === AddProductStatus.ConfigurableProduct)
    return <DangerAlert role="alert"><SimpleText textKey="BarCodeScanner_ConfigurableProduct" /></DangerAlert>;

  if (status === AddProductStatus.AllowedForB2bOnly)
    return <DangerAlert role="alert"><SimpleText textKey="BarCodeScanner_AllowedForB2bOnly" /></DangerAlert>;

  return null;
};

export default AddProductResultAlert;

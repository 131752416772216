export const ADMINTEXTS_REQUESTED = 'ADMINTEXTS_REQUESTED' as const;
export const loadTexts = (keys: string[]) => ({
  type: ADMINTEXTS_REQUESTED,
  payload: keys,
});

export const ADMINTEXTS_LOADED = 'ADMINTEXTS_LOADED' as const;
export const textsLoaded = (texts: Record<string, string | null>) => ({
  type: ADMINTEXTS_LOADED,
  payload: texts,
});

export type AdminTextsAction = ReturnType<
  | typeof loadTexts
  | typeof textsLoaded
>

import PropTypes from 'prop-types';
import Webcam from 'react-webcam';

const WebcamComponent = ({ webcamRef }) => (
  <Webcam
    audio={false}
    width={'100%'}
    height={'100%'}
    ref={webcamRef}
    screenshotFormat={'image/png'}
    videoConstraints={{
      facingMode: 'environment',
    }}
  />
);

WebcamComponent.propTypes = {
  webcamRef: PropTypes.object,
};

export default WebcamComponent;
import { createContext } from 'react';

const UpdateContext = createContext({
  quantities: null,
  setQuantity: null,
  deleteLines: null,
  writeOnlySubmit: null,
  submit: null,
  setLineAgreement: null,
  deleteLineAgreement: null,
});

export default UpdateContext;

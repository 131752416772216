import linkStyles from 'components/primitives/links/Link.module.scss';
import PropTypes from 'prop-types';
import { useSanaTexts } from 'components/sanaText';
import ErrorButton from './ErrorButton';
import { makeSimpleText } from 'utils/render';
import { isBrowser } from 'utils/detections';

const ErrorId = ({ id }) => {
  const format = useSanaTexts(['ErrorId']).texts[0];

  return (
    <div>
      {makeSimpleText(
        format || 'Error ID: {0}',
        [(ErrorButton.canCopy || !isBrowser) ? <ErrorButton id={id} className={linkStyles.link} /> : id],
        true,
      )}
    </div>
  );
};

ErrorId.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ErrorId;

import { RichText, SimpleText } from 'components/sanaText';
import styles from './StockTransactionList.module.scss';
import tableStyles from 'components/primitives/table/Table.module.scss';
import { connect } from 'react-redux';
import { useRef, useEffect } from 'react';
import Fiter from './Filter';
import LinksPaging from './LinksPaging';
import { UpdateType } from 'behavior/pages/stockTransaction';
import {
  useHandlerLockerInPreview,
} from 'components/objects/preview';
import { useLoadData, pageSize } from '../hook';
import ReverseReductionConfirmationModal from '../ReverseReductionConfirmationModal';
import { useIsMobileViewport, useCultureName } from 'utils/hooks';
//import upArrow from '../images/up-arrow.png';
//import downArrow from '/content/files/stockTransaction/up-arrow.png';
import { toLocaleDate, toLocaleTime } from 'utils/format';
import { useSelector } from 'react-redux';
const ListItems = ({
  items,
  totalCount,
  page,
  stockTransactionRoute,
  status,
}) => {
  if (!items)
    return null;

  const filterRef = useRef();
  const pageRef = useRef();
  const cultureName = useCultureName();
  const loadData = useLoadData(stockTransactionRoute);
  const product = useSelector(state => state.stockTransactions.productFilter);
  if (product && !filterRef.current) {
    filterRef.current = { itemNo: product, variantId: ' ', shopAccountName: '', transactionType: '', fromDate: null, toDate: null };
  }

  const applyFilter = useHandlerLockerInPreview(filter => {
    filterRef.current = filter;
    loadData(page, filterRef.current);
  });

  const onPageChange = page => {
    pageRef.current = page;
    loadData(page, filterRef.current);
  };
  const isMobile = useIsMobileViewport();

  useEffect(() => {
    if (status != null) {
      const { type } = status;
      if (type && type === UpdateType.Reversed) {
        if ((pageRef.current + 1) * pageSize - totalCount === (pageSize - 1))
          pageRef.current = pageRef.current - 1;

        loadData(pageRef.current, filterRef.current);
      }
    }
  }, [status]);

  return (
    <>

      <div id="stockTransactionList" className={styles.listWrapper}>
        <Fiter
          applyFilter={applyFilter}
          filter={filterRef.current}
        />

        {totalCount === 0 && (
          <div className="msg-block">
            <RichText textKey="MyAccount_StockTracking_Trackings" />
          </div>
        )}
        {totalCount > 0 && (
          <div className={styles.tableWrapper}>
            <h2><SimpleText textKey="StockTransactions_RecentTransactions" /></h2>
            <table className={`${tableStyles.table} stock-tracking-table recent-tranaction-table`}>
              <thead>
                <tr>
                  <th className={`${styles.stockStatusIcon}`}>
                    &nbsp;
                  </th>
                  <th>
                    <SimpleText textKey="StockTransactions_Item" />
                  </th>
                  <th className={`${styles.textCenter} `}>
                    <SimpleText textKey="StockTransactions_Quantity" />
                  </th>
                  <th className={`${styles.textCenter} `}>
                    <SimpleText textKey="StockTransactions_TransactionBy" />
                  </th>
                  <th className={`${styles.textCenter} `}>
                    <SimpleText textKey="StockTransactions_DateTime" />
                  </th>
                  <th>
                    <SimpleText textKey="StockTransactions_Comment" />
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {items.map(({ id, trackingId, productId, variantId, quantity, transactionBy, comment, createdDate, isReduceStock, title, barcode, isReversed, reversedDate, uOM, reverseReason, isExpiredForReversal }) => (
                  <>
                    <tr key={id}>
                      <td className={!isMobile ? `${styles.stockStatusIcon}` : 'mobile-table-row'}>
                        <div className={!isMobile ? 'visually-hidden' : 'mobile-table-name'}>&nbsp;</div>
                        <div className="mobile-table-value">
                          {/*            {isReduceStock && <img src={downArrow} alt="Downarrow" />}*/}
                          {isReduceStock && <span className="downarrow-stock-arrow">&#8595;</span>}
{/*                          {!isReduceStock && <FontAwesomeIcon icon={faArrowAltCircleUp} />}*/}
                          {!isReduceStock && <span className="uparrow-stock-arrow">&#8593;</span>}
                        </div>
                      </td>
                      <td className={!isMobile ? 'vertical-align-top' : 'mobile-table-row'}>
                        <div className={!isMobile ? 'visually-hidden' : 'mobile-table-name'}><SimpleText textKey="StockTransactions_Item" /></div>
                        <div className="mobile-table-value">
                          <div className={isReduceStock ? `${styles.redText}` : `${styles.greenText}`}>
                            <div><strong>{title}</strong></div>
                            <div><strong><SimpleText textKey="StockTransactions_No" /></strong>{productId}</div>
                            {variantId && (<div><strong><SimpleText textKey="StockTransactions_VAR" /></strong>{variantId}</div>)}
                            {barcode && (<div><strong><SimpleText textKey="StockTransactions_EAN" /></strong>{barcode}</div>)}
                          </div>
                        </div>
                      </td>
                      <td className={!isMobile ? 'table-text-center vertical-align-top' : 'mobile-table-row'}>
                        <div className={!isMobile ? 'visually-hidden' : 'mobile-table-name'}> <SimpleText textKey="StockTransactions_Quantity" /></div>
                        <div className="mobile-table-value">
                          {quantity}
                        </div>
                        <div>{uOM}
                        </div>
                      </td>
                      <td className={!isMobile ? 'table-text-center vertical-align-top' : 'mobile-table-row'}>
                        <div className={!isMobile ? 'visually-hidden' : 'mobile-table-name'}><SimpleText textKey="StockTransactions_TransactionBy" /></div>
                        <div className="mobile-table-value">
                          {transactionBy}
                        </div>
                      </td>
                      <td className={!isMobile ? 'table-text-center vertical-align-top' : 'mobile-table-row'}>
                        <div className={!isMobile ? 'visually-hidden' : 'mobile-table-name'}><SimpleText textKey="StockTransactions_DateTime" /></div>
                        <div className="mobile-table-value">
                          {toLocaleDate(createdDate, cultureName)} {toLocaleTime(createdDate, cultureName)}
                        </div>
                      </td>
                      <td className={!isMobile ? 'td-comment vertical-align-top' : 'mobile-table-row td-comment'}>
                        <div className={!isMobile ? 'visually-hidden' : 'mobile-table-name'}> <SimpleText textKey="StockTransactions_Comment" /></div>
                        <div className="mobile-table-value mb-word-break">
                          {comment}
                          {isReduceStock && isReversed && <div> {reverseReason}  </div>}
                          {isReduceStock && isReversed && <div><SimpleText textKey="StockTransactions_ReverseReason" />  {toLocaleDate(reversedDate, cultureName)} </div>}
                        </div>
                      </td>
                      <td className={!isMobile ? 'vertical-align-top' : 'mobile-table-row'}>
                        <div className={!isMobile ? 'visually-hidden' : 'mobile-table-name'}> &nbsp;</div>
                        <div className="mobile-table-value">
                          <ReverseReductionConfirmationModal transactionId={id} trackingId={trackingId} isReduceStock={isReduceStock} isReversed={isReversed} stockTransactionRoute={stockTransactionRoute} isExpiredForReversal={isExpiredForReversal} />
                        </div>
                      </td>
                    </tr>
                  </>
                )
                )}
              </tbody>
            </table>
            <LinksPaging
              totalCount={totalCount}
              pageSize={pageSize}
              onPageChange={onPageChange}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default connect(
  ({
    stockTransactions: {
      listItems: { items, totalCount },
      filter,
      page,
      status,
    },
    localization,
  }) => ({
    items,
    totalCount,
    filter,
    page,
    status,
    culture:
      localization.currentLanguage && localization.currentLanguage.cultureName,
  }),
)(ListItems)

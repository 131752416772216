export const STOCK_TRACKINGS_REQUESTED = 'STOCK_TRACKINGS_REQUESTED';
export const loadstockTrackings = options => ({
  type: STOCK_TRACKINGS_REQUESTED,
  payload: { options },
});

export const STOCK_TRACKINGS_RECEIVED = 'STOCK_TRACKINGS_RECEIVED';
export const stockTrackingsReceived = ({ list }) => ({
  type: STOCK_TRACKINGS_RECEIVED,
  payload: list,
});

export const STOCK_TRACKING_ADD = 'STOCK_TRACKING_ADD';
export const addStockTrackingProduct = input => ({
  type: STOCK_TRACKING_ADD,
  payload: { input },
});

export const STOCK_TRACKING_ADDED = 'STOCK_TRACKING_ADDED';
export const stockTrackingsAddingResultReceived = savingResult => ({
  type: STOCK_TRACKING_ADDED,
  payload: { savingResult },
});

export const STOCK_TRACKING_DELETE_REQUESTED =
  'STOCK_TRACKING_DELETE_REQUESTED';
export const deleteStockTracking = id => ({
  type: STOCK_TRACKING_DELETE_REQUESTED,
  payload: { id },
});

export const STOCK_TRACKING_DELETED = 'STOCK_TRACKING_DELETED';
export const stockTrackingsDeleted = id => ({
  type: STOCK_TRACKING_DELETED,
  payload: id,
});

export const STOCK_TRACKING_UPDATE_REQUESTED =
  'STOCK_TRACKING_UPDATE_REQUESTED';
export const updateStockTracking = input => ({
  type: STOCK_TRACKING_UPDATE_REQUESTED,
  payload: { input },
});

export const STOCK_TRACKING_UPDATED = 'STOCK_TRACKING_UPDATED';
export const stockTrackingUpdated = input => ({
  type: STOCK_TRACKING_UPDATED,
  payload: input,
});

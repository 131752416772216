import {
  STOCK_TRACKING_ADDED,
  STOCK_TRACKING_DELETED,
  STOCK_TRACKINGS_RECEIVED,
  STOCK_TRACKING_DELETE_REQUESTED,
  STOCK_TRACKING_UPDATED,
  STOCK_TRACKINGS_REQUESTED,
} from 'behavior/pages/stockTrackings/actions';
import { createReducer } from 'utils/redux';
import { NAVIGATED } from 'behavior/routing';
import { UpdateType } from './constants';
import { PageComponentNames } from '../componentNames';

const initialState = {
  list: { items:null, totalCount: 0, categories: null },
  filter: null,
  page: 0,
  status: null,
  savingResult: null,
};

export default createReducer(initialState, {
  [STOCK_TRACKING_ADDED]: onStockTrackingAddResultReceived,
  [STOCK_TRACKING_DELETED]: onStockTrackingDeleted,
  [STOCK_TRACKINGS_RECEIVED]: onStockTrackingsReceived,
  [STOCK_TRACKING_DELETE_REQUESTED]: onStockTrackingRemoveRequested,
  [NAVIGATED]: onNavigated,
  [STOCK_TRACKING_UPDATED]: onStockTrackingUpdated,
  [STOCK_TRACKINGS_REQUESTED]: onStockTrackingRequested,
});

function onStockTrackingRequested(state, action){
  const { index } = action.payload.options.page;

  return { ...state, page: index };
}

function onStockTrackingAddResultReceived(state, action){
  const { savingResult } = action.payload;
  const { failureText } = savingResult;

  return { ...state, savingResult, status: {
    type: failureText === null ? UpdateType.Added : UpdateType.Duplicate,
  } };
}

function onStockTrackingDeleted(state, action) {
  return { ...state, status: { type: UpdateType.Removed } };
}

function onStockTrackingsReceived(state, action) {
  return {
    ...state,
    list: action.payload,
  };
}

function onStockTrackingRemoveRequested(state){
return { ...state, status: { type: UpdateType.Removing } };
}

function onStockTrackingUpdated(state, action)
{
  const { trackingId, lowStock, optimalStock } = action.payload;
  return  { ...state, list: { ...state.list ,items: state.list.items.map(i=>i.id === trackingId ? { ...i, lowStock, optimalStock } : i) } };
}

function onNavigated(state, action) {
  if (action.payload.page && action.payload.page.component === PageComponentNames.StockTrackings)
    return state;

  return initialState;
}
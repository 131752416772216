import type { ProductInfo, AddProductFailureReason } from './types';

export const BAR_CODE_SCANNER_PRODUCT_ADDED = 'BAR_CODE_SCANNER/PRODUCT_ADDED' as const;
export const addProduct = (code: string) => ({
  type: BAR_CODE_SCANNER_PRODUCT_ADDED,
  payload: { code },
});

export const BAR_CODE_SCANNER_PRODUCT_INFO_RECEIVED = 'BAR_CODE_SCANNER/PRODUCT_INFO_RECEIVED' as const;
export const receiveProductInfo = (productInfo: ProductInfo) => ({
  type: BAR_CODE_SCANNER_PRODUCT_INFO_RECEIVED,
  payload: { productInfo },
});

export const BAR_CODE_SCANNER_FAILURE_REASON_RECEIVED = 'BAR_CODE_SCANNER/FAILURE_REASON_RECEIVED' as const;
export const receiveFailureReason = (reason: AddProductFailureReason) => ({
  type: BAR_CODE_SCANNER_FAILURE_REASON_RECEIVED,
  payload: { reason },
});

export const BAR_CODE_SCANNER_RESULT_CLEARED = 'BAR_CODE_SCANNER/RESULT_CLEARED' as const;
export const clearResult = () => ({ type: BAR_CODE_SCANNER_RESULT_CLEARED });

export type BarCodeScannerAction = ReturnType<
  | typeof addProduct
  | typeof receiveProductInfo
  | typeof receiveFailureReason
  | typeof clearResult
>;

import { useMemo, useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText, makeRichText } from 'utils/render';
import { ProductSelector } from 'components/objects/productSelectorTracking';
import { EVENT_SOURCES } from 'behavior/analytics';
import { useHandlerLockerInPreview } from 'components/objects/preview';
import { addStockTrackingProduct } from 'behavior/pages/stockTrackings';
import PropTypes from 'prop-types';
import { toasts } from 'behavior/toasts';
import { SimpleText, RichText } from 'components/sanaText';
import { useLoadData } from '../hook';
import {  clearProduct } from 'behavior/productSelector';

const AddTracking = ({
  savingResult,
  filter,
  addStockTrackingProduct,
  preSelectedProduct,
  stockTrackingRoute,
  clearProduct,
}) => {
  const texts = useQuickOrderTexts();

  const [clearResult, setClearResult] = useState(false);
  const [productAddDisabled, setProductAddDisabled] = useState(false);
  const loadData = useLoadData(stockTrackingRoute);
  const onProductAdd = useCallback(
    product => {
      if (productAddDisabled) {
        return;
      }

      addStockTrackingProduct(product);
      setProductAddDisabled(true);
    },
    [productAddDisabled],
  );

  const onProductSelected = useCallback(() => {
    setClearResult(false);
  }, []);

  useEffect(() => {
    if (!savingResult) {
      return;
    }

    const { failureText } = savingResult;

    if (failureText === undefined) {
      return;
    }

    setProductAddDisabled(false);

    if (failureText === null) {
      toasts.success(texts.stockTrackingSuccessfullyAdded);      
      clearProduct();
      setClearResult(true);
      loadData(0, filter);
    } else {
      toasts.error(failureText);
    }
  }, [savingResult]);

  return (
    <>
      <div className="StockTracking-search-box">
        <h2 className="StockTracking-main-heading"><SimpleText textKey="StockTracking_Create_Heading" /></h2>
      <RichText textKey="StockTracking_Create_Description" />
      <ProductSelector
        preSelectedProduct={preSelectedProduct}
        texts={texts}
        isStockTracking
        onProductAdd={useHandlerLockerInPreview(onProductAdd)}
        productAddDisabled={productAddDisabled}
        productClickTrackingSource={EVENT_SOURCES.stockTracking}
        clearResult = {clearResult}
        onProductSelected = {onProductSelected}
      />
        </div>
    </>
  );
};

AddTracking.propTypes = {
  savingResult: PropTypes.shape({
    failureTest: PropTypes.string,
  }),
  addStockTrackingProduct: PropTypes.func.isRequired,
  stockTrackingRoute: PropTypes.object.isRequired,
  clearProduct: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  addStockTrackingProduct, clearProduct,
})(AddTracking);

function mapStateToProps({
  stockTrackings: { savingResult },
  routing: { routeData },
}) {
  const params = routeData ? routeData.params : undefined;
  const filter = params ? params.filter : undefined;
  const product = params ? params.product : undefined;
  return { savingResult, filter, preSelectedProduct: product };
}

function useQuickOrderTexts() {
  const texts = useSanaTexts([
    'Search_SearchPrefix',
    'StockTrackings_StartTracking',
    'Loading',
    'QuickOrder_ProductNotFound',
    'ProductCannotBeOrderedAtThisTime',
    'StockTracking_SuccessfullyAdded',
  ]).texts;

  return useMemo(() => {
    const [
      searchBoxPlaceholder,
      addBtn,
      loadingText,
      productNotFoundText,
      productCannotBeOrderedMessage,
      stockTrackingSuccessfullyAdded,
    ] = texts;

    return {
      searchBoxPlaceholder: makeSimpleText(searchBoxPlaceholder),
      addBtn: makeSimpleText(addBtn),
      loadingText: makeSimpleText(loadingText),
      productNotFoundText: makeRichText(productNotFoundText),
      productCannotBeOrderedMessage: makeRichText(
        productCannotBeOrderedMessage,
      ),
      stockTrackingSuccessfullyAdded: makeSimpleText(
        stockTrackingSuccessfullyAdded,
      ),
    };
  }, [texts]);
}

exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "#content.limited{margin:auto 0}#content.limited .LimitedAccess_container{max-width:682px}#content.limited .LimitedAccess_logo{display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;max-width:none;padding-bottom:1.25em}#content.limited .LimitedAccess_logo>a{pointer-events:none}#content.limited .LimitedAccess_logo img{display:inline-block}.LimitedAccess_title{margin:.25em 0 .13em;line-height:1em}.LimitedAccess_message{margin:1.9em 0;font-size:1.08em;line-height:1.7em}", ""]);

// exports
exports.locals = {
	"container": "LimitedAccess_container",
	"logo": "LimitedAccess_logo",
	"title": "LimitedAccess_title",
	"message": "LimitedAccess_message"
};
import { useCallback, useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import QuantityContext from './QuantityContext';
import { useProductContext } from './hooks';
import { addProducts } from 'behavior/basket';
import { generateKey } from 'utils/helpers';
import { createQuantityModel, adjustForSalesAgreement } from 'components/primitives/product';
import { useModal } from 'components/objects/modals';
import ConfirmationModal from 'components/objects/profile/salesAgreements/ConfirmationModal';

const OrderBoxForm = ({
  children,
  modifiedDate,
  updatedById,
  isSelected,
  agreementId,
  dispatch,
  isAgreementAppliedToLines,
  appliedSalesAgreementId,
  agreementLine,
}) => {
  const [componentId] = useState(generateKey);
  const [disabled, setDisabled] = useState(false);
  const { product, variantId } = useProductContext();
  const { opened, show, hide } = useModal();

  const add = (line, componentId, agreementId) => dispatch(addProducts(line, componentId, agreementId));

  const updateQuantity = useCallback(quantity => {
    updateContext(prev => {
      if (quantity === prev.quantity)
        return prev;

      return { ...prev, quantity };
    });
  }, []);

  const {
    id: lineId,
    isMaxEnforced,
    quantities: {
      remaining: quantityRemaining,
    },
  } = agreementLine;

  const uomId = agreementLine.uom?.id;

  const [context, updateContext] = useState(() => {
    const quantityModel = generateQuantityModel(product, uomId, isMaxEnforced, quantityRemaining);
    return {
      uomId,
      productId: product.id,
      quantity: { value: quantityModel.initial, isValid: true },
      quantityModel,
      updateQuantity,
    };
  });

  const addToBasket = () => {
    if (opened)
      hide();

    const { quantity } = context;
    if (quantity && quantity.isValid) {
      setDisabled(true);

      const line = {
        productId: product.id,
        variantId,
        uomId,
        quantity: quantity.value,
        salesAgreementLineId: lineId,
      };

      add([line], componentId, isSelected ? null : agreementId);
    }
  };

  const showModal = useCallback(e => {
    e.preventDefault();
    if (disabled)
      return;

    if (isAgreementAppliedToLines && appliedSalesAgreementId !== agreementId)
      show();
    else
      addToBasket();
  }, [product, uomId, variantId, context, disabled, isAgreementAppliedToLines, appliedSalesAgreementId]);

  useEffect(() => {
    if (updatedById === componentId)
      setDisabled(false);
  }, [modifiedDate, updatedById]);

  useEffect(() => {
    updateContext(prev => {
      if (prev.productId === product.id && prev.uomId === uomId)
        return prev;

      const quantityModel = generateQuantityModel(product, uomId, isMaxEnforced, quantityRemaining);
      return {
        ...prev,
        uomId,
        productId: product.id,
        quantity: { value: quantityModel.initial, isValid: true },
        quantityModel,
      };
    });
  }, [product.id, uomId]);

  return (
    <form disabled={disabled} onSubmit={showModal}>
      <QuantityContext.Provider value={context}>
        {children}
      </QuantityContext.Provider>
      <ConfirmationModal opened={opened} hide={hide} onConfirm={addToBasket} />
    </form>
  );
};

OrderBoxForm.propTypes = {
  children: PropTypes.node.isRequired,
  modifiedDate: PropTypes.number,
  updatedById: PropTypes.string,
  agreementId: PropTypes.string.isRequired,
  dispatch: PropTypes.func,
  isSelected: PropTypes.bool,
  isAgreementAppliedToLines: PropTypes.bool,
  appliedSalesAgreementId: PropTypes.string,
  agreementLine: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isMaxEnforced: PropTypes.bool,
    uom: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    quantities: PropTypes.shape({
      remaining: PropTypes.number,
    }).isRequired,
  }).isRequired,
};

export default connect(({ basket, page }) => ({
  modifiedDate: basket.modifiedDate,
  updatedById: basket.updated.updaterId,
  agreementId: page.agreement.id,
  isSelected: basket.salesAgreementInfo?.id === page.agreement.id,
  appliedSalesAgreementId: basket.salesAgreementInfo?.id,
  isAgreementAppliedToLines: basket.salesAgreementInfo?.isAppliedToLines,
}))(OrderBoxForm);

function generateQuantityModel(product, uomId, isMaxEnforced, quantityRemaining) {
  const uom = product.uoms && product.uoms.find(u => u.id === uomId);
  const quantityModel = createQuantityModel(uom, false);
  return adjustForSalesAgreement(quantityModel, isMaxEnforced, quantityRemaining);
}

import { useRef, useEffect } from 'react';
import styles from './StockTrackingsList.module.scss';
import { RichText, SimpleText } from 'components/sanaText';
import tableStyles from 'components/primitives/table/Table.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Item from './Item';
import { UpdateType } from 'behavior/pages/stockTrackings';
import Fiter from './Filter';
import LinksPaging from './LinksPaging';
import {
  useHandlerLockerInPreview,
} from 'components/objects/preview';
import { useLoadData, pageSize } from '../hook';
import { loadstockTrackings } from 'behavior/pages/stockTrackings';

const List = ({
  items,
  totalCount,
  categories,
  page,
  filter,
  culture,
  status,
  stockTrackingRoute,
  loadstockTrackings,
}) => {
  if (!items) 
return null;

  const filterRef = useRef();
  const pageRef = useRef();

  const loadData = useLoadData(stockTrackingRoute);

  const applyFilter = useHandlerLockerInPreview(filter => {
    filterRef.current = filter;    
    //loadData(page, filterRef.current);
    const options = { ...filter, page: { index: 0, size:20 } };
    loadstockTrackings(options);
  });

  const onPageChange = page => {
    pageRef.current = page;
    loadData(page, filterRef.current);
  };

  useEffect(() => {
    if (status != null) {
      const { type } = status;
      if (type && type === UpdateType.Removed) {
        if((pageRef.current+1)*pageSize - totalCount === (pageSize-1))
          pageRef.current = pageRef.current-1;

        loadData(pageRef.current, filterRef.current);
      }
    }
  }, [status]);

  return (
    <>
      <div id="stockTrackingList" className={styles.listWrapper}>
        <h2 className="stock-tracking-list-Title"><SimpleText textKey="MyAccount_StockTracking_Trackings" /></h2>       
        <Fiter
          categories={categories}
          applyFilter={applyFilter}
          filter={filterRef.current}
        />
        {totalCount === 0 && (
          <div className="msg-block">
            <RichText textKey="MyAccount_StockTracking_Trackings" />
          </div>
        )}
        {totalCount > 0 && (
          <div className={styles.tableWrapper}>
            <table className={`${tableStyles.table} stock-tracking-table currently-track-product-table`}>
              <thead>
                <tr>
                  <th>
                    <SimpleText textKey="StockTrackings_ItemNo" />
                  </th>
                  <th>
                    <SimpleText textKey="StockTrackings_Title" />
                  </th>
                  <th className={`${styles.textCenter} `}>
                    <SimpleText textKey="StockTrackings_List_LowStock" />
                  </th>
                  <th className={`${styles.textCenter} `}>
                    <SimpleText textKey="StockTrackings_List_OptimalStock" />
                  </th>
                  <th className={`${styles.textCenter} `}>
                    <SimpleText textKey="StockTrackings_List_OnHandStock" />
                  </th>
                  <th className={`${styles.textCenter} `}>
                    <SimpleText textKey="StockTrackings_List_Status" />
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {items.map(tracking => (
                  <Item
                    key={tracking.id}
                    tracking={tracking}
                    culture={culture}
                  />
                ))}
              </tbody>
            </table>
            <LinksPaging
              totalCount={totalCount}
              pageSize={pageSize}
              onPageChange={onPageChange}
              pageIndex={page}
            />
          </div>
        )}

      </div>
    </>
  );
};

List.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      productId: PropTypes.string.isRequired,
    }),
  ),
  status: PropTypes.object,
  culture: PropTypes.string,
  totalCount: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  filter: PropTypes.object,
  stockTrackingRoute: PropTypes.object.isRequired,
  loadstockTrackings: PropTypes.func.isRequired,
};

export default connect(
  ({
    stockTrackings: {
      list: { items, totalCount, categories },
      filter,
      page,
      status,
    },
    localization,
  }) => ({
    items,
    totalCount,
    categories,
    filter,
    page,
    status,
    culture:
      localization.currentLanguage && localization.currentLanguage.cultureName,
  }),{ loadstockTrackings },
)(List);

import styles from 'components/objects/product/Details.module.scss';
import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'components/primitives/grid';
import { Placeholder } from 'components/primitives/placeholders';
import { renderHTML, makeSimpleText } from 'utils/render';
import { useSanaTexts, SimpleText } from 'components/sanaText';
import { getCurrency, getFormatPrice, formatAsPercentage, getCurrencyAndNumber } from 'utils/format';
import { usePriceModel } from 'utils/product';
import { useCurrencyInfo } from 'utils/hooks';
import { formatText } from '../../../utils/formatting';
import { useProductContext } from 'components/objects/product/hooks';

const BaseUomPrice = ({ baseUomPrice ,baseUoMDescription}) => {
  const currencyInfo = useCurrencyInfo();
  const formatAsPrice = currencyInfo && getFormatPrice(currencyInfo);
  const [symbol, price] = getCurrencyAndNumber(baseUomPrice, currencyInfo);
  const { product } = useProductContext();
  const formattedPrice = useMemo(() => {
    if (!currencyInfo)
      return null;

    return renderHTML(baseUomPriceformat(formatAsPrice(baseUomPrice), currencyInfo));
  }, [baseUomPrice, currencyInfo]);

  if (formattedPrice == null)
    return <Placeholder className="price-block product-price-placeholder" />;

  const currencySymbol = getCurrency(currencyInfo.id, currencyInfo.cultureName);

  return (<>
    <span className={styles.pricePerBaseUom}>
      <SimpleText textKey="BaseUoM_PDP" formatWith={[price, currencySymbol, baseUoMDescription]} />
    </span>

  </>);
};

BaseUomPrice.propTypes = {
  baseUomPrice: PropTypes.number,
  baseUoMDescription: PropTypes.string,
};

export default memo(props => {
  return <BaseUomPrice {...props} />;
});

function baseUomPriceformat(baseUomPrice, currencyInfo) {
  if (currencyInfo.decimalDigits === 0)
    return baseUomPrice;
  return baseUomPrice.replace(/ /, '$1');
}

import { memo, FunctionComponent } from 'react';
import { HeadingStyle } from './constants';

type Props = {
  headingStyle: HeadingStyle;
  color?: string;
};

const Heading: FunctionComponent<Props> = ({ headingStyle, color, children }) => {
  const colorStyle = color ? { color } : {};

  switch (headingStyle) {
    case HeadingStyle.DisplayHeading1:
      return <div className="dh1" style={colorStyle}>{children}</div>;
    case HeadingStyle.DisplayHeading2:
      return <div className="dh2" style={colorStyle}>{children}</div>;
    case HeadingStyle.DisplayHeading3:
      return <div className="dh3" style={colorStyle}>{children}</div>;
    case HeadingStyle.Heading1:
      return <h1 style={colorStyle}>{children}</h1>;
    case HeadingStyle.Heading2:
      return <h2 style={colorStyle}>{children}</h2>;
    case HeadingStyle.Heading3:
      return <h3 style={colorStyle}>{children}</h3>;
    case HeadingStyle.Heading4:
      return <h4 style={colorStyle}>{children}</h4>;
    case HeadingStyle.Heading5:
      return <h5 style={colorStyle}>{children}</h5>;
    default:
      return null;
  }
};

export default memo(Heading);
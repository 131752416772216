export const STOCK_TRANSACTION_REQUESTED = 'STOCK_TRANSACTION_REQUESTED';
export const loadstockTransaction = options => ({
  type: STOCK_TRANSACTION_REQUESTED,
  payload: { options },
});

export const STOCK_TRANSACTION_RECEIVED = 'STOCK_TRANSACTION_RECEIVED';
export const stockTransactionReceived = ({ listItems, productFilter }) => ({
  type: STOCK_TRANSACTION_RECEIVED,
  payload: listItems, productFilter,
});

export const REVERSE_STOCK_TRNSACTION_REQUESTED = 'REVERSE_STOCK_TRNSACTION_REQUESTED';
export const reverseReduction = input => ({
  type: REVERSE_STOCK_TRNSACTION_REQUESTED,
  payload: { input },
});

export const STOCK_TRANSACTION_UPDATED = 'STOCK_TRANSACTION_UPDATED';
export const stockTransactionUpdated = input => ({
  type: STOCK_TRANSACTION_UPDATED,
  payload: { input },
});
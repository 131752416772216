import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { rewriteTo } from 'behavior/routing';

export const pageSize = 50;

export const useLoadData = route => {
  const dispatch = useDispatch();
  return useCallback(
    (pageIndex, filterDate) => {
      const filter = filterDate  && filterDate.filter  ?
        {
          'filter': {
            itemNo: filterDate.filter.itemNo, variantId: filterDate.filter.variantId,
            shopAccountName: filterDate.filter.shopAccountName, transactionType: filterDate.filter.transactionType,
            fromDate: filterDate.filter.fromDate, toDate: filterDate.filter.toDate }} :
        { 'filter': { itemNo: '', variantId: ' ', shopAccountName: '', transactionType: '', fromDate: null, toDate: null } };
      const routeData = {
        ...route,
        params: { pageIndex, filter },
      };
      dispatch(rewriteTo(routeData, true));
    },
    [route],
  );
};
import documentEpic from './epic.document';
import reorderEpic from './epic.reorder';
import quoteEpic from './epic.quote';
import editEpic from './epic.edit';
import shipmentEpic from './epic.shippment';
import orderAuthorizationEpic from './epic.authorization';

import { combineEpics } from 'redux-observable';

export default combineEpics(documentEpic, reorderEpic, quoteEpic, shipmentEpic,editEpic, orderAuthorizationEpic);

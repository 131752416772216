import {
  stockTransactionQuery,
  reverseStockTransactionMutation
} from './queries';
import {
  STOCK_TRANSACTION_REQUESTED,
  REVERSE_STOCK_TRNSACTION_REQUESTED,
  stockTransactionReceived,
  stockTransactionUpdated,
} from './actions';
import { combineEpics, ofType } from 'redux-observable';
import { mergeMap, startWith, switchMap } from 'rxjs/operators';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { retryWithToast } from 'behavior/errorHandling';

const loadStockTransactionEpic = (actions$, _state$, { api }) => {
  return actions$.pipe(
    ofType(STOCK_TRANSACTION_REQUESTED),
    switchMap(action => api.graphApi(stockTransactionQuery, action.payload).pipe(
      mergeMap(listItems, productFilter => [stockTransactionReceived(listItems, productFilter), unsetLoadingIndicator()],
      ),
    )),
    startWith(setLoadingIndicator()),
  );
};

const reverseStockTransactionEpic = (actions$, _state$, { api, logger }) => {
  return actions$.pipe(
    ofType(REVERSE_STOCK_TRNSACTION_REQUESTED),
    mergeMap(action => api.graphApi(reverseStockTransactionMutation, action.payload).pipe(
      mergeMap(() => [stockTransactionUpdated(action.payload.input), unsetLoadingIndicator()]),
      retryWithToast(actions$, logger),
      startWith(setLoadingIndicator()),
    ),
    ));
};

export default combineEpics(
  loadStockTransactionEpic,
  reverseStockTransactionEpic,
);

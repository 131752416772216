import styles from '../SalesAgreements.module.scss';
import { memo } from 'react';
import { useProductContext } from '../hooks';
import VariantDropdownRow from './VariantDropdownRow';
import { useVariantDropdownsControl } from 'components/objects/product/variantDropdowns';

const VariantDropdowns = () => {
  const { product, updateContext } = useProductContext();

  const [
    filteredVariantComponentGroups,
    selection,
    onSelectionChange,
  ] = useVariantDropdownsControl(
    product.variantComponentGroups,
    (variantId, variantComponents) => updateContext({ variantId, variantComponents }),
    product.id,
  );

  const shouldRender = product
    && product.variantComponentGroups
    && !!product.variantComponentGroups.length;

  if (!shouldRender)
    return null;

  return (
    <fieldset className={styles.variantDropdowns}>
      {filteredVariantComponentGroups.map(({ id: groupId, components, name: groupName }) => {
        const selectedComponent = selection[groupId];
        const value = selectedComponent.id;

        return (
          <VariantDropdownRow
            id={groupId}
            key={groupId}
            groupName={groupName}
            variantComponents={components}
            onChange={value => onSelectionChange({ ...selection, [groupId]: components.find(c => c.id === value) })}
            value={value}
          />
        );
      })}
    </fieldset>
  );
};

export default memo(VariantDropdowns);

import styles from '../../Order.module.scss';
import tableStyles from 'components/primitives/table/Table.module.scss';
import { useRef, useEffect, useState, useCallback, createRef, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'lodash';
import { resize$, useEventObservable } from 'utils/rxjs';
import { fixIncorrectScrollAnchoring } from 'utils/helpers';
import { usePrintMode } from 'utils/hooks';
import { toggleOverflowAnchorState } from 'components/primitives/transitions';
import LinesFooter from '../LinesFooter';
import ProductLine from './ProductLine';
import ServiceLine from './ServiceLine';
import { useIsPreview } from 'components/objects/preview';
import PreviewLine from './PreviewLine';
import QuantityContext from './QuantityContext';
import { SanaButton } from 'components/primitives/buttons';
import { addOnHandStock } from 'behavior/pages/document';
import { useDispatch } from 'react-redux';
import { SimpleText } from 'components/sanaText';
import { useSanaTexts } from 'components/sanaText';

import { toasts } from 'behavior/toasts';
import { makeSimpleText } from 'utils/render';
import { useHasAbilities } from 'components/objects/user'; // [209289] TE: Remove discount info
import { AbilityTo } from 'behavior/user/constants'; // [209289] TE: Remove discount info

const DesktopTable = ({
  lines,
  loadLines,
  options,
  itemsFolded,
  initialLinesLoaded,
  lineTexts,
  cancelled,
  agreementId,
  orderId,
  documentId,
  internalDocumentId,
}) => {
  const {
    showUOM,
    isB2CCustomer,
    showPrices,
    showDiscounts,
    showStock,
    showExtraColumns,
    showShippingStatus,
    showSurcharges,
    isShipment,
  } = options;

  const customTexts = useCustomText();

  let transactionComment = createRef();
  const dispatch = useDispatch();
  const isPrintMode = usePrintMode();
  const handleOnClick = useCallback(() => {
    const list = [];

    for (const [productId, { quantity }] of quantities) {
      if (!(quantity && quantity.isValid)) {
        document.getElementById(productId + '_' + 'qty').focus();
        return;
      }
      if (quantity && quantity.value > 0) {
        list.push({
          productId: productId,
          quantity: quantity.value,
        });
      }
    }
    
    const data = {
      referenceId: orderMessage + orderId + ' ' + shipmentMessage + documentId,
      quantities: list,
      shipmentId: internalDocumentId,
      comment: transactionComment.current.value,
    };

    dispatch(addOnHandStock(data));
    toasts.success(customTexts.stockAddtoOnHandSuccess);
    window.location.reload(false);
  });

  const {
    quantities,
  } = useContext(QuantityContext);

  const linesContainerRef = useRef();
  const tableRef = useRef();
  const overflowAnchorTimeoutRef = useRef();
  const [scrollable, setScrollable] = useState(false);
  const isPreview = useIsPreview();

  const allLinesAreNotTracked = lines && lines.itemLines && lines.itemLines.length && lines.itemLines.some(line => (line.isTracked || (line.sublines && line.sublines.length && line.sublines.some(subLine => subLine.isTracked))));
  const isHidden = lines && lines.itemLines && lines.itemLines.length && lines.itemLines.every(line => (line.quantityShippedRemained <= 0));

  const [placeholder, orderMessage, shipmentMessage] = useSanaTexts([
    'Shipment_details_comment_Placeholder',
    'Shipment_Order',
    'Shipment_Shipment',
  ]).texts;

  const discountAbility = useHasAbilities(AbilityTo.DiscountAbility)[0]; // [209289] TE: Remove discount info

  useEventObservable(resize$, checkTableScrollPos);
  useEffect(() => {
    const handleTableScroll = throttle(checkTableScrollPos, 250);
    tableRef.current.addEventListener('scroll', handleTableScroll);

    return () => {
      tableRef.current.removeEventListener('scroll', handleTableScroll);
      toggleOverflowAnchorState();
      clearTimeout(overflowAnchorTimeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (!lines.itemLines.length && !itemsFolded && !lineTexts.length)
      return;

    requestAnimationFrame(checkTableScrollPos);
    overflowAnchorTimeoutRef.current = setTimeout(toggleOverflowAnchorState, 50);
    fixIncorrectScrollAnchoring();
  }, [lines.itemLines, itemsFolded, lineTexts]);

  return (
    <div className={scrollable ? undefined : styles.noScroll} ref={linesContainerRef}>
      <div className={styles.linesTableWrapper} ref={tableRef} tabIndex={scrollable ? '0' : null}>
        <table className={`${tableStyles.table} ${styles.linesTable}`}>
          <thead>
            <tr>
              <th className="visually-hidden">{lineTexts.lineStatus}</th>
              <th>{lineTexts.id}</th>
              <th>{lineTexts.title}</th>
              {showShippingStatus && <th>{lineTexts.lineShippingStatus}</th>}
              {!isB2CCustomer && <th><span title={lineTexts.shipmentDateTitle || null}>{lineTexts.shipmentDate}</span></th>}
              {showPrices && <th className={styles.linePrice}>{lineTexts.price}</th>}
              {showPrices && showSurcharges && <th className={`${styles.linePrice} mtz-notice-lines`}>{lineTexts.surcharge}</th>}
              {showDiscounts && discountAbility && <th className={styles.lineDiscount}>{lineTexts.discount}</th>} {/*[209289] TE: Remove discount info*/}
              <th className={styles.lineQty}>{isB2CCustomer ? lineTexts.quantity : <span title={lineTexts.quantity || null}>{lineTexts.qty}</span>}</th>
              {showUOM && <th className={styles.lineUom}><span title={lineTexts.unitOfMeasure || null}>{lineTexts.uom}</span></th>}
              {showStock && <th className={styles.lineStock}>{lineTexts.stock}</th>}
              {showExtraColumns && (
                <th className={styles.lineQtyShipped}>
                  <span title={lineTexts.quantityShipped || null}>
                    {lineTexts.linesShippedQuantity}
                  </span>
                </th>
              )}
              {showExtraColumns && (
                <th className={styles.lineQtyInvoiced}>
                  <span title={lineTexts.quantityInvoiced || null}>
                    {lineTexts.quantityInvoicedTitle}
                  </span>
                </th>
              )}
              {!isB2CCustomer &&
                <th className={styles.lineQtyOutstanding}>
                  <span title={lineTexts.outstandingQuantityAlt || null}>
                    {lineTexts.outstandingQuantity}
                  </span>
                </th>
              }
              {showPrices && <th className={styles.lineTotal}>{lineTexts.totals}</th>}
              {!isPrintMode && isShipment && !isHidden && <th className="text-center"><SimpleText textKey="RecivedAmount" /></th>}
            </tr>
          </thead>
          {!isPreview
            ? !itemsFolded && (
              <tbody>
                {lines.itemLines && lines.itemLines.map((item, index) => (
                  <ProductLine
                    key={index}
                    line={item}
                    options={options}
                    cancelled={cancelled || item.isCancelled}
                    lineTexts={lineTexts}
                    agreementId={agreementId}
                  />
                ))}
                {lines.serviceLines && lines.serviceLines.map((item, index) => (
                  <ServiceLine key={index} line={item} options={options} />
                ))}
              </tbody>
            )
            : (
              <tbody>
                <PreviewLine options={options} />
                <PreviewLine options={options} />
                <PreviewLine options={options} />
              </tbody>
            )
          }
        </table>
      </div>
      {!initialLinesLoaded && !isPrintMode &&
        <LinesFooter
          lines={lines}
          itemsFolded={itemsFolded}
          loadLines={loadLines}
          lineTexts={lineTexts}
        />
      }
      {allLinesAreNotTracked && !isPrintMode && !isHidden &&
        <div className="pull-right on-hand-stock-block">
          <textarea name="comment" id="transactionComment" className="form-control" ref={transactionComment} maxLength="150" minLength="20" placeholder={placeholder} style={{ marginBottom: '20px' }} />
          <SanaButton
            textKey="AddToOnHandStock"
            onClick={handleOnClick}
          />
        </div>
      }
    </div>
  );

  function checkTableScrollPos() {
    const tableWrapper = tableRef.current;
    const table = tableWrapper && tableWrapper.querySelector('table');
    if (!table)
      return;

    setScrollable(tableWrapper.scrollWidth !== tableWrapper.offsetWidth);
  }

  function useCustomText() {
    const texts = useSanaTexts([
      'Shipment_AddToHandFail',
      'Shipment_AddToHandSuccess',
    ]).texts;

    return useMemo(() => {
      const [
        stockAddtoOnHandFailed,
        stockAddtoOnHandSuccess,
      ] = texts;

      return {
        stockAddtoOnHandFailed: makeSimpleText(
          stockAddtoOnHandFailed,
        ),
        stockAddtoOnHandSuccess: makeSimpleText(
          stockAddtoOnHandSuccess,
        ),
      };
    }, [texts]);
  }
};

DesktopTable.propTypes = {
  lines: PropTypes.shape({
    serviceLines: PropTypes.array,
    totalCount: PropTypes.number.isRequired,
    itemLines: PropTypes.arrayOf(PropTypes.shape({
      discountPercentage: PropTypes.number,
    })),
  }).isRequired,
  loadLines: PropTypes.func.isRequired,
  options: PropTypes.shape({
    showShippingStatus: PropTypes.bool.isRequired,
    isB2CCustomer: PropTypes.bool.isRequired,
    showPrices: PropTypes.bool.isRequired,
    showDiscounts: PropTypes.bool.isRequired,
    showSurcharges: PropTypes.bool,
    showUOM: PropTypes.bool.isRequired,
    showStock: PropTypes.bool.isRequired,
    showExtraColumns: PropTypes.bool.isRequired,
  }).isRequired,
  itemsFolded: PropTypes.bool.isRequired,
  initialLinesLoaded: PropTypes.bool.isRequired,
  lineTexts: PropTypes.object,
  cancelled: PropTypes.bool.isRequired,
  agreementId: PropTypes.string,
};

export default DesktopTable;

exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Reviews_cnt ul{margin:0 0 15px;padding:0;list-style:none}.Reviews_cnt li{margin-top:10px;padding-bottom:10px;border-bottom:1px solid;border-color:var(--tables_BorderColor,#e6e6e6)}.Reviews_cnt time{font-size:var(--normalText_SmallerFontSize,12px)}.Reviews_cnt .Reviews_heading{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-flex-wrap:wrap;flex-wrap:wrap}.Reviews_cnt .Reviews_title{margin:0}.Reviews_cnt footer,.Reviews_cnt header{text-align:right}.Reviews_cnt footer button,.Reviews_cnt header button{width:100%}.Reviews_cnt header{margin-top:15px}.Reviews_rating{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;margin-right:.6em;font-size:var(--normalText_SmallerFontSize,12px)}.Reviews_write .Reviews_title{margin:.4em 0}.Reviews_write form{margin-top:1em;max-width:53.85em}.Reviews_success-title{margin-top:5px;margin-bottom:1em}.Reviews_total{line-height:1.2em}.Reviews_reviews-btn{margin-left:.75em}.Reviews_reviews-btn+.Reviews_reviews-btn{border-left:1px solid var(--borderColor,#e6e6e6);padding-left:1em}.Reviews_form:focus{outline:1px dotted var(--normalText_FontColor,grey)}.Reviews_description-message{color:var(--regularTitle_FontColor,#333);line-height:1.2;margin-top:.6em}.Reviews_description-message .Reviews_icon{margin-right:1.2em}@media (min-width:var(--breakpoints_Small,600)px),print{.Reviews_cnt footer button,.Reviews_cnt header button{width:auto}.Reviews_cnt .Reviews_header{float:right;max-width:40%;margin-top:0}.Reviews_cnt footer{display:-webkit-flex;display:flex;-webkit-justify-content:flex-end;justify-content:flex-end}}.Reviews_btn-add,.Reviews_description{\n  /*!*/}", ""]);

// exports
exports.locals = {
	"cnt": "Reviews_cnt",
	"heading": "Reviews_heading",
	"title": "Reviews_title",
	"rating": "Reviews_rating",
	"write": "Reviews_write",
	"success-title": "Reviews_success-title",
	"successTitle": "Reviews_success-title",
	"total": "Reviews_total",
	"reviews-btn": "Reviews_reviews-btn",
	"reviewsBtn": "Reviews_reviews-btn",
	"form": "Reviews_form",
	"description-message": "Reviews_description-message",
	"descriptionMessage": "Reviews_description-message",
	"icon": "Reviews_icon",
	"header": "Reviews_header",
	"btn-add": "Reviews_btn-add",
	"btnAdd": "Reviews_btn-add",
	"description": "Reviews_description"
};
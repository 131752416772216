export const enum ToggleButtonStyle {
  ShowAsIconButton = 0,
  ShowAsIcon = 1,
  ShowAsIconWithText = 2,
}

export const enum OverlayActionLink {
  Hide = 0,
  Basket = 1,
  Checkout = 2,
}

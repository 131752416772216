import styles from './Lines.module.scss';
import { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { joinClasses } from 'utils/helpers';
import MediaBlock from './MediaBlock';
import ProductInfo from './ProductInfo';
import PriceNode from '../PriceNode';
import ActionLinks from './ActionLinks';
import Discount from './Discount';
import QuantityBox from './QuantityBox';
import ReadonlyQuantityBox from './ReadonlyQuantityBox';
import { routesBuilder } from 'routes';
import DefaultTemplate from './templates/Default';
import MobileTemplate from './templates/Mobile';
import ServiceLine from './ServiceLine';
import UomLabel from './UomLabel';
import BasketLinesContext from './BasketLinesContext';
import { useUpdateContext } from '../updateContext';
import ExtendedTexts from './ExtendedTexts';
import { ProductTrackingContext } from 'components/objects/analytics';
import { EVENT_SOURCES } from 'behavior/analytics';
import SalesAgreementTerm from '../salesAgreements/SalesAgreementTerm';
import { SimpleText} from 'components/sanaText';

const ProductLine = ({
  line,
  imageUrl,
  product,
  isVariantLine,
  isLastGroupLine,
  className,
  elementId,
  isSupplementary,
}) => {
  const { showImages, showPrice, showUom, currencyInfo, isMobile } = useContext(BasketLinesContext);
  const { quantities, setQuantity, deleteLines } = useUpdateContext();
  const { id, url, title: productTitle, isSpecialShipping, surchargeValue, surchargeType } = product;
  const { id: lineId, price, subTotal, discount, serviceLines, extendedTexts, isVariantSpecialShipping } = line;
  const uomId = line.uom && line.uom.id;
  const uomDescription = line.uom && line.uom.description;
  const uom = uomId && product.uoms && product.uoms.find(u => u.id === uomId);

  let displayingSurchargeValue = surchargeValue;
  let displayingSurchargeType = surchargeType;

  if ((surchargeValue === undefined || surchargeValue === null) && (line.surchargeValue !== 0 && line.surchargeValue !== undefined && line.surchargeValue !== null)) {
    displayingSurchargeType = line.surchargeType;
    displayingSurchargeValue = line.surchargeValue;
  }

  if (isVariantLine) {
    displayingSurchargeType = line.surchargeType;
    displayingSurchargeValue = line.surchargeValue;
  }

  const route = routesBuilder.forProduct.bind(null, id);

  const productActionBlock = showImages && (isMobile || !isVariantLine) && (
    <MediaBlock
      productTitle={productTitle}
      imageUrl={imageUrl}
      productUrl={url}
      route={route}
    />
  );

  const productInfo = isVariantLine
    ? (
      <>
        {showImages && !isMobile && (
          <div className={styles.variantThumbnail}>
            <MediaBlock
              productTitle={productTitle}
              imageUrl={imageUrl}
              route={route}
            />
          </div>
        )}
        <span className={styles.smallTitle}>{line.title}</span>
        <div className="variant-product-id">
          <span className="variant-product-id-label">
          <SimpleText textKey="Variant_Product_Id" /></span>
          {' '}
          <span className={`${styles.smallTitle} variant-product-id-value`}>{line.variationId}</span>
        </div>
        <ExtendedTexts texts={extendedTexts} />
      </>
    )
    : (
      <ProductInfo
        productUrl={url}
        route={route}
        productTitle={productTitle}
        productId={id}
        variantTitle={line.title}
        variantId={line.variationId}
        extendedTexts={extendedTexts}
        basketLine={line}
      />
    );

  const agreementTerm = line.availableSalesAgreementLines?.length ? (
    <SalesAgreementTerm
      productId={product.id}
      basketLine={line}
    />
  ) : null;

  const actionLinks = !isSupplementary && (
    <ActionLinks
      productUrl={isVariantLine ? null : url}
      route={isVariantLine ? null : route}
      onDelete={() => deleteLines([lineId])}
    />
  );

  const priceNode = showPrice && <PriceNode price={price} currencyInfo={currencyInfo} className={styles.priceNode} />;
  const subTotalNode = showPrice && <PriceNode price={subTotal} currencyInfo={currencyInfo} />;

  const uomData = uom || { id: uomId, description: uomDescription };
  const quantityBox = !isSupplementary
    ? (
      <QuantityBox
        initialQuantity={line.quantity}
        getCurrentQuantity={() => quantities.get(lineId)}
        uom={uomData}
        id={'qty' + lineId}
        updateQuantity={newQuantity => setQuantity(lineId, line.quantity, newQuantity)}
      />
    )
    : <ReadonlyQuantityBox quantity={line.quantity} />;
  const uomLabel = showUom && <UomLabel uom={uomData} />;

  const productDiscount = !!discount && <Discount discount={discount} currencyInfo={currencyInfo} />;

  const LineTemplate = isMobile ? MobileTemplate : DefaultTemplate;

  const trackingData = {
    product: { ...product, price, uom: line.uom },
    trackingSource: EVENT_SOURCES.shoppingCartPage,
  };

  return (
    <ProductTrackingContext.Provider value={trackingData}>
      <LineTemplate
        id={elementId}
        className={joinClasses(
          className,
          styles.productLine,
          isVariantLine && styles.variantLine,
          isLastGroupLine && (!serviceLines || !serviceLines.length) && styles.lastInGroup,
        )}
        productActionBlock={productActionBlock}
        productInfo={productInfo}
        agreementTerm={agreementTerm}
        quantityBox={quantityBox}
        uomLabel={uomLabel}
        actionLinks={actionLinks}
        priceNode={priceNode}
        productDiscount={productDiscount}
        subTotal={subTotalNode}
        isSpecialShipping={isSpecialShipping}  //[114886] 3.12. Warning on products that require additional shipping
        surchargeValue={displayingSurchargeValue} //133701 - [RFC Surcharges] 3.1. Display surcharge notices on the web shop
        surchargeType={displayingSurchargeType} //133701 - [RFC Surcharges] 3.1. Display surcharge notices on the web shop
        isVariantSpecialShipping={isVariantSpecialShipping}
      />
      {serviceLines && serviceLines.map(({ id, title, price, quantity, subTotal }, index) => (
        <ServiceLine
          key={index}
          id={id}
          title={title}
          price={price}
          quantity={quantity}
          subTotal={subTotal}
          className={joinClasses(
            styles.serviceLine,
            isVariantLine && `${styles.variantLine} ${className}`,
            isLastGroupLine && index === serviceLines.length - 1 && styles.lastInGroup,
          )}
          elementId={`${elementId}_${index}`}
        />
      ))}
    </ProductTrackingContext.Provider>
  );
};

export const productLinePropTypes = {
  line: PropTypes.shape({
    title: PropTypes.string,
    productTitle: PropTypes.string,
    quantity: PropTypes.number,
    uom: PropTypes.shape({
      id: PropTypes.string.isRequired,
      description: PropTypes.string,
    }),
    price: PropTypes.number,
    subTotal: PropTypes.number,
    discount: PropTypes.number,
    serviceLines: PropTypes.array,
    extendedTexts: PropTypes.array,
    availableSalesAgreementLines: PropTypes.array,
    isVariantSpecialShipping: PropTypes.bool
    //surchargeValue: PropTypes.number,   //133701 - [RFC Surcharges] 3.1. Display surcharge notices on the web shop
    //surchargeType: PropTypes.string     //133701 - [RFC Surcharges] 3.1. Display surcharge notices on the web shop
  }).isRequired,
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string,
    uoms: PropTypes.array,
    isSpecialShipping: PropTypes.bool  //[114886] 3.12. Warning on products that require additional shipping
    //surchargeValue: PropTypes.number,   //133701 - [RFC Surcharges] 3.1. Display surcharge notices on the web shop
    //surchargeType: PropTypes.string     //133701 - [RFC Surcharges] 3.1. Display surcharge notices on the web shop
  }).isRequired,
  isSupplementary: PropTypes.bool,
};

ProductLine.propTypes = {
  ...productLinePropTypes,
  imageUrl: PropTypes.string,
  isVariantLine: PropTypes.bool,
  isLastGroupLine: PropTypes.bool,
  className: PropTypes.string,
  elementId: PropTypes.string,
};

export default memo(ProductLine);

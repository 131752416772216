import styles from './ProductTiles.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import OrderBox from 'components/objects/productList/b2c/OrderBox';
import { ProductPrice } from 'components/primitives/product';
import { Placeholder } from 'components/primitives/placeholders';
import { ProductThumbnail } from 'components/primitives/product';
import { routesBuilder } from 'routes';
import { Link } from 'components/primitives/links';
import { ProductTrackingContext, WithProductClickTracking } from 'components/objects/analytics';
import { SanaButton } from 'components/primitives/buttons';
import { SanaLinkButton } from 'components/primitives/links';
import { RichText,useSanaTexts } from 'components/sanaText';
import { InfoAlert } from 'components/primitives/alerts'
import Tooltip from 'components/primitives/tooltip'
import { makeRichText } from 'utils/render';
const ProductTile = ({ product, noImage, itemsPerSlide }) => {
  const ref = useRef(null);
  const { id, url, title, image } = product;
  const getRouteData = routesBuilder.forProduct.bind(null, id);

  useEffect(() => {
    if (!itemsPerSlide)
      return;

    ref.current.style.width = 100 / itemsPerSlide + '%';

    return () => ref.current.style.width = '';
  }, [itemsPerSlide]);

  const { trackingSource } = useContext(ProductTrackingContext);

  const namePlaceholder = <Placeholder className={styles.namePlaceholder} />;
  const [message] =
  useSanaTexts(['SpecialShippingMessage']).texts;

  return (
    <ProductTrackingContext.Provider value={{ product, trackingSource }}>
      <article className={styles.productTile} ref={ref} aria-label={`${title} (${id})`}>
        <div className={styles.tileTop}>
          <div className={styles.productImg}>
            <WithProductClickTracking>
              {handleClick => (
                <Link onClick={handleClick} draggable="false" url={url} to={getRouteData}>
                  <ProductThumbnail draggable="false" src={image && image.small} noImageSrc={noImage} title={title} />
                </Link>
              )}
            </WithProductClickTracking>
          </div>
          <div className={styles.productDescription}>
            <WithProductClickTracking>
              {handleClick => (
                <Link onClick={handleClick} url={url} to={getRouteData} draggable="false" className={styles.productTitle}>
                  {title}
                </Link>
              )}
            </WithProductClickTracking>
            <div className={styles.productId}>
              <span className={styles.name}>
                <SimpleText textKey="General_Product_Id" placeholder={namePlaceholder} />
              </span>
              <span className={styles.value}>{id}</span>
            </div>
          </div>
        </div>
        <div className={styles.tileBottom}>
          <div className={styles.productAction}>
            <ProductPrice salesPrice={product.price} basePrice={product.listPrice} />
          </div>
         
        </div>
        {product.isSpecialShipping && (
        <Tooltip title="" body={makeRichText(message)} sign={false} className="custom_tooltip">
            <RichText textKey="SpecialShippingMessageText" />
    </Tooltip>)}
        {/* {product.isSpecialShipping && (<InfoAlert role="alert"> <RichText textKey="SpecialShippingMessage" /> </InfoAlert>)} */}
        {!product.allowedForBToBOnly && <OrderBox product={product} className={styles.orderBox} withoutBackTo/>}
          
        {product.allowedForBToBOnly && <SanaLinkButton to={routesBuilder.forBusinessRegistration} textKey="RegisterAsB2BCustomer" className={`${btnStyles.btnSmall} b2b-btn-block reg-b2b-cus-btn`}  />}
        {/*<div className={`${styles.newAccount} b2b-btn-block`} ></div>*/}
        {product.allowedForBToBOnly && (<div className="italic_text reg-b2b-desc"> <RichText textKey="OnlyAvailableForB2BCustomers" /> </div>)}
       
      </article>
    </ProductTrackingContext.Provider>
  );
};

ProductTile.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    allowedForBToBOnly: PropTypes.bool,
    image: PropTypes.shape({
      small: PropTypes.string,
    }),
  }).isRequired,
  noImage: PropTypes.string,
  itemsPerSlide: PropTypes.number,
};

export default ProductTile;
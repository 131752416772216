import { stockTransactionPageQuery } from './queries';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { PageComponentNames } from '../componentNames';
import { initSystemPageContent } from '../system';
import { stockTransactionReceived } from './actions';
import { loadSystemPageQuery } from '../system/queries';
import { generateKey } from 'utils/helpers';
export const defultSize = 50;

export default ({ params }, _state$, { api }) => {
  if (params?.previewToken) {
    return api.graphApi(loadSystemPageQuery('stockTransactions')).pipe(
      map(({ pages: { stockTransactions: page } }) => {
        if (!page)
          return null;

        page.component = PageComponentNames.StockTransactions;
        const stockTransactions = Array.from(Array(5)).map(() => ({
          id: generateKey(),
          lines: [],
        }));

        return {
          page,
          action$: of(stockTransactionReceived({ stockTransactions })),
        };
      }),
      initSystemPageContent(),
    );
  }

  const { filter, pageIndex, pageSize, product, variantId } = params;
  const index = pageIndex ?? 0;
  const size = pageSize ?? defultSize;
  let options;
  if (product) {
    options = { filter: { itemNo: product, variantId: variantId, shopAccountName: '', transactionType: '', fromDate: null, toDate: null }, page: { index, size } };
  }
  else {
    options = { filter: { itemNo: '', shopAccountName: '', transactionType: '', fromDate: null, toDate: null }, page: { index, size } };
  }
  if (filter && !filter.filter) {
    options = { filter: filter, page: { index, size } }; //For stock tracking filter
  }
  else if ((filter && filter.filter)) {
    options = { ...filter, page: { index, size } };
  }

  let productFilter;
  if (product && !filter) {
    productFilter = variantId ? variantId : product;
  }
  return api.graphApi(stockTransactionPageQuery, { options }).pipe(
    map(({ pages: { stockTransactions: page }, stockTransaction: { listItems } }) => {
      if (!page)
        return null;

      page.component = PageComponentNames.StockTransactions;
      page.size = size;
      page.index = index;

      return { page, action$: of(stockTransactionReceived({ listItems, productFilter })) };
    }),
    initSystemPageContent(),
  );
};

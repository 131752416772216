import styles from '../../Order.module.scss';
import PropTypes from 'prop-types';
import { toLocaleDate, formatAsPercentage } from 'utils/format';
import UnitOfMeasure from '../UnitOfMeasure';
import ShippingStatus from '../ShippingStatus';
import QuoteLineStock from '../../quote/QuoteLineStock';
import { useCultureName, useCurrentRouteAsBackTo } from 'utils/hooks';
import { FormattedPrice } from 'components/primitives/price';
import { SalesAgreementTerm, SalesAgreementId } from '../SalesAgreements';
import { AbilityTo } from 'behavior/user/constants';
import { useHasAbilities } from 'components/objects/user';
import QuantityBox from './QuantityBox';
import { useContext } from 'react';
import QuantityContext from './QuantityContext';
import { Link } from 'components/primitives/links';
import { SimpleText } from 'components/sanaText';
import { routesBuilder } from 'routes';
import { usePrintMode } from 'utils/hooks';

const SimpleLine = ({
  line: {
    variationId, // 114888: [Autolack] 3.14.Display the variant code
    productId,
    title,
    isTracked,
    quantityShippedRemained,
    shippingStatus,
    shipmentDate,
    price,
    surchargeType,
    surchargeValue,
    subtotal,
    discountPercentage,
    uom,
    quantity,
    quantityShipped,
    quantityInvoiced,
    quantityOutstanding,
    inStock,
    salesAgreementId: salesAgreementIdPerLine,
    salesAgreementLineId,
    showReceivedAmount,
  },
  showId = false,
  options: {
    isB2CCustomer,
    showUOM,
    showStock,
    showPrices,
    showDiscounts,
    showExtraColumns,
    showShippingStatus,
    currencyInfo,
    showSurcharges, /*133701 -[RFC Surcharges] 3.1.Display surcharge notices on the web shop*/   
    isShipment
},
  className = '',
  cancelled,
  lineTexts,
  agreementId,
}) => {
  const [canUseSalesAgreements] = useHasAbilities(AbilityTo.UseSalesAgreements);
  const cultureName = useCultureName();
  const uomId = uom && uom.id;
  const uomDescription = uom && uom.description;
  const { quantities, setQuantity } = useContext(QuantityContext);
  let lineId = productId + '_' + variationId;
  const backTo = useCurrentRouteAsBackTo();
  const isPrintMode = usePrintMode();
  const isHidden = quantityShippedRemained <= 0;

  const discountAbility = useHasAbilities(AbilityTo.DiscountAbility)[0]; // [209289] TE: Remove discount info
 
  return (
    <tr className={`${cancelled ? styles.cancelled : ''} ${className}`}>
      <td aria-hidden={!cancelled} className="visually-hidden">{cancelled && lineTexts.lineStatusCancelled}</td>
      <td className={styles.lineId} aria-hidden={!showId}>{showId ? productId : variationId}</td>
      <td className={styles.lineTitle}>
        {title}
        {canUseSalesAgreements && salesAgreementIdPerLine
          && <SalesAgreementId agreementId={salesAgreementIdPerLine} />
        }
        {canUseSalesAgreements && salesAgreementLineId
          && <SalesAgreementTerm salesAgreementLineId={salesAgreementLineId} agreementId={agreementId ?? salesAgreementIdPerLine} />
        }
      </td>
      {showShippingStatus && <td className={styles.lineShippingStatus}><ShippingStatus status={shippingStatus} /></td>}
      {!isB2CCustomer &&
        <td className={styles.lineShipmentDate} aria-hidden={!shipmentDate}>
          {toLocaleDate(shipmentDate, cultureName)}
        </td>
      }
      {showPrices && <td className={styles.linePrice}><FormattedPrice price={price} currencyInfo={currencyInfo} /></td>}
      {showPrices && showSurcharges && surchargeType !== "Percentage" && surchargeValue > 0 && <td className={`${styles.linePrice} mtz-notice-lines`}><FormattedPrice price={surchargeValue} currencyInfo={currencyInfo} /></td>}
      {showPrices && showSurcharges && surchargeType === "Percentage" && surchargeValue > 0 && <td className={`${styles.linePrice} mtz-notice-lines`}>{surchargeValue + '%'}</td>}
      {showPrices && showSurcharges && surchargeValue === 0 && <td className={`${styles.linePrice} mtz-notice-lines`}>-</td>}
      {showDiscounts && discountAbility && ( // [209289] TE: Remove discount info
        <td className={styles.lineDiscount} aria-hidden={!discountPercentage}>
          {discountPercentage
            ? formatAsPercentage(discountPercentage, currencyInfo, true /* include sign*/)
            : ''
          }
        </td>
      )}
      <td className={styles.lineQty}>{quantity}</td>
      {showUOM && <td className={styles.lineUom}><UnitOfMeasure id={uomId} description={uomDescription} /></td>}
      {showStock &&
        <td aria-hidden={inStock == null} className={styles.lineStock}>
          <QuoteLineStock inStock={inStock} lineTexts={lineTexts} />
        </td>
      }
      {showExtraColumns && <td className={styles.lineQtyShipped}>{quantityShipped}</td>}
      {showExtraColumns && <td className={styles.lineQtyInvoiced}>{quantityInvoiced}</td>}
      {!isB2CCustomer && <td className={styles.lineQtyOutstanding}>{quantityOutstanding}</td>}
      {showPrices && <td className={styles.lineTotal}><FormattedPrice price={subtotal} currencyInfo={currencyInfo} /></td>}
      {isTracked && !isPrintMode && !isHidden && isShipment && <td className="text-center">
        <QuantityBox lineId={lineId} uom={uom} initialQuantity={0} quantityShippedRemained={quantityShippedRemained}
          getCurrentQuantity={() => quantities.get(lineId)}
          updateQuantity={newQuantity => setQuantity(lineId, newQuantity)} /></td>}
      {!isTracked && !isPrintMode  && isShipment && <td className="text-center"> <Link to={routesBuilder.forStockTracking(productId, variationId, backTo)}>
        <SimpleText textKey="TrackStock" />
      </Link> </td>}
    </tr>
  );
};

SimpleLine.propTypes = {
  line: PropTypes.shape({
    productId: PropTypes.string,
    title: PropTypes.string,
    shippingStatus: PropTypes.string,
    shipmentDate: PropTypes.string,
    price: PropTypes.number,
    subtotal: PropTypes.number,
    discountPercentage: PropTypes.number,
    uom: PropTypes.shape({
      id: PropTypes.string.isRequired,
      description: PropTypes.string,
    }),
    quantity: PropTypes.number,
    quantityShipped: PropTypes.number,
    quantityInvoiced: PropTypes.number,
    quantityOutstanding: PropTypes.number,
    inStock: PropTypes.bool,
    salesAgreementId: PropTypes.string,
    salesAgreementLineId: PropTypes.string,
  }),
  showId: PropTypes.bool,
  options: PropTypes.shape({
    isB2CCustomer: PropTypes.bool,
    showUOM: PropTypes.bool,
    showStock: PropTypes.bool,
    showPrices: PropTypes.bool,
    showDiscounts: PropTypes.bool,
    showSurcharges: PropTypes.bool,
    isShipment: PropTypes.bool,
    showExtraColumns: PropTypes.bool,
    showShippingStatus: PropTypes.bool,
    currencyInfo: PropTypes.shape({
      symbol: PropTypes.string,
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  className: PropTypes.string,
  cancelled: PropTypes.bool.isRequired,
  lineTexts: PropTypes.shape({
    lineStatusCancelled: PropTypes.string,
  }),
  agreementId: PropTypes.string,
};

export default SimpleLine;

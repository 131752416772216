import type { BarCodeScannerState } from './types';
import {
  BarCodeScannerAction,
  BAR_CODE_SCANNER_PRODUCT_ADDED,
  BAR_CODE_SCANNER_PRODUCT_INFO_RECEIVED,
  BAR_CODE_SCANNER_FAILURE_REASON_RECEIVED,
  BAR_CODE_SCANNER_RESULT_CLEARED,
} from './actions';
import { AddProductStatus } from './constants';

export const initialState: BarCodeScannerState = {
  status: undefined,
  productInfo: null,
  anyProductAdded: false,
};

export default (state: BarCodeScannerState = initialState, action: BarCodeScannerAction): BarCodeScannerState => {
  switch (action.type) {
    case BAR_CODE_SCANNER_PRODUCT_ADDED:
      return { ...state, status: AddProductStatus.Loading, productInfo: null };
    case BAR_CODE_SCANNER_PRODUCT_INFO_RECEIVED:
      return {
        ...state,
        status: AddProductStatus.Success,
        productInfo: action.payload.productInfo,
        anyProductAdded: true,
      };
    case BAR_CODE_SCANNER_FAILURE_REASON_RECEIVED:
      return { ...state, status: action.payload.reason, productInfo: null };
    case BAR_CODE_SCANNER_RESULT_CLEARED:
      return initialState;
    default:
      return state;
  }
};

import { useMemo, useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText, makeRichText } from 'utils/render';
import { ProductSelector } from 'components/objects/productSelectorTracking';
import { EVENT_SOURCES } from 'behavior/analytics';
import { useHandlerLockerInPreview } from 'components/objects/preview';
import {
  addToReductionList,
  receiveProducts,
  UpdateType,
} from 'behavior/pages/stockReduction';
import PropTypes from 'prop-types';
import { SimpleText, RichText } from 'components/sanaText';
import { toasts } from 'behavior/toasts';

const AddReduction = ({ items, status }) => {
  const texts = useQuickOrderTexts();

  const dispatch = useDispatch();
  const [productAddDisabled] = useState(false);
  const onProductAdd = useCallback(
    (line, product) => {
      if (productAddDisabled) {
        return;
      }
      const { productId, variantId, uomId, quantity } = line;
      const item = {
        productId,
        variantId,
        quantity,
        uomId,
      };

      dispatch(addToReductionList(item));
      dispatch(receiveProducts([product]));
      // setProductAddDisabled(true);
    },
    [productAddDisabled],
  );

  useEffect(() => {
    if (!status) {
      return;
    }

    if (status === UpdateType.NotExist) {
      toasts.warning(texts.stockReductionNoTracking);
    }
    if (status === UpdateType.AlreadyAdded) {
      toasts.warning(texts.stockReductionAlreadyAdded);
    }
    if (status === UpdateType.ListFull) {
      toasts.warning(texts.stockReductionRechedMax);
    }
    if (status === UpdateType.QuantityAdded) {
      toasts.success(texts.stockReductionQuantityAdded);
    }    
    if (status === UpdateType.Added) {
      toasts.success(texts.stockReductionItemAdded);
    }
  }, [status]);

  return (
    <>
      <strong><RichText textKey="StockReduction_Description" /></strong>
      <div className="StockReduction-search-box">
      <SimpleText textKey="StockReduction_Add_Description" />
      <ProductSelector
        texts={texts}
        isStockTracking = {false}
        onProductAdd={useHandlerLockerInPreview(onProductAdd)}
        productAddDisabled={productAddDisabled}
        productClickTrackingSource={EVENT_SOURCES.stockTracking}
      />
      </div>
      {items && items.count > 2 && (
        <div>
          <SimpleText textKey="StockReduction_Add_Already_Exits" />
        </div>
      )}
    </>
  );
};

AddReduction.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  status: PropTypes.string,
};

export default AddReduction;

function useQuickOrderTexts() {
  const texts = useSanaTexts([
    'Search_SearchPrefix',
    'StockReduction_AddToList',
    'Loading',
    'QuickOrder_ProductNotFound',
    'ProductCannotBeOrderedAtThisTime',
    'StockReduction_SuccessfullyAdded',
    'StockReduction_Add_NoTracking_Available',
    'StockReduction_Add_Already_Exits',
    'StockReduction_Add_ReachedLimit',
    'StockReduction_Add_Quantity',
    'StockReduction_Item_Added',
  ]).texts;

  return useMemo(() => {
    const [
      searchBoxPlaceholder,
      addBtn,
      loadingText,
      productNotFoundText,
      productCannotBeOrderedMessage,
      stockReductionSuccessfullyAdded,
      stockReductionNoTracking,
      stockReductionAlreadyAdded,
      stockReductionRechedMax,
      stockReductionQuantityAdded,
      stockReductionItemAdded,
    ] = texts;

    return {
      searchBoxPlaceholder: makeSimpleText(searchBoxPlaceholder),
      addBtn: makeSimpleText(addBtn),
      loadingText: makeSimpleText(loadingText),
      productNotFoundText: makeRichText(productNotFoundText),
      productCannotBeOrderedMessage: makeRichText(
        productCannotBeOrderedMessage,
      ),
      stockReductionSuccessfullyAdded: makeSimpleText(
        stockReductionSuccessfullyAdded,
      ),
      stockReductionNoTracking: makeSimpleText(stockReductionNoTracking),
      stockReductionAlreadyAdded: makeSimpleText(stockReductionAlreadyAdded),
      stockReductionRechedMax: makeSimpleText(stockReductionRechedMax),
      stockReductionQuantityAdded: makeSimpleText(stockReductionQuantityAdded),
      stockReductionItemAdded: makeSimpleText(stockReductionItemAdded),
    };
  }, [texts]);
}

import { authenticated, loginFailed } from './actions';
import { viewerChanged, navigateTo } from 'behavior/events';
import { tap } from 'rxjs/operators';
import { routesBuilder } from 'routes'; /*136722: Loop in password reset*/
import { from, of } from 'rxjs';
import { getBackToFromUrl } from 'behavior/pages/helpers'; /*136722: Loop in password reset*/
import { unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { arrayToObject } from 'utils/helpers';

export function createMapLoginResult(state$, { api, scope }, expirationsObserver) {
  return function (email, loginResult, viewerResult) {
    if (loginResult.failureText) {
      return of(unsetLoadingIndicator(), loginFailed());
    }

    if (loginResult.token) {
      expirationsObserver && expirationsObserver.next(loginResult.token.expiration);
      api.setAuthToken(loginResult.token.value);

      const data = createUserData(viewerResult, true);
      data.email = email;

      const redirectTo = getBackToFromUrl(scope) || state$.value.page.backTo; /*136722: Loop in password reset*/

      if (redirectTo) {
        return from([
          authenticated(data),
          viewerChanged(),
          navigateTo(redirectTo.routeData, redirectTo.url),
        ]);
      }

      return from([authenticated(data), viewerChanged(), navigateTo(routesBuilder.forHome())]);
    }
  };
}

export function createMapB2BRegistrationResultResult() {
  return function (businessRegistrationResult) {
    if (businessRegistrationResult.isRegistered)
      return of(navigateTo(routesBuilder.forHome()));
  };
}


export function createUserData(viewer, isAuthenticated) {
  const userData = { ...viewer, isAuthenticated };

  if (viewer.abilities)
    userData.abilities = convertAbilities(viewer.abilities);

  return userData;
}

export function handleToken(api, expirationsObserver, broadcast = true) {
  return tap(res => {
    const token = res.viewer.token;
    if (token !== undefined) {
      expirationsObserver && expirationsObserver.next(token.expiration);
      api.setAuthToken(token.value, broadcast);
      delete res.viewer.token;
    }
  });
}

export function convertAbilities(abilities) {
  return arrayToObject(abilities, ability => ability.key, ability => ability.state);
}

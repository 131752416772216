export const PRODUCT_SELECTOR_SEARCH_SUGGESTIONS_REQUESTED = 'PRODUCT_SELECTOR_SEARCH_SUGGESTIONS_REQUESTED';
export const searchProducts = (keywords, count = 10) => ({
  type: PRODUCT_SELECTOR_SEARCH_SUGGESTIONS_REQUESTED,
  payload: { keywords, count },
});

export const PRODUCT_SELECTOR_SEARCH_SUGGESTIONS_RECEIVED = 'PRODUCT_SELECTOR_SEARCH_SUGGESTIONS_RECEIVED';
export const receiveSearchSuggestions = suggestions => ({
  type: PRODUCT_SELECTOR_SEARCH_SUGGESTIONS_RECEIVED,
  payload: { suggestions },
});

export const PRODUCT_SELECTOR_SEARCH_BARCODE_REQUESTED = 'PRODUCT_SELECTOR_SEARCH_BARCODE_REQUESTED';
export const searchProductByBarcode = barcode => ({
  type: PRODUCT_SELECTOR_SEARCH_BARCODE_REQUESTED,
  payload: { barcode },
});

export const PRODUCT_SELECTOR_SEARCH_BARCODE_RECEIVED = 'PRODUCT_SELECTOR_SEARCH_BARCODE_RECEIVED';
export const receiveSearchByBarcode = product => ({
  type: PRODUCT_SELECTOR_SEARCH_BARCODE_RECEIVED,
  payload: { product },
});

export const PRODUCT_SELECTOR_PRODUCT_REQUESTED = 'PRODUCT_SELECTOR_PRODUCT_REQUESTED';
export const requestProduct = id => ({
  type: PRODUCT_SELECTOR_PRODUCT_REQUESTED,
  payload: { id },
});

export const PRODUCT_SELECTOR_PRODUCT_RECEIVED = 'PRODUCT_SELECTOR_PRODUCT_RECEIVED';
export const receiveProduct = product => ({
  type: PRODUCT_SELECTOR_PRODUCT_RECEIVED,
  payload: { product },
});

export const PRODUCT_SELECTOR_PRODUCT_CLEARED = 'PRODUCT_SELECTOR_PRODUCT_CLEARED';
export const clearProduct = () => ({
  type: PRODUCT_SELECTOR_PRODUCT_CLEARED,
});
exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".StockTransactions_popup-button-wrapper{display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center;height:100%;width:100%;margin-top:10PX}.StockTransactions_popup-button-wrapper .StockTransactions_btn-action{margin-left:15px}.StockTransactions_text-area{margin-bottom:0!important;height:5.77em;resize:none;border:0;border-radius:3px;display:block;box-sizing:border-box;width:100%;outline:none;padding:.3em 1em;background:#f2f2f2;border-bottom:1px solid;border-color:#c2c2c2;color:#333;margin-top:10px}.StockTransactions_model-content,.StockTransactions_model-header{margin-bottom:0}.StockTransactions_model-content{line-height:17px;margin-top:5px}table .StockTransactions_img-button{-webkit-appearance:none;background:unset;border:none;cursor:pointer}table .StockTransactions_img-button:focus{outline:none}", ""]);

// exports
exports.locals = {
	"popup-button-wrapper": "StockTransactions_popup-button-wrapper",
	"popupButtonWrapper": "StockTransactions_popup-button-wrapper",
	"btn-action": "StockTransactions_btn-action",
	"btnAction": "StockTransactions_btn-action",
	"text-area": "StockTransactions_text-area",
	"textArea": "StockTransactions_text-area",
	"model-content": "StockTransactions_model-content",
	"modelContent": "StockTransactions_model-content",
	"model-header": "StockTransactions_model-header",
	"modelHeader": "StockTransactions_model-header",
	"img-button": "StockTransactions_img-button",
	"imgButton": "StockTransactions_img-button"
};
import styles from './StockTrackingsList.module.scss';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { Select } from 'components/primitives/form';
import { useState, useRef, useEffect, useMemo } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import restImage from './images/rest-white.png';

const Filter = ({ applyFilter, filter, categories }) => {
  const [option, setOption] = useState('ProductId');
  const [optionValue, setOptionValue] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const categoryRef = useRef(null);
  const [placeholder] = useSanaTexts([
    'StockTrackings_Filter_Categories_Placeholder',
  ]).texts;

  useEffect(() => {
    const filterData = {
      filter: { option, optionValue, categories: selectedCategories },
    };    
    applyFilter(filterData);
  }, [selectedCategories, option, optionValue]);

  const onCategoryChange = (selectedList, selectedItem) => {
    setSelectedCategories(selectedList.map(x => x.id));
  };

  const resetSelection = () => {
    setOption('ProductId');
    setOptionValue('');
    setSelectedCategories([]);
    categoryRef.current.resetSelectedValues();
  };

  return (
    <div className={styles.filterSection}>
      <div className={`${styles.filterInput} Input_tbx`}>
        <span>
          <SimpleText textKey="StockTrackings_Filter_Product" />
        </span>
        <input
          id="trackingFilterInput"
          type="text"
          value={optionValue}
          onChange={e => setOptionValue(e.target.value)}
        />
      </div>
      <div className={styles.filterDropdown}>
        <Select
          items={getFilterSeletion()}
          onChange={item => setOption(item)}
          value={option}
          id={'list_Filter'}
        />
      </div>
      <div className={styles.filterMultiSelect}>
        <span className={styles.label}>
          <SimpleText textKey="StockTrackings_Filter_Categories" />
        </span>
        <Multiselect
          ref={categoryRef}
          options={categories}
          displayValue="name"
          onSelect={onCategoryChange}
          onRemove={onCategoryChange}
          showCheckbox
          placeholder={makeSimpleText(placeholder)}
          closeOnSelect={false}
        />
        <button onClick={resetSelection}  className={`${styles.nonArrowBtn} Button_btn Reset-btn`}> <img src={restImage} alt="Reset" /></button>
      </div>
    </div>
  );
};

Filter.propTypes = {
  applyFilter: PropTypes.func,
  filter: PropTypes.object,
  inputFocusRef: PropTypes.object,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
};

export default Filter;

function getFilterSeletion() {
  const texts = useSanaTexts([
    'StockTrackings_Filter_Option_ProductId',
    'StockTrackings_Filter_Option_Barcode',
    'StockTrackings_Filter_Option_Title',
  ]).texts;

  return useMemo(() => {
    const [productId, barcode, title] = texts;

    return [
      { value: 'ProductId', name: productId },
      { value: 'Barcode', name: barcode },
      { value: 'Title', name: title },
    ];
  }, [texts]);
}

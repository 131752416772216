import {
  addOnHandStockMutation
} from '../queries';
import {
  ADD_ON_HAND_STOCK,
} from '../actions';
import { combineEpics, ofType } from 'redux-observable';
import { pluck, startWith, switchMap, map } from 'rxjs/operators';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';

///114880: [Autolack] 3.9. Stock transaction � Add to on-hand stock
const addOnHandStockEpic = (actions$, _state$, { api, logger }) => {
  return actions$.pipe(
    ofType(ADD_ON_HAND_STOCK),
    pluck('payload'),
    switchMap((input) => api.graphApi(addOnHandStockMutation, { input }).pipe(
      map(() => unsetLoadingIndicator()),
      startWith(setLoadingIndicator()),
    )),
  );
};

export default combineEpics(
  addOnHandStockEpic,
);

import { BUSINESS_REGISTRATION_PROCESSED, REGISTRATION_TEMPLATEFIELDS_RECEIVED } from './actions';
import { createReducer } from 'utils/redux';

const initialState = {
  businessRegistrationResult: null,
  templateFields: null,
};

export default createReducer(initialState, {
  [BUSINESS_REGISTRATION_PROCESSED]: onRegistrationProcessed,
  [REGISTRATION_TEMPLATEFIELDS_RECEIVED]: onTemplateFieldsReceived,
});

function onRegistrationProcessed(state, action) {
  return { ...state, businessRegistrationResult: action.payload };
}

function onTemplateFieldsReceived(state, action) {
  return { ...state, templateFields: action.payload };
}

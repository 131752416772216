import type { Epic } from 'redux-observable';
import type { Observable } from 'rxjs';
import { merge, EMPTY, concat, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { showErrorToast } from 'behavior/errorHandling';
import { logger } from 'utils/logs';

export const enum ERROR_PRIORITIES {
  LOW = 0,
  MEDIUM = 1,
  HIGH = 2,
}

type HandleError<S, R> = (error: any, source$: Observable<S>) => Observable<R>;

const getEmpty = () => EMPTY;
export const handleError = <S, R>(priority = ERROR_PRIORITIES.MEDIUM, epicName: string, continueWith: HandleError<S, R> = getEmpty) => catchError(
  (e: any, o: Observable<S>) => {
    const continueObservable = continueWith(e, o);

    if (priority === ERROR_PRIORITIES.LOW)
      return continueObservable;

    logger.error(`The following error occurred in "${epicName || '<anonymous>'}" epic:`, e);
    if (priority === ERROR_PRIORITIES.HIGH)
      return concat(of(showErrorToast({ type: 'reload', data: e.response?.errors?.[0].extensions.data })), continueObservable);

    return continueObservable;
  },
);

type SanaEpic = Epic<any, any, any> & { displayName?: string }
export function combineWithErrorCatch(...epics: SanaEpic[]) {
  function combineEpics(...args: Parameters<Epic<any, any, any>>) {
    return merge(
      ...epics.map(epic => {
        let output$ = epic(...args);
        if (!output$) {
          throw new TypeError(`combineEpics: one of the provided Epics "${epic.name || '<anonymous>'}" does not return a stream.
Double check you're not missing a return statement!`);
        }

        output$ = output$.pipe(
          handleError(ERROR_PRIORITIES.HIGH, epic.displayName || epic.name, _ => output$),
        );

        return output$;
      }),
    );
  }

  return combineEpics;
}

export const STOCK_REDUCTION_TO_LIST_ADD = 'STOCK_REDUCTION_TO_LIST_ADD';
export const addToReductionList = item => ({
  type: STOCK_REDUCTION_TO_LIST_ADD,
  payload: { item },
});

export const STOCK_REDUCTION_TO_LIST_ADDED = 'STOCK_REDUCTION_TO_LIST_ADDED';
export const addToReductionListResultReceived = item => ({
  type: STOCK_REDUCTION_TO_LIST_ADDED,
  payload: item,
});

export const STOCK_REDUCTION_DELETE_FROM_LIST = 'STOCK_REDUCTION_DELETE_FROM_LIST';
export const deleteFromList = trackingId => ({
  type: STOCK_REDUCTION_DELETE_FROM_LIST,
  payload: trackingId,
});

export const STOCK_REDUCTION_DELETE_LIST = 'STOCK_REDUCTION_DELETE_LIST';
export const deleteList = () => ({
  type: STOCK_REDUCTION_DELETE_LIST,
  payload: '',
});

export const STOCK_REDUCTION_SAVE = 'STOCK_REDUCTION_SAVE';
export const addStockReductionList = reductions => ({
  type: STOCK_REDUCTION_SAVE,
  payload: { reductions },
});

export const STOCK_REDUCTION_SAVED = 'STOCK_REDUCTION_SAVED';
export const addStockReductionListResultReceived = result => ({
  type: STOCK_REDUCTION_SAVED,
  payload: { result },
});

export const STOCK_REDUCTION_PRODUCTS_RECEIVED = 'STOCK_REDUCTION_PRODUCTS_RECEIVED';
export const receiveProducts = products => ({
  type: STOCK_REDUCTION_PRODUCTS_RECEIVED,
  payload: { products },
});
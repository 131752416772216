export const UpdateType = Object.freeze({  
  Adding: 'Adding',
  Added: 'Added',
  QuantityAdded: 'QuantityAdded',
  AlreadyAdded: 'AlreadyAdded',
  Removed: 'Removed',
  ListFull: 'ListFull',
  NotExist: 'NotExist',
  Exist: 'Exist',
  Saved: 'Saved',
});
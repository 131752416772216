import styles from './ProductPrice.module.scss';
import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'components/primitives/grid';
import { Placeholder } from 'components/primitives/placeholders';
import { renderHTML, makeSimpleText } from 'utils/render';
import { useSanaTexts } from 'components/sanaText';
import { getCurrency, getFormatPrice, formatAsPercentage } from 'utils/format';
import { usePriceModel } from 'utils/product';
import { useCurrencyInfo } from 'utils/hooks';

const ProductPrice = ({ salesPrice, basePrice }) => {
  const currencyInfo = useCurrencyInfo();
  const { price, actionPrice, discountAmount, discountPercentage } = usePriceModel(salesPrice, basePrice);
  const formatAsPrice = currencyInfo && getFormatPrice(currencyInfo);

  const formattedPrice = useMemo(() => {
    if (!currencyInfo)
      return null;

    return renderHTML(formatPrice(formatAsPrice(price), currencyInfo));
  }, [currencyInfo, price]);

  const formattedActionPrice = currencyInfo && actionPrice
    && actionPrice !== price && formatAsPrice(actionPrice);

  const { texts: [
    priceLbl,
    actionPriceLbl,
    discountLbl,
    discountAmountTemplate,
    discountPercentTemplate,
  ], loaded } = useSanaTexts([
    'Price',
    'ActionPrice_Label',
    'ActionPrice_Discount_Label',
    'ActionPrice_DiscountAmount',
    'ActionPrice_DiscountPercentage',
  ]);

  if (!loaded || formattedPrice == null)
    return <Placeholder className="price-block product-price-placeholder" />;

  const currencySymbol = getCurrency(currencyInfo.id, currencyInfo.cultureName);

  return (<>
    {(!!(formattedActionPrice || discountAmount || discountPercentage)) && (
      <Col xs="auto" className="price-block price-list discount_price-block" role="group">
        {!!actionPrice && (
          <div className={`${styles.actionPrice} action-price`} title={actionPriceLbl}>
            <i className="visually-hidden">{actionPriceLbl} {currencySymbol + actionPrice}</i>
            <span aria-hidden suppressHydrationWarning>
              {formattedActionPrice}
            </span>
          </div>
        )}
        {!!discountAmount && (
          <div className={`${styles.discountAmount} discount-amount`} title={discountLbl}>
            <i className="visually-hidden">{discountLbl} {currencySymbol + discountAmount}</i>
            <span aria-hidden suppressHydrationWarning>
              {makeSimpleText(discountAmountTemplate, [formatAsPrice(discountAmount, currencyInfo)])}
            </span>
          </div>
        )}
        {!!discountPercentage && (
          <div className={`${styles.discountPercentage} discount-percentage`} title={discountLbl}>
            <i className="visually-hidden">{discountLbl} {discountPercentage + '%'}</i>
            <span aria-hidden>
              {makeSimpleText(discountPercentTemplate, [formatAsPercentage(discountPercentage, currencyInfo, false, 2)])}
            </span>
          </div>
        )}
      </Col>
    )}
    <Col xs="auto" className="price-block" title={priceLbl}>
      <i className="visually-hidden">{priceLbl} {currencySymbol + price}</i>
      <span className={`${styles.actualPrice} actual-price`} aria-hidden suppressHydrationWarning>
        {formattedPrice}
      </span>
    </Col>
  </>);
};

ProductPrice.propTypes = {
  salesPrice: PropTypes.number,
  basePrice: PropTypes.number,
};

export default memo(props => {
  if (props.salesPrice === null)
    return null;

  if (typeof props.salesPrice === 'undefined')
    return <Placeholder className="price-block product-price-placeholder" />;

  return <ProductPrice {...props} />;
});

function formatPrice(price, currencyInfo) {
  if (currencyInfo.decimalDigits === 0)
    return price;

  return price.replace(/(\d+)([^\d]*)$/, '<sup>$1</sup>$2');
}

import styles from './ProductSelector.module.scss';
import { memo, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useOnCustomerChanged } from 'utils/hooks';
import {
  searchProducts,
  requestProduct,
  clearProduct,
  searchProductByBarcode,
} from 'behavior/productSelector';
import { addToReductionList } from 'behavior/pages/stockReduction';
import ProductPanel from './ProductPanel';
import SearchBox from './SearchBox';
import LoadingIndicator from './LoadingIndicator';
import BarcodeReader from './barcodeScanner/BarcodeReader';
import { UpdateType } from 'behavior/pages/stockReduction/constants';
import { useSanaTexts } from 'components/sanaText';
import { UpdateType as ProductSelectorUpdateType } from 'behavior/productSelector/constants';

const SEARCH_INPUT_ID = 'productSelectorSearch';

const ProductSelector = ({
  preSelectedProduct,
  texts,
  onProductAdd,
  productAddDisabled,
  allowAddUnorderableProduct,
  productClickTrackingSource,
  isStockTracking,
  clearResult,
  onProductSelected,
}) => {
  const dispatch = useDispatch();
  const { suggestions, selectedProduct, barcodeResult, barcodeResultStatus, status, shopAccountName } = useSelector(
    ({ productSelector, page, user }) => ({
      suggestions: productSelector.suggestions,
      selectedProduct: productSelector.selectedProduct,
      barcodeResult: productSelector.barcodeResult,
      barcodeResultStatus: productSelector.status,
      status: page.status,
      shopAccountName: user.name,
    }),
    shallowEqual,
  );
  const [stockReductionComment, barcodeScanText] = useSanaTexts(['StockReduction_Item_Comment', 'Barcode_ScanText']).texts;
  const productLoadingState = useState(false);
  const [productLoading, setProductLoading] = productLoadingState;
  const [preSelecteProd, setPreSelectedProd] = useState(preSelectedProduct);
  const [enableReScan, setEnableReScan] = useState(false);
  const [reSetBarcode, setReSetBarcode] = useState(false);
  const productNotFoundState = useState(false);
  const [, setProductNotFound] = productNotFoundState;
  const [selectedVariantId, setSelectedVariantId] = useState(preSelectedProduct ? preSelectedProduct.variantId : null);

  const onProductSearch = useCallback(
    (keywords, count) => dispatch(searchProducts(keywords, count)),
    [],
  );
  const onProductSelect = useCallback((id, variantId) => {
    dispatch(requestProduct(id));
    if (variantId) {
      setSelectedVariantId(variantId);
    }

    if(isStockTracking){
      onProductSelected();
    }
  }, []);
  const onProductClear = useCallback(() => dispatch(clearProduct()), []);

  useEffect(() => {
    setProductLoading(false);
  }, [selectedProduct]);  

  useOnCustomerChanged(() => {
    onProductClear();
  });

  useEffect(
    () => () => {
      onProductClear();
    },
    [],
  );

  useEffect(() => {
    if (preSelecteProd) {
      const { productId } = preSelecteProd;
      dispatch(requestProduct(productId));
    }
  }, [preSelecteProd]);

  useEffect(() => {
    if (barcodeResult) {
      setPreSelectedProd(barcodeResult);
      setSelectedVariantId(barcodeResult.variantId);      
      setProductNotFound(false);
      // if(preSelecteProd){
      //   setSelectedVariantId(preSelecteProd.variantId);
      // }
    }
  }, [barcodeResult]);

  useEffect(() => {
    if (barcodeResultStatus && barcodeResultStatus === ProductSelectorUpdateType.NotFound) {
      setProductLoading(false);
      setProductNotFound(true);
    }
  }, [barcodeResultStatus]);

  useEffect(() => {
    if (status) {
      if (status === UpdateType.Added || status === UpdateType.QuantityAdded) {
        setEnableReScan(true);
        setReSetBarcode(false);
      } else if (
        status === UpdateType.NotExist ||
        status === UpdateType.AlreadyAdded ||
        status === UpdateType.ListFull
      ) {
        setEnableReScan(true);
        setReSetBarcode(false);
      } else if (status === UpdateType.Adding) {
        setReSetBarcode(false);
      }
    }
  }, [status]);

  const onBarcodeDetect = useCallback( barcode =>{
    setEnableReScan(false);
    if(isStockTracking){
      setProductLoading(true);
      dispatch(searchProductByBarcode(barcode));
    }
    else{
      const item = {  productId:'', quantity: 1, barcode, comment: stockReductionComment.replace('[SHOP_ACCOUNT_NAME]', shopAccountName) };
      dispatch(addToReductionList(item));
    }
  },[]);

  return (
    <div className={styles.box}>
      <SearchBox
        texts={texts}
        id={SEARCH_INPUT_ID}
        productLoadingState={productLoadingState}
        productNotFoundState={productNotFoundState}
        suggestions={suggestions}
        selectedProduct={selectedProduct}
        onProductSearch={onProductSearch}
        onProductSelect={onProductSelect}
        onProductClear={onProductClear}
        clearSearchBox={clearResult}
      />
      {!isStockTracking && <span>&nbsp;</span>}
      <BarcodeReader onBarcodeDetect={onBarcodeDetect} isStockTracking ={isStockTracking} enableReScan={enableReScan} reSet = {reSetBarcode} />
      <div className={styles.productWrapper}>
        {productLoading ? (
          <LoadingIndicator text={texts.loadingText} />
        ) : !selectedProduct ? null : (
          <ProductPanel
            texts={texts}
            product={selectedProduct}
            preSelectedVariantId={selectedVariantId}
            onProductAdd={onProductAdd}
            productAddDisabled={productAddDisabled}
            allowAddUnorderableProduct={allowAddUnorderableProduct}
            productClickTrackingSource={productClickTrackingSource}
            searchInputId={SEARCH_INPUT_ID}
            isStockTracking = {isStockTracking}
          />
        )}
      </div>
    </div>
  );
};

ProductSelector.propTypes = {
  texts: PropTypes.shape({
    searchBoxPlaceholder: PropTypes.string,
    addBtn: PropTypes.string,
    addBtnAlt: PropTypes.string,
    productNotFoundText: PropTypes.node,
    productCannotBeOrderedMessage: PropTypes.node,
    loadingText: PropTypes.string,
  }).isRequired,
  onProductAdd: PropTypes.func.isRequired,
  productAddDisabled: PropTypes.bool,
  allowAddUnorderableProduct: PropTypes.bool,
  productClickTrackingSource: PropTypes.string.isRequired,
  preSelectedProduct: PropTypes.object,
  isStockTracking:PropTypes.bool.isRequired,
  clearResult: PropTypes.bool,
  onProductSelected: PropTypes.func,
};

export default memo(ProductSelector);

import PropTypes from 'prop-types';

const LineTemplatePropTypes = {
  productInfo: PropTypes.node.isRequired,
  agreementTerm: PropTypes.node,
  productActionBlock: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.bool,
  ]),
  actionLinks: PropTypes.node,
  quantityBox: PropTypes.node.isRequired,
  uomLabel: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.bool,
  ]),
  priceNode: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.bool,
  ]),
  productDiscount: PropTypes.node,
  subTotal: PropTypes.node,
  className: PropTypes.string,
  isGroupedLine: PropTypes.bool,
  id: PropTypes.string,
  isSpecialShipping: PropTypes.bool,  //[114886] 3.12. Warning on products that require additional shipping
  allowedForBToBOnly: PropTypes.bool,
  isVariantSpecialShipping: PropTypes.bool, //[114886] 3.12. Warning on products that require additional shipping
  surchargeValue: PropTypes.number,   //133701 - [RFC Surcharges] 3.1. Display surcharge notices on the web shop
  surchargeType: PropTypes.string     //133701 - [RFC Surcharges] 3.1. Display surcharge notices on the web shop
};

export default LineTemplatePropTypes;
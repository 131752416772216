import {
  stockTrackingsQuery,
  addStockTrackingMutation,
  removeTrackingMutation,
  updateStocksMutation,
} from './queries';
import {
  STOCK_TRACKINGS_REQUESTED,
  STOCK_TRACKING_ADD,
  STOCK_TRACKING_DELETE_REQUESTED,
  STOCK_TRACKING_UPDATE_REQUESTED,
  stockTrackingUpdated,
  stockTrackingsReceived,
  stockTrackingsAddingResultReceived,
  stockTrackingsDeleted,
} from './actions';
import { combineEpics, ofType } from 'redux-observable';
import { mergeMap, pluck, startWith, switchMap } from 'rxjs/operators';
import {
  setLoadingIndicator,
  unsetLoadingIndicator,
} from 'behavior/loadingIndicator';
import { retryWithToast } from 'behavior/errorHandling';

const loadStockTrackingsEpic = (actions$, _state$, { api, logger }) => {
  return actions$.pipe(
    ofType(STOCK_TRACKINGS_REQUESTED),
    switchMap(action =>
      api.graphApi(stockTrackingsQuery, action.payload).pipe(
        pluck('stockTracking', 'list'),
        mergeMap(list => [
          stockTrackingsReceived({ list }),
          unsetLoadingIndicator(),
        ]),
        startWith(setLoadingIndicator()),
      ),
    ),
  );
};

const addStockTrackingEpic = (actions$, _state$, { api, logger }) => {
  return actions$.pipe(
    ofType(STOCK_TRACKING_ADD),
    mergeMap(action =>
      api.graphApi(addStockTrackingMutation, action.payload).pipe(
        mergeMap(({ stockTrackings }) => [
          stockTrackingsAddingResultReceived(
            stockTrackings && stockTrackings.add,
          ),
          unsetLoadingIndicator(),
        ]),
        startWith(setLoadingIndicator()),
      ),
    ),
  );
};

const stockTrackingDeleteEpic = (actions$, _state$, { api }) => {
  return actions$.pipe(
    ofType(STOCK_TRACKING_DELETE_REQUESTED),
    mergeMap(action =>
      api.graphApi(removeTrackingMutation, action.payload).pipe(
        mergeMap(() => [stockTrackingsDeleted(), unsetLoadingIndicator()]),
        startWith(setLoadingIndicator()),
      ),
    ),
  );
};

const stockTrackingUpdateEpic = (actions$, _state$, { api, logger }) => {
  return actions$.pipe(
    ofType(STOCK_TRACKING_UPDATE_REQUESTED),
    mergeMap(action =>
      api.graphApi(updateStocksMutation, action.payload).pipe(
        mergeMap(() => [
          stockTrackingUpdated(action.payload.input),
          unsetLoadingIndicator(),
        ]),
        retryWithToast(actions$, logger),
        startWith(setLoadingIndicator()),
      ),
    ),
  );
};

export default combineEpics(
  addStockTrackingEpic,
  loadStockTrackingsEpic,
  stockTrackingDeleteEpic,
  stockTrackingUpdateEpic,
);

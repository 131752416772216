import PropTypes from 'prop-types';
import LanguageSelectorBase from './LanguageSelectorBase';

const LanguageSelectorBlock = ({ model, isDesignerMode }) => (
  <LanguageSelectorBase options={model} isDesignerMode={isDesignerMode} />
);

LanguageSelectorBlock.propTypes = {
  model: PropTypes.shape({
    togglePresentation: PropTypes.number.isRequired,
    showFlagsInDropdown: PropTypes.bool.isRequired,
  }).isRequired,
  isDesignerMode: PropTypes.bool,
};

export default LanguageSelectorBlock;

import styles from '../PLP.module.scss';
import PropTypes from 'prop-types';
import ProductContext from '../ProductContext';
import Thumbnail from '../Thumbnail';
import Description from '../Description';
import { ProductPrice } from 'components/primitives/product';
import OrderBox from './OrderBox';
import { AbilityTo } from 'behavior/user/constants';
import ProductComparisonSelector from '../ProductComparisonSelector';
import { useResponsiveBreakpoints } from 'utils/layout';
import { RichText, useSanaTexts, SimpleText } from 'components/sanaText';
import { InfoAlert } from 'components/primitives/alerts';
import Tooltip from 'components/primitives/tooltip'
import { makeRichText } from 'utils/render';
import { BaseUomPricePLP } from 'components/primitives/product';
const ProductLine = ({ product, gridMode, showThumbnail, abilities, index }) => {
  const { xs } = useResponsiveBreakpoints();
  const gridOrSmallScreen = gridMode || xs;
  const [message] =
    useSanaTexts(['SpecialShippingMessage']).texts;
  return (
    <ProductContext.Provider value={product}>
      <div className={styles.item}>
        <div className={styles.tile} role="listitem">
          <i className="visually-hidden">{index + 1}</i>
          {(showThumbnail || gridMode) && <Thumbnail gridMode={gridMode} />}
          <div className={styles.productInfoWrapper}>
            <div className={styles.productInfo}>
              <Description />
              <div className={styles.productAction}>
                {product.price != null && (
                  <div className={styles.prices}>
                    <ProductPrice salesPrice={product.price} basePrice={product.listPrice} />
                  </div>
                )}
                <div>
                  {product.price != null && product.pricePerBaseUoM !== 0 && (
                    <span className={styles.pricePerBaseUom}>
                      <BaseUomPricePLP baseUomPrice={product.pricePerBaseUoM} baseUoMDescription={product.baseUoMDescription} />
                    </span>
                  )}
                </div>
                {gridOrSmallScreen && <ProductComparisonSelector className={styles.productComparisonSelector} />}
                {product.isSpecialShipping &&
                  (<div className="show_in-grid">
                    <Tooltip title="" body={makeRichText(message)} sign={false} className="custom_tooltip">
                      <RichText textKey="SpecialShippingMessageText" />
                    </Tooltip>
                  </div>)}
                {/* <div className="show_in-grid">{product.isSpecialShipping && (<div className="alert_wrapper"><InfoAlert role="alert"> <RichText textKey="SpecialShippingMessage" /> </InfoAlert></div>)}</div> */}
                {abilities[AbilityTo.OrderProducts] && (
                  <OrderBox
                    product={product}
                    className={styles.orderBox}
                    abilities={abilities}
                    gridMode={gridMode}
                  />
                )}
              </div>
            </div>
            {!gridOrSmallScreen && <ProductComparisonSelector className={styles.productComparisonSelector} />}
            {product.allowedForBToBOnly && (<div className="shipping_text"> <RichText textKey="OnlyAvailableForB2BCustomers" /> </div>)}
            <div className="show_in-list"> {product.isSpecialShipping && (<div className="alert_wrapper"><InfoAlert role="alert"> <RichText textKey="SpecialShippingMessage" /> </InfoAlert></div>)}</div>
          </div>
        </div>
      </div>
    </ProductContext.Provider>
  );
};

ProductLine.propTypes = {
  product: PropTypes.object.isRequired,
  gridMode: PropTypes.bool,
  showThumbnail: PropTypes.bool,
  abilities: PropTypes.shape({
    [AbilityTo.OrderProducts]: PropTypes.bool,
  }).isRequired,
  index: PropTypes.number.isRequired,
};

export default ProductLine;
exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Breadcrumb_container{display:-webkit-flex;display:flex}.Breadcrumb_container.Breadcrumb_no-back,.Breadcrumb_nav{display:none}.Breadcrumb_nav ul{display:-webkit-flex;display:flex;-webkit-align-items:flex-end;align-items:flex-end;margin:0;padding:0;list-style:none;font-size:var(--normalText_SmallerFontSize,12px);line-height:1}.Breadcrumb_nav li .Breadcrumb_icon{max-width:.2em;max-height:.2em;color:var(--menu_ActiveItemUnderline_Color,#cb2245);margin:0 .06em}.Breadcrumb_nav li svg.Breadcrumb_icon{margin:0}.Breadcrumb_nav li a{color:var(--breadcrumb_FontColor,grey)}html.pointer-mouse .Breadcrumb_nav li a:hover{color:var(--breadcrumb_FontColor,grey)}@supports (not (-webkit-hyphens:none)) and (not (-moz-appearance:none)) and (list-style-type:\"*\"){@media (pointer:coarse){html:not(.pointer-mouse) .Breadcrumb_nav li a:active{color:var(--breadcrumb_FontColor,grey)}}}.Breadcrumb_nav li:first-of-type .Breadcrumb_icon{display:none}.Breadcrumb_nav li:last-of-type a{color:var(--breadcrumb_LastItem_FontColor,#333)}html.pointer-mouse .Breadcrumb_nav li:last-of-type a:hover{color:var(--breadcrumb_LastItem_FontColor,#333)}@supports (not (-webkit-hyphens:none)) and (not (-moz-appearance:none)) and (list-style-type:\"*\"){@media (pointer:coarse){html:not(.pointer-mouse) .Breadcrumb_nav li:last-of-type a:active{color:var(--breadcrumb_LastItem_FontColor,#333)}}}.Breadcrumb_back{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center}.Breadcrumb_back ins{display:none}.Breadcrumb_back a+ins{border-left:1px solid var(--borderColor,#e6e6e6);padding-left:41px;margin-left:42px}@media (min-width:var(--breakpoints_Medium,960)px),print{.Breadcrumb_container.Breadcrumb_no-back,.Breadcrumb_nav{display:-webkit-flex;display:flex}.Breadcrumb_nav{-webkit-align-items:center;align-items:center}.Breadcrumb_back ins{display:block;font-size:var(--normalText_SmallerFontSize,12px);height:1.5em}}@media print{.Breadcrumb_cnt{display:none}}", ""]);

// exports
exports.locals = {
	"container": "Breadcrumb_container",
	"no-back": "Breadcrumb_no-back",
	"noBack": "Breadcrumb_no-back",
	"nav": "Breadcrumb_nav",
	"icon": "Breadcrumb_icon",
	"back": "Breadcrumb_back",
	"cnt": "Breadcrumb_cnt"
};
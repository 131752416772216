import styles from '../../Order.module.scss';
import PropTypes from 'prop-types';
import ServiceLine from './ServiceLine';
import ProductLine from './ProductLine';
import LinesFooter from '../LinesFooter';
import { useIsPreview } from 'components/objects/preview';
import PreviewLine from './PreviewLine';
import QuantityContext from '../DesktopTable/QuantityContext';
import { SanaButton } from 'components/primitives/buttons';
import { addOnHandStock } from 'behavior/pages/document';
import { useDispatch } from 'react-redux';
import { navigateTo } from 'behavior/events';
import { routesBuilder } from 'routes';
import { useCallback, createRef, useContext, useMemo } from 'react';
import { usePrintMode } from 'utils/hooks';
import { useSanaTexts } from 'components/sanaText';

import { toasts } from 'behavior/toasts';
import { makeSimpleText } from 'utils/render';

const MobileTable = ({
  lines,
  loadLines,
  options,
  itemsFolded,
  initialLinesLoaded,
  lineTexts,
  cancelled,
  agreementId,
  orderId,
  documentId,
  internalDocumentId,
}) => {
  const isPreview = useIsPreview();
  const isPrintMode = usePrintMode();
  const transactionComment = createRef();
  const dispatch = useDispatch();

  const customTexts = useCustomText();

  const handleOnClick = useCallback(() => {
    const list = [];

    for (const [productId, { quantity }] of quantities) {
      if (!(quantity && quantity.isValid)) {
        document.getElementById(productId + '_' + 'qty').focus();
        return;
      }
      if (quantity && quantity.value > 0) {
        list.push({
          productId: productId,
          quantity: quantity.value,
        });
      }
    }

    const data = {
      referenceId: orderMessage + orderId + ' ' + shipmentMessage + documentId,
      shipmentId: internalDocumentId,
      quantities: list,
      comment: transactionComment.current.value,
    };
    dispatch(addOnHandStock(data));
    dispatch(addOnHandStock(data));
    toasts.success(customTexts.stockAddtoOnHandSuccess);
    window.location.reload(false);

  });

  const {
    quantities,
  } = useContext(QuantityContext);

  const allLinesAreNotTracked = lines && lines.itemLines && lines.itemLines.length && lines.itemLines.some(line => (line.isTracked || (line.sublines && line.sublines.length && line.sublines.some(subLine => subLine.isTracked))));
  const isHidden = lines && lines.itemLines && lines.itemLines.length && lines.itemLines.every(line => (line.quantityShippedRemained <= 0));

  const [placeholder, orderMessage, shipmentMessage] = useSanaTexts([
    'Shipment_details_comment_Placeholder',
    'Shipment_Order',
    'Shipment_Shipment',
  ]).texts;

  return (
    <div className={styles.lines}>
      <div className={styles.linesTableWrapper}>
        <table className={`${styles.linesTable} ${styles.mobileLines}`}>
          {!isPreview
            ? !itemsFolded && (
              <tbody>
                {lines.itemLines && lines.itemLines.map((item, index) => (
                  <ProductLine
                    key={index}
                    line={item}
                    options={options}
                    lineTexts={lineTexts}
                    cancelled={cancelled || item.isCancelled}
                    agreementId={agreementId}
                  />
                ))}
                {lines.serviceLines && lines.serviceLines.map((item, index) => (
                  <ServiceLine key={index} line={item} options={options} lineTexts={lineTexts} />
                ))}
              </tbody>
            )
            : (
              <tbody>
                <PreviewLine options={options} lineTexts={lineTexts} />
                <PreviewLine options={options} lineTexts={lineTexts} />
                <PreviewLine options={options} lineTexts={lineTexts} />
              </tbody>
            )
          }
          { }
        </table>
      </div>
      {!initialLinesLoaded && (
        <LinesFooter
          lines={lines}
          itemsFolded={itemsFolded}
          loadLines={loadLines}
          lineTexts={lineTexts}
        />
      )}
      {allLinesAreNotTracked && !isPrintMode && !isHidden &&
        <div className="pull-right on-hand-stock-block">
          <textarea name="comment" id="transactionComment" className="form-control" ref={transactionComment} maxLength="150" minLength="20" placeholder={placeholder} style={{ marginBottom: '20px' }} />
          <SanaButton
            textKey="AddToOnHandStock"
            onClick={handleOnClick}
          />
        </div>}
    </div>
  );

  function useCustomText() {
    const texts = useSanaTexts([
      'Shipment_AddToHandFail',
      'Shipment_AddToHandSuccess',
    ]).texts;

    return useMemo(() => {
      const [
        stockAddtoOnHandFailed,
        stockAddtoOnHandSuccess,
      ] = texts;

      return {
        stockAddtoOnHandFailed: makeSimpleText(
          stockAddtoOnHandFailed,
        ),
        stockAddtoOnHandSuccess: makeSimpleText(
          stockAddtoOnHandSuccess,
        ),
      };
    }, [texts]);
  }
};

MobileTable.propTypes = {
  lines: PropTypes.shape({
    serviceLines: PropTypes.array,
    itemLines: PropTypes.array,
  }).isRequired,
  loadLines: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  itemsFolded: PropTypes.bool.isRequired,
  initialLinesLoaded: PropTypes.bool.isRequired,
  lineTexts: PropTypes.object,
  cancelled: PropTypes.bool.isRequired,
  agreementId: PropTypes.string,
};

export default MobileTable;

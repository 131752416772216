import styles from '../WishList.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'components/sanaText';
import { InfoAlert } from 'components/primitives/alerts'

function LineTemplate({
  productInfo,
  productActionBlock,
  actionLinks,
  priceNode,
  uomBlock,
  addToBasketBlock,
  isGroupedLine,
  className = null,
  isSpecialShipping,
  allowedForBToBOnly,
  ...attributes
}) {

  return (
    <>
      <tr className={className} {...attributes}>
        {productActionBlock
          ? (
            <td className={styles.colProductAction} aria-hidden={!isGroupedLine}>
              {productActionBlock}
            </td>
          )
          : <td className={styles.colProductAction} />}
        <td className={styles.colProductInfo}>
          {productInfo}
          {actionLinks}
        </td>
        <td className={styles.colPrice}>
          {priceNode}
        </td>
        <td>
          {uomBlock}
        </td>
        <td>
          {addToBasketBlock}
          {allowedForBToBOnly && (<div className="italic_text reg-b2b-desc"> <RichText textKey="OnlyAvailableForB2BCustomers" /> </div>)}
        </td>
 
      </tr>
      <tr className="info_alert-wrapper">
        <td colSpan="6">{isSpecialShipping && (<InfoAlert role="alert"> <RichText textKey="SpecialShippingMessage" /> </InfoAlert>)}</td>
      </tr>
    </>
  );
}

LineTemplate.propTypes = {
  productInfo: PropTypes.node.isRequired,
  productActionBlock: PropTypes.node,
  actionLinks: PropTypes.node,
  priceNode: PropTypes.node,
  uom: PropTypes.node,
  className: PropTypes.string,
  addToBasketBlock: PropTypes.node,
  isGroupedLine: PropTypes.bool,
  isSpecialShipping: PropTypes.bool,
  allowedForBToBOnly: PropTypes.bool,
};

export default memo(LineTemplate);

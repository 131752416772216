import styles from '../Checkout.module.scss';
import { useContext } from 'react';
import PropTypes from 'prop-types';
import AddressStep from './AddressStep';
import GuestAddressStep from './GuestAddressStep';
import ShippingMethodStep from './ShippingMethodStep';
import PaymentMethodStep from './PaymentMethodStep';
import CustomerDataStep from './CustomerDataStep';
import ExtraPaymentStep from './ExtraPaymentStep';
import OverviewStep from './OverviewStep';
import CheckoutContext from './CheckoutContext';
import { Steps } from 'behavior/pages/checkout';
import { useSelector } from 'react-redux';
import { useIncompleteStepsInfo } from '../hooks';
import { CreditLimitMessage } from 'components/objects/basket';

const CheckoutSteps = ({ info }) => {
  const { activeStep, stepVisited } = useContext(CheckoutContext);

  const isAddress = activeStep === Steps.Address,
    isShipping = activeStep === Steps.Shipping,
    isPayment = activeStep === Steps.Payment,
    isCustomerData = activeStep === Steps.CustomerData,
    isExtraPayment = activeStep === Steps.ExtraPayment,
    isOverview = activeStep === Steps.Overview;

  const isOffline = useSelector(state => state.app.offlineMode);
  const showShippingMethods = !!info.shippingMethods,
    showPaymentMethods = !isOffline && !!info.paymentMethods,
    showExtraPayment = showPaymentMethods && !!info.extraPaymentStep,
    showCustomerDataStep = showPaymentMethods && !!info.customerDataStep;

  const incompleteSteps = useIncompleteStepsInfo(info.steps);

  const addressStepCompleted = !incompleteSteps.has(Steps.Address) && stepVisited(Steps.Address),
    shippingStepCompleted = !incompleteSteps.has(Steps.Shipping) && stepVisited(Steps.Shipping),
    paymentStepCompleted = !incompleteSteps.has(Steps.Payment) && stepVisited(Steps.Payment),
    customerStepCompleted = !incompleteSteps.has(Steps.CustomerData) && stepVisited(Steps.CustomerData),
    additionalPaymentStepCompleted = !incompleteSteps.has(Steps.ExtraPayment) && stepVisited(Steps.ExtraPayment);

  return (
    <>
      <CreditLimitMessage creditLimit={info.creditLimit} />
      {info.isGuest
        ? <GuestAddressStep isActive={isAddress} info={info} isCompleted={addressStepCompleted} />
        : <AddressStep isActive={isAddress} info={info} isCompleted={addressStepCompleted} />
      }
      {showShippingMethods &&
        <ShippingMethodStep
          isActive={isShipping}
          asLink={stepVisited(Steps.Shipping)}
          isCompleted={shippingStepCompleted}
          info={info}
        />
      }
      {showPaymentMethods &&
        <PaymentMethodStep
          className={`${styles.paymentMethod} ${isPayment ? styles.activeStep : ''}`}
          isActive={isPayment}
          asLink={stepVisited(Steps.Payment)}
          isCompleted={paymentStepCompleted}
          info={info}
        />
      }
      {showCustomerDataStep &&
        <CustomerDataStep
          className={`${styles.customerData} ${isCustomerData ? styles.activeStep : ''}`}
          isActive={isCustomerData}
          asLink={stepVisited(Steps.CustomerData)}
          isPromotion={!!info.quote}
          isCompleted={customerStepCompleted}
          customerDataStep={info.customerDataStep}
          stepInvalid={info.invalid}
        />
      }
      {showExtraPayment &&
        <ExtraPaymentStep
          className={`${styles.extraPayment} ${isExtraPayment ? styles.activeStep : ''}`}
          isActive={isExtraPayment}
          asLink={stepVisited(Steps.ExtraPayment)}
          extraPaymentStep={info.extraPaymentStep}
          isPromotion={!!info.quote}
          isCompleted={additionalPaymentStepCompleted}
          stepInvalid={info.stepInvalid}
        />
      }
      <OverviewStep
        className={`${styles.overview} ${isOverview ? styles.activeStep : ''}`}
        isActive={isOverview}
        info={info}
        isCompleted={incompleteSteps.size === 0}
      />
    </>
  );
};

CheckoutSteps.propTypes = {
  info: PropTypes.shape({
    shippingAddresses: PropTypes.array,
    billingAddress: PropTypes.object,
    shippingAddress: PropTypes.object,
    shippingTemplateFields: PropTypes.array,
    isQuote: PropTypes.bool,
    extraPaymentStep: PropTypes.object,
    customerDataStep: PropTypes.object,
  }),
};

export default CheckoutSteps;

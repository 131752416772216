import { createContext } from 'react';

export const contextInitialValue = {
  product: null,
  calculatedInfo: {
    inventory: null,
    isOredable: null,
  },
  variantId: null,
  updateContext: null,
};

const ProductContext = createContext(contextInitialValue);

export default ProductContext;
import {
  STOCK_TRANSACTION_RECEIVED,
  STOCK_TRANSACTION_UPDATED,
} from 'behavior/pages/stockTransaction/actions';
import { createReducer } from 'utils/redux';
import { NAVIGATED } from 'behavior/routing';
import { PageComponentNames } from '../componentNames';
import { UpdateType } from './constants';

const initialState = {
  listItems: { items: null, totalCount: 0 },
  filter: null,
  page: 0,
  status: null,
  savingResult: null,
};

export default createReducer(initialState, {
  [STOCK_TRANSACTION_RECEIVED]: onStockTransactionReceived,
  [NAVIGATED]: onNavigated,
  [STOCK_TRANSACTION_UPDATED]: onStockTransactionUpdated,
});

function onStockTransactionReceived(state, action) {
  return {
    ...state,
    listItems: action.payload,
    productFilter: action.productFilter
  };
}

function onStockTransactionUpdated(state) {
  return { ...state, status: { type: UpdateType.Reversed } };
}

function onNavigated(state, action) {
  if (action.payload.page && action.payload.page.component === PageComponentNames.StockTransactions)
    return state;

  return initialState;
}
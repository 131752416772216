import type { Action } from 'redux';
import type { Epic } from 'behavior/types';
import type { ModifiedLines, AddProductFailureReason } from './types';
import { of } from 'rxjs';
import { mergeMap, pluck } from 'rxjs/operators';
import {
  BarCodeScannerAction,
  BAR_CODE_SCANNER_PRODUCT_ADDED,
  receiveProductInfo,
  receiveFailureReason,
} from './actions';
import { addProductByBarCodeMutation } from './queries';
import { retryWithToast } from 'behavior/errorHandling';
import { ofType } from 'redux-observable';
import { basketChangeCompleted } from 'behavior/events';
import { trackAddToBasket } from 'behavior/analytics';
import { getProductsTrackingDataFromLines } from 'behavior/basket/util';
import { Updaters } from 'behavior/basket';

const epic: Epic<BarCodeScannerAction> = (action$, state$, dependencies) => {
  const { api, logger } = dependencies;

  return action$.pipe(
    ofType(BAR_CODE_SCANNER_PRODUCT_ADDED),
    pluck('payload', 'code'),
    mergeMap(code => {
      const loadCategories = !!(state$.value as any).analytics?.isTrackingEnabled;

      return api.graphApi<AddProductByBarCodeResponse>(addProductByBarCodeMutation({ loadCategories }), { code }).pipe(
        pluck('basket', 'addProductByBarCodeOrId'),
        mergeMap(result => {
          if ('reason' in result)
            return of(receiveFailureReason(result.reason));

          const productLine = result.modifiedLines.list[0];
          const actions: Action[] = [
            basketChangeCompleted({ updaterId: Updaters.BarCodeScanner, date: Date.now(), linesAmount: 1 }),
            receiveProductInfo({
              id: productLine.product.id,
              title: productLine.product.title,
              variantTitle: productLine.subLines?.length ? productLine.subLines[0].title : null,
            }),
          ];

          const addedProducts = getProductsTrackingDataFromLines([productLine]);
          if (addedProducts.length)
            actions.push(trackAddToBasket(addedProducts));

          return actions;
        }),
        retryWithToast(action$, logger),
      );
    }),
  );
};

export default epic;

type AddProductByBarCodeResponse = {
  basket: {
    addProductByBarCodeOrId: {
      modifiedLines: ModifiedLines;
    } | {
      reason: AddProductFailureReason;
    };
  };
};
